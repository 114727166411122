<template>
    <div
        v-if="video"
        :class="className"
        :style="styles"
    >
        <vimeo :id="video" />
    </div>
</template>

<script>

import Vimeo from 'objects/Vimeo'

export default {
    name: 'BlockVideo',
    components: {
        Vimeo,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        className() {
            let classname = 'b-video'

            if(this.settings.boxed) {
                classname += ' -boxed'
            }

            return classname
        },
        video() {
            return this.data.video
        },
        settings() {
            return this.data.blockSettings[0]
        },
        styles() {
            let styles = `
                --width: ${this.settings.width};
                --align-self: ${this.settings.alignY};
            `

            if(this.settings.alignX === 'left') {
                styles += '--margin-left: 0;'
            } else if(this.settings.alignX === 'right') {
                styles += '--margin-right: 0;'
            }

            return styles
        },
    },
};

</script>

<style lang="scss">

</style>
