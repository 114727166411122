/*
** Craft GraphQL Snippets
*/

import {getAsset} from './assets'

/**********************************
 *
 *
 *  SEO
 *
 *
 **********************************/

const seo = `
    seo {
        title
        description
    }
`




/**********************************
 *
 *
 *  DEFAULT
 *
 *
 **********************************/

// Default data
const defaultData = `
title
id
slug
`

// Default entry
const entry = `
    ${defaultData}
    url
    uri
    sectionHandle
`

// Base entry content
const defaultEntry = `
    mainTitle
    ${seo}
`




/**********************************
 *
 *
 *  PRODUCT(S)
 *
 *
 **********************************/

const product = `
    ...on product_product_Entry {
        storefrontId
        ${getAsset("product")}
        video
        description
        features: tags {
            title
        }
        sizingGuide
        shippingInstructions
        productAssets {
            ...on productAssets_MatrixField {
                ...on productAssets_image_BlockType {
                    ${getAsset("product")}
                    width
                }
                ...on productAssets_video_BlockType {
                    video
                    width
                }
            }
        }
        ${seo}
    }
`




/**********************************
 *
 *
 *  MISC
 *
 *
 **********************************/

// List supertable
const list = `
list {
    ... on list_BlockType {
        label
        body
    }
}
`

const address = `
address {
    ...on address_BlockType {
        label
        googleMap
    }
}
`

// Push
const push = `
push {
    ...on push_BlockType {
        headline
        entry {
            ${defaultData}
            uri
        }
        btnLabel
    }
}
`


// mainCta
const mainCta = `
mainCta {
    ...on mainCta_BlockType {
        entry {
            ${defaultData}
            uri
        }
        btnLabel
    }
}
`




/**********************************
 *
 *
 *  BUILDER
 *
 *
 **********************************/

// Blocks
const blocks = `
blocks {
    ...on blocks_asset_BlockType {
        typeHandle
        ${getAsset('grid')}
        fullwidth
    }
    ...on blocks_entry_BlockType {
        typeHandle
        entry {
            ${defaultData}
            uri
            sectionHandle
        }
        headline
        btnLabel
        fullwidth
    }
}
`

const builderSettings = `
blockSettings {
    ...on blockSettings_BlockType {
        boxed
        width
        alignX
        alignY
    }
}`

const builderBlockText = `
...on builder_blockText_BlockType {
    typeHandle
    body
    ${builderSettings}
}
`

const builderBlockAsset = `
...on builder_blockAsset_BlockType {
    typeHandle
    ${getAsset('grid')}
    ${builderSettings}
}
`

const builderBlockVideo = `
...on builder_blockVideo_BlockType {
    typeHandle
    video
    ${builderSettings}
}
`

const builderBlockProduct = `
...on builder_blockProduct_BlockType {
    typeHandle
    ${getAsset('product')}
    selectedProducts {
        title
        id
        slug
        uri
        ...on product_product_Entry {
            storefrontId
        }
    }
    ${builderSettings}
}
`


const builder = `
builder {
    ...on builder_blockSlider_BlockType {
        typeHandle
        mainTitle
        slide {
            ...on slide_BlockType {
                headline
                desc
                ${getAsset()}
            }
        }
    }
    ...on builder_blockLink_BlockType {
        typeHandle
        ${getAsset()}
        headline
        label
        outerlink
    }

    ${builderBlockText}
    ${builderBlockAsset}
    ${builderBlockVideo}
    ${builderBlockProduct}
    ...on builder_blockRow_BlockType {
        typeHandle

        children {
            ...on builder_blockColumn_BlockType {
                typeHandle

                children {
                    ${builderBlockText}
                    ${builderBlockAsset}
                    ${builderBlockVideo}
                    ${builderBlockProduct}
                }
            }
        }
    }
}
`

//
export default {
    defaultData,
    entry,
    product,
    seo,
    defaultEntry,
    list,
    address,
    blocks,
    push,
    mainCta,
    builder,
}
