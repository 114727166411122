export default {
    btn: {
        addToCart: 'Add to cart',
        outOfStock: 'Out of Stock',
        credits: 'Creditos',
        subscribe: 'Suscríbete',
        readMore: 'Leer mas',
        readPosts: 'Leer todas las publicaciones',
        more: 'More',
        shop: 'Comprar',
        shopAll: 'Comprar todo',
        shopCollections: 'Comprar collecciónnes',
        shopNow: 'Comprar ahora',
        viewAll: 'Ver todo',
        shopCollection: 'Ver la collección',
        backToHome: 'Volver al inicio',
        adding: 'Añadiendo...',
        attendEvent: 'Asistir al evento',
        learnMore: 'Saber más',
        directions: 'Tener la dirección',
        chat: 'Hablar con nosotros',
        findStore: 'Encuentra tu tienda',
        loadMore: 'Cargar más',
        newTab: `Abrir enlace en una nueva pestaña`
    },
    header: {
        searchPlaceholder: 'Buscar vestidos, accesorios, calzado',
    },
    misc: {
        product: 'producto',
        display: 'Disposición',
        wantToBuy: 'Comprar',
        categories: 'Categorías',
        emailUs: 'Correo electronico',
        callUs: 'Teléfono',
        location: 'Ubicación',
        loading: 'Cargando',
        openingHours: 'Horario de apertura',
        size: 'Talla',
        sizes: 'Tamaño',
        ourStores: 'Nuestras Tiendas',
        storesIn: 'tiendas en',
        regions: 'regiónes',
        order: 'Ordenar',
        priceASC: 'Menor precio',
        priceDESC: 'Mayor precio',
        newsletterTitle: `No te pierdas las <i>novedades</i>`,
        nextEvents: `Próximos <i>eventos</i>`,
        secureCheckout: 'Pago seguro'
    },
    detection: {
        message: `Está en la página de compra de {currentSiteCode} en {currency}. Puede dirigirse a la web de otro pais cercano para acceder a más productos.`,
        the: `{country}`
    },
    product: {
        description: 'Descripción',
        price: 'Precio',
        outOfStock: 'Agotado',
        inSale: 'Sale',
        relatedProducts: 'También te puede interesar',
        sizingGuide: 'Sizing Guide',
        shippingAndReturns: 'Shipping and Returns',
    },
    form: {
        yourEmail: 'Correo',
        loading: 'Loading...',
        success: 'Sucess. Welcome on board!',
    },
    aria: {
        displayBigGrid: 'Display as large thumbnails',
        displaySmallGrid: 'Display as small thumbnails',
        close: 'Cerrar',
        closeSubnav: 'Cerrar la sub-navegación',
        next: 'Next',
        prev: 'Previous',
        search: 'Search',
        toggle: 'Abrir/Cerrar',
        toggleNav: 'Abrir/Cerrar el menú',
        toggleSearch: 'Abrir/Cerrar la búsqueda',
        toggleCart: 'Abrir/Cerrar el carrito',
    },
    search: {
        label: 'Search the products',
        searched: 'Has buscado',
        noResult: 'Hemos encontrado 0 productos con',
        tryShop: 'Inténtalo en nuestra tienda?',
        seeMore: 'No es exactamente lo que estabas buscando?',
        recommendations: 'Te puede gustar',
        favorites: 'Busquedas favoritas',
    },
    cart: {
        empty: 'Tu carrito esta vacío',
        title: 'Tu carrito',
        review: 'Ver mi carrito',
        checkout: 'Comprar ahora',
        orderSummary: 'Resumen de compra',
        orderSubtotal: 'Subtotal de compra',
        total: 'Total',
        subtotal: 'Subtotal',
        shipping: 'Gastos de envío',
        taxes: 'Tasas',
        addedAtCheckout: 'Añadido al checkout',
    },
    credits: 'Site by MamboMambo'
}
