import { fetchApi } from 'src/graphql/craft/config'
import queries from 'src/graphql/craft/queries'

/*
** Categories
*/

// State
const state = {
}

// Getters
const getters = {
    getCategoryByHandle: state => handle => state[handle],
}

// Actions
const actions = {
    loadGroup(store, handle) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if page already exists
            let categoryGroup = store.getters.getCategoryByHandle(handle)

            // If category already exists, resolve with the category value
            if(typeof categoryGroup !== 'undefined') {
                resolve(categoryGroup)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If category doesn't exist, load category
            } else {
                fetchApi(queries.categories(handle))
                    .then(r => {
                        const data = r.categories
                        store.commit('setCategoryGroup', { group: handle, categories: data })
                        resolve(data)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
            }

        })
    },
}

// Mutations
const mutations = {
    setCategoryGroup(state, { group, categories }) {
        state[group] = categories
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
