<template>
    <div class="b-link">
        <asset
            class="b-link__asset"
            v-bind="asset"
        />

        <div class="b-link__content">
            <anim-text
                :cms="true"
                :text="headline"
                class="b-link__headline | t-t5"
            />
            <btn
                v-if="data.outerlink"
                tag="a"
                :href="data.outerlink"
                :label="data.label ? data.label : $t('btn.readMore')"
                iconAfter="arrow-long-right"
                bg="none"
            />
        </div>
    </div>
</template>

<script>

import Asset from 'objects/Asset'
import AnimText from 'objects/AnimText'
import Btn from 'components/Btn'

export default {
    name: 'BlockLink',
    components: {
        Asset,
        AnimText,
        Btn,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        asset() {
            const asset = this.data.asset
            if(asset.length === 0) {
                return false
            } else {
                return asset[0]
            }
        },
        headline() {
            const headline = this.data.headline
            return headline ? headline : false
        },
    },
};

</script>

<style lang="scss">

.b-link {
    border-top: $border-secondary-light;

    .c-btn {
        padding-left: 0;
    }

    @media #{md("xs")} {
        display: grid;
        grid-column-gap: var(--grid-gutter);
        grid-template-columns: 1fr 3fr;
        align-items: center;
        border-bottom: $border-secondary-light;
    }

    @media #{md("sm")} {
        grid-template-columns: 1fr 4fr;
    }

    @media #{md("md")} {
        grid-column-gap: 12%;
    }
}

.b-link__asset {

    @media #{md("xs", "max")} {
        width: 12em;
    }
}

.b-link__content {
    padding-top: 1em;
    padding-bottom: 1em;

    .c-btn {
        margin-top: 1em;
    }
}

</style>
