<template>
    <div :class="className">
        <div
            class="c-mini-cart__inner"
        >
            <template v-if="isEmpty">
                <h3 class="c-mini-cart__title | t-small">{{ $t('cart.empty') }}</h3>
                <btn
                    tag="router-link"
                    :href="'shop'|resolve"
                    :title="$t('btn.shopAll')"
                    :label="$t('btn.shopAll')"
                    :reveal="false"
                />
            </template>

            <template v-else>
                <h3 class="c-mini-cart__title | t-small">{{ $t('cart.title') }}</h3>
                <ul class="c-mini-cart__list">
                    <li
                        v-for="line in products"
                        :key="`mini-cart-product-${line.variantId}`"
                        class="c-mini-cart__product | o-card-product"
                    >
                        <asset
                            v-bind="line.thumb"
                            class="o-card-product__image"
                            :reveal="false"
                            :cover="false"
                        />
                        <div class="o-card-product__content">
                            <div class="o-card-product__header">
                                <router-link :to="`/${line.uri}`" class="o-card-product__name | t-small">
                                    {{ line.title }}
                                </router-link>
                                <button
                                    @click="remove(line)"
                                    class="o-card-product__remove | u-as"
                                >
                                    <icon icon="cross" />
                                </button>
                            </div>
                            <div v-if="line.variant && line.variant.selectedOptions">
                                <span

                                    v-for="option in cleanOptions(line.variant.selectedOptions)"
                                    :key="`cart-option-${line.variantId}-${option.name}`"
                                    class="o-card-product__size | t-caps"
                                    >
                                    {{ option.name }}: {{ option.value }}
                                </span>
                            </div>
                            <div class="u-dp-flex u-ai-center u-jc-between">
                                <span class="o-card-product__qty">
                                    {{ line.quantity }}
                                </span>
                                <price
                                    :amount="line.price"
                                    :compareAt="line.compareAtPrice"
                                    class="o-card-product__price"
                                />
                            </div>
                        </div>
                    </li>
                </ul>

                <btn
                    tag="router-link"
                    :href="'cart'|resolve"
                    :label="$t('cart.review')"
                    bg="dark"
                    iconAfter="arrow-long-right"
                    size="md"
                    :reveal="false"
                />

                <btn
                    :label="$t('cart.checkout')"
                    iconAfter="arrow-long-right"
                    size="md"
                    :reveal="false"
                    @click.native="goToCheckout"
                />

            </template>

        </div>
    </div>
</template>

<script>

import Price from 'objects/Price';
import Icon from 'objects/Icon';
import Asset from 'objects/Asset';
import Btn from 'components/Btn';

import { mapState, mapGetters } from 'vuex'

export default {
    name: 'MiniCart',
    components: {
        Price,
        Btn,
        Icon,
        Asset,
    },
    data: () => ({
        isOpen: false
    }),
    computed: {
        ...mapState({
            products: state => state.cart.lines
        }),

        ...mapGetters({
            isEmpty: 'cart/isEmpty'
        }),

        className() {
            let classname = 'c-mini-cart'

            classname += this.isEmpty ? ' is-empty' : ' has-products'

            if(this.isOpen) {
                classname += ' is-open'
            }

            return classname
        },

    },
    methods: {
        open() {
            if(this.isOpen) {
                return
            }

            this.isOpen = true
        },
        close() {
            if(!this.isOpen) {
                return
            }

            this.isOpen = false
        },
        toggle() {
            if(this.isOpen) {
                this.close()
            } else {
                this.open()
            }
        },
        remove(line) {

            this.$store.dispatch('cart/remove', line)
                .then(() => {
                    this.$emit('removed', line)
                })
                .catch(e => {
                    console.error('Error: cart/remove', e)  // eslint-disable-line
                })
        },
        goToCheckout() {

            this.$store.dispatch('checkout/create')
                .then(checkout => {

                    if ( checkout && checkout.webUrl ) {
                        const webUrl = checkout.webUrl
                        location.href = this.decorateUrl(webUrl)
                    }
                })
                .catch(e => {
                    console.error('Error: checkout/create', e)  // eslint-disable-line
                })
        },
        cleanOptions(options) {
            return options.filter(option => option.name != 'Title')
        },
        decorateUrl(urlString) {

            //console.log('::decorateUrl() called')  // eslint-disable-line

            const ga = window[window['GoogleAnalyticsObject']];
            let tracker;

            if (ga && typeof ga.getAll === 'function') {

                //console.log('::decorateUrl() truly fired')  // eslint-disable-line
                tracker = ga.getAll()[0]
                urlString = (new window.gaplugins.Linker(tracker)).decorate(urlString)
                //console.log('::decorated Url: ', urlString)  // eslint-disable-line
            }
            return urlString;
        }
    },
    watch: {
        isOpen(isOpen) {
            this.$emit('toggle', isOpen)
        },
    }
};

</script>

<style lang="scss">

.c-mini-cart {

    &:before {
        @include pseudo-el($bg: $color-primary-light);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transform: scale(1, 0);
        transform-origin: 50% 0;
        transition: all .4s $out-quint;
    }

    &.is-open {

        &:before {
            opacity: 1;
            transform: scale(1);
            transition: all .8s $out-expo;
        }

        .c-mini-cart__inner {
            opacity: 1;
            transition: opacity .3s ease-out .3s;
        }
    }

    &.is-empty {
        text-align: center;

        .c-btn {
            margin-top: 1em;
        }
    }

    &.has-products {

        .c-btn {
            width: 100%;
        }

        .c-btn__inner {
            width: 100%;
            justify-content: space-between;
        }
    }

    @media #{md("sm")} {
        max-width: 26em;
    }
}

.c-mini-cart__inner {
    max-height: calc(100vh - #{$header-height});
    padding: 1.25em;
    overflow-y: auto;
    opacity: 0;

    .c-btn + .c-btn {
        margin-top: 1em;
    }
}

.c-mini-cart__title {
    display: block;
    padding-bottom: 1rem;
    text-align: center;
}

.c-mini-cart__list {
}

.c-mini-cart__product {
    padding-top: 1em;
    padding-bottom: 1em;

    &:not(:first-child) {
        border-top: $border-secondary-light;
    }
}


/*----------  Product cart item  ----------*/

.o-card-product {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-column-gap: var(--grid-gutter-half);
}

.o-card-product__image {
    width: 100%;
}

.o-card-product__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.o-card-product__name {
}

.o-card-product__size {
    display: block;
    margin-bottom: 1.2rem;
}

.o-card-product__qty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.25em;
    height: 2.25em;
    text-align: center;
    border: $border-secondary-light;
}

.o-card-product__price {
    font-size: .875em;
}

.o-card-product__remove {
    margin-top: -.75em;
}


</style>
