/*
** StoreFrontApi GraphQL Queries
*/

import snippets from './snippets'


/**********************************
 *
 *
 *  GENERAL
 *
 *
 **********************************/

// Settings
export const settings = `query ShopSettings {
    shop {
        name
        description
        moneyFormat
        paymentSettings {
            currencyCode
        }
        shipsToCountries
    }
}`



/**********************************
 *
 *
 *  PRODUCTS
 *
 *
 **********************************/

// Products (with query)
export const products = `query Products($first: Int!, $after: String, $query: String, $sortKey: ProductSortKeys, $reverse: Boolean) {
    products(first: $first, after: $after, query: $query, sortKey: $sortKey, reverse: $reverse) {
        pageInfo {
            hasNextPage
            hasPreviousPage
        }
        edges {
            cursor
            node {
                id
                ... on Product {
                    ${snippets.product}
                }
            }
        }
    }
}`

// Specific Products By IDs
/*
export const productsByIDs = `query SpecificProductsByIDs($ids: ID!) {
    nodes(ids: $ids) {
        id
        ...on Product {
            ${snippets.product}
        }
    }
}`
*/

// Specific Product By Handle
export const productByHandle = `query SpecificProductByHandle($handle: String!) {
    productByHandle(handle: $handle) {
        id
        ${snippets.product}
    }
}`

//
export const productRecommendations = `query SpecificProductRecommendations($id: ID!) {
    productRecommendations(productId: $id) {
        id
        ${snippets.product}
    }
}`

// Specific Product By ID
/*
export const productByID = `query SpecificProductByID($id: ID!) {
    node(id: $id) {
        id
        ...on Product {
            ${snippets.product}
        }
    }
}`
*/

/**********************************
 *
 *
 *  TAGS
 *
 *
 **********************************/

 export const tags = `query ShopTags {
    productTags(first: 100) {
        edges {
            node
        }
    }
 }`


/**********************************
 *
 *
 *  CHECKOUT
 *
 *
 **********************************/

export const fetchCheckout = `query GetCheckout($id: ID!) {
    node(id: $id) {
        id
        ...on Checkout {
            order {
                id
            }
            email
            completedAt
        }
    }
}`


export default {
    settings,
    products,
    productByHandle,
    productRecommendations,
    fetchCheckout,
    tags
}
