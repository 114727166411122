<template>
    <div
        v-if="items.length > 0"
        :class="className"
        v-reveal.once
    >
        <h4
            v-if="title"
            class="l-breadcrumb__title"
        >
            {{ title }}
        </h4>
        <ul class="l-breadcrumb__list">
            <li
                v-for="(item, i) in items"
                :key="i"
                class="l-breadcrumb__item"
            >
                <router-link
                    v-if="item.uri"
                    :to="formatUri(item.uri)"
                    :title="item.title"
                    class="l-breadcrumb__link"
                    v-html="item.title"
                />
            </li>
        </ul>
    </div>
</template>

<script>

import { routes } from 'src/router'

export default {
    name: 'Breadcrumb',
    props: {
        title: {
            type: String,
            default: null,
        },
        center: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: () => []
        }
    },

    data: () => ({
        items: []
    }),

    created() {
        this.items = this.main ? this.crumbs : this.list
    },
    methods: {
        formatUri(uri) {
            return ( uri.charAt(0) == '/') ? uri : `/${uri}`
        }
    },
    computed: {
        className() {
            let classname = 'l-breadcrumb'

            if(this.center) {
                classname += ' -center'
            }

            if(this.main) {
                classname += ' -main'
            }

            return classname
        },
        main() {
            return this.list.length === 0
        },
        crumbs() {
            if(!this.main) {
                return
            }

            // Make array from url segments
            let pathArray = this.$route.path.split('/')

            // Add slash before each path
            pathArray = pathArray.map(path => `/${path}`)

            let route
            const breadcrumbs = []
            const pathArrayLength = pathArray.length

            pathArray.forEach((path, i) => {

                // Find route with same path
                route = routes.find(r => r.path.endsWith(path))

                // If no route found (child route with var), get previous route and add slug parameter
                if (typeof route === 'undefined') {

                    route = routes.find(r => r.path.endsWith(`${pathArray[i-1]}/:slug`))

                    // Create new object to don't modify route in router
                    route = Object.assign({}, route)

                    route.path = route.path.replace('/:slug', path)

                    // If last path, route = currentPage so get the current page title
                    if(i === pathArrayLength - 1) {
                        route.meta.title = this.$store.getters['global/getCurrentPageTitle']
                    }
                }

                breadcrumbs.push({
                    title: route.meta.title ? route.meta.title : route.name,
                    uri: route.path,
                })
            })

            return breadcrumbs
        },
    },
};

</script>

<style lang="scss">

.l-breadcrumb {
    z-index: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: .875em;
    color: $color-dark;

    &.-center {
        justify-content: center;

        .l-breadcrumb__list {
            text-align: center;
        }
    }

    &.-main {
        color: $color-brown;

        .l-breadcrumb__item {

            &:last-child {
                pointer-events: none;

                .l-breadcrumb__link {
                    color: $color-dark;
                }
            }
        }
    }

    &.js-reveal {
        overflow: hidden;

        .l-breadcrumb__item,
        .l-breadcrumb__title {
            @include anim-reveal-hide;
        }

        @for $i from 1 through 5 {

            .l-breadcrumb__item:nth-child(#{$i}) {
                $i: $i - 1;
                --a-out-delay: #{$i/10}s;
                --a-in-delay: #{$i/7}s;
            }
        }

        &.is-visible {

            .l-breadcrumb__item,
            .l-breadcrumb__title {
                @include anim-reveal-show;
            }
        }
    }
}

.l-breadcrumb__title {
    display: inline-block;
    margin-right: .75em;
    font-weight: 400;
    color: $color-brown;
    text-align: center;
}

.l-breadcrumb__list {
    @include reset-list;
}

.l-breadcrumb__item {
    display: inline-block;

    &:not(:first-child):before {
        content: "/";
        display: inline-block;
        margin-right: .5em;
        margin-left: .5em;
    }
}

.l-breadcrumb__link {
    display: inline-flex;
    padding-top: .1em;
    padding-bottom: .1em;

    &::first-letter {
        text-transform: uppercase;
    }

    &:after {
        @include pseudo-el($height: $border-width, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scale(0, 1);
        transform-origin: 100% 50%;
        transition: transform .6s $out-circ;
    }

    &:hover {

        &:after {
            transform: scale(1);
            transform-origin: 0 50%;
            transition: transform .8s $in-out-circ;
        }
    }
}

</style>
