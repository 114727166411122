<template>
    <div
        v-if="blocks.length > 0"
        class="c-blocks"
    >
        <div class="c-blocks__grid">
            <div
                v-for="(block, i) in blocks"
                :key="`block-${i}`"
                class="c-blocks__item"
                :class="{ '-fullwidth' : block.fullwidth }"
            >
                <div class="c-blocks__box">
                    <asset
                        v-if="block.asset && block.asset.length > 0"
                        v-bind="block.asset[0]"
                        :cover="true"
                        class="c-blocks__img"
                    />
                    <div
                        v-else-if="entries[i]"
                        class="c-blocks__entry | t-content"
                    >
                        <template v-if="block.fullwidth">
                            <anim-text
                                tag="h2"
                                :text="entries[i].mainTitle"
                                class="c-blocks__title | t-t1"
                            />
                            <asset
                                v-if="entries[i].asset && entries[i].asset.length > 0"
                                v-bind="entries[i].asset[0]"
                                class="c-blocks__img"
                            />
                            <btn
                                tag="router-link"
                                :href="`/${entries[i].uri}`"
                                :label="block.btnLabel ? block.btnLabel : $t('btn.readMore')"
                                bg="primary-light"
                            />
                        </template>

                        <template v-else>
                            <router-link
                                :to="`/${entries[i].uri}`"
                                class="c-blocks__link"
                            >
                            <asset
                                v-if="entries[i].asset && entries[i].asset.length > 0"
                                v-bind="entries[i].asset[0]"
                                class="c-blocks__img"
                            />
                            <anim-text
                                v-if="block.headline"
                                tag="h2"
                                :text="entries[i].mainTitle"
                                class="c-blocks__sup | t-t5"
                            />
                            <anim-text
                                :tag="block.headline ? 'h3' : 'h2'"
                                :text="block.headline ? block.headline : entries[i].mainTitle"
                                class="c-blocks__title | t-t2"
                            />
                            </router-link>
                            <btn
                                tag="router-link"
                                :href="`/${entries[i].uri}`"
                                :label="block.btnLabel ? block.btnLabel : $t('btn.readMore')"
                            />

                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Asset from 'objects/Asset';
import AnimText from 'objects/AnimText';
import Btn from 'components/Btn';

export default {
    name: 'Blocks',
    components: {
        Asset,
        AnimText,
        Btn,
    },
    props: {
        blocks: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        entries: [],
    }),
    created() {
        // Parse blocks and load partial entries data
        this.blocks.forEach((block, i) => {
            let entry
            entry = block.entry
            if(entry && entry.length > 0) {
                entry = entry[0]
                this.$store.dispatch('pages/loadPartial', { id: entry.id })
                    .then(entryData => {
                        this.$set(this.entries, i, entryData)
                    })
                    .catch(e => {
                        console.error('Error: Block', e)  // eslint-disable-line
                    })

            } else {
                this.$set(this.entries, i, false)
            }
        })
    },
};

</script>

<style lang="scss">

.c-blocks {
    --padding: 12.5%;
    --offset-2n: 0;

    @media #{md("xs")} {
        --offset-2n: 7vw;
        padding-bottom: var(--offset-2n);
    }
}

.c-blocks__grid {

    @media #{md("xs")} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

.c-blocks__item {
    --bg-color: #{$color-primary-light};
    height: 100%;

    &.-fullwidth {

        .c-blocks__box {
            background-color: transparent;
        }

        .c-blocks__entry {
            padding: 0;

            .c-blocks__img {
                padding: var(--padding);
                background-color: var(--bg-color);
            }
        }

        .c-blocks__title {
            z-index: 1;
            top: .4em;
        }

        .c-btn {
            margin-top: -1em;
        }
    }

    &:nth-child(2n) {
        margin-top: var(--offset-2n);
    }

    @media #{md("xs", "max")} {

        &:nth-child(2n) {
            --bg-color: #{$color-primary};
        }
    }

    @media #{md("xs")} {

        &:nth-child(4n+2),
        &:nth-child(4n+3) {
            --bg-color: #{$color-primary};
        }

        &.-fullwidth {
            --bg-color: #{$color-primary-light};

            grid-column: 1/3;
            margin-top: 0;
            padding-top: var(--section-margin-half);
            padding-bottom: var(--section-margin-half);

            &:nth-child(2n+1) {
                padding-top: calc(var(--offset-2n) + var(--section-margin-half));
            }

            .c-blocks__img.-cover {
                width: 100%;
                height: 0;
                padding-top: calc(100% * 1/var(--ratio));
            }

            .c-blocks__entry .o-asset__img {
                width: calc(3/4 * 100%);
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    @media #{md("md")} {

        &.-fullwidth {

            .c-blocks__entry .o-asset__img {
                width: calc(2/3 * 100%);
            }
        }
    }
}

.c-blocks__box {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: var(--bg-color);
}

.c-blocks__link {
    pointer-events: none;

    * {
        pointer-events: auto;
    }
}
.c-blocks__img {

    @media #{md("xs", "max")} {

        &.-cover {
            width: 100%;
            height: 0;
            padding-top: calc(100% * 1/var(--ratio));
        }
    }
}

.c-blocks__entry {
    width: 100%;
    padding: var(--padding);
    text-align: center;

    @media #{md("sm")} {

        .c-blocks__img {
            margin: var(--padding);

            &.js-reveal {
                overflow: visible;
            }
        }
    }
}

</style>
