<template>
    <tag
        :is="tag"
        :href="tag == 'a' && href !== null ? href : false"
        :to="tag == 'router-link' && href !== null ? href : false"
        :title="(tag == 'router-link' || tag == 'a') && label ? label : false"
        :rel="tag == 'a' ? 'noopener' : false"
        :class="className"
        v-reveal.once="reveal ? revealHandle : false"
    >
        <span class="c-btn__border"></span>
        <span class="c-btn__inner">
            <icon
                v-if="iconBefore !== null"
                class="c-btn__icon"
                :icon="iconBefore"
            />
            <span class="c-btn__label">
                <slot>{{ label }}</slot>
            </span>
            <icon
                v-if="iconAfter !== null"
                class="c-btn__icon"
                :icon="iconAfter"
            />
        </span>
    </tag>
</template>

<script>

import Icon from 'objects/Icon';

export default {
    name: 'Btn',
    components: {
        Icon,
    },
    props: {
        tag: {
            type: String,
            default: 'button',
        },
        href: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: 'Button Label',
        },
        iconBefore: {
            type: String,
            default: null,
        },
        iconAfter: {
            type: String,
            default: null,
        },
        bg: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: null,
        },
        uppercase: {
            type: Boolean,
            default: false,
        },
        reveal: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        revealHandle(state) {
            this.isVisible = state.isActive
        },
    },
    computed: {
        className() {
            let classname = 'c-btn'

            if(this.bg) {
                classname += ` -bg-${this.bg}`
            }

            if(this.size) {
                classname += ` -${this.size}`
            }

            if(this.reveal) {
                classname += ' js-reveal'
            }

            if(this.isVisible) {
                classname += ' is-visible'
            }

            if(this.uppercase) {
                classname += ' -uppercase'
            }

            return classname
        }
    },
    watch: {
        isVisible(visible) {
            this.$emit('toggle', visible)

            if(visible) {
                this.$emit('show')
            } else {
                this.$emit('hide')
            }
        },
        visible(visible) {
            this.isVisible = visible
        },
    }
}
</script>

<style lang="scss">

.c-btn {
    display: inline-flex;
    padding: .5em 1em;
    cursor: pointer;
    @include anim-reveal-default;

    &.-md {

        .c-btn__label {
            font-size: 1em;
        }
    }

    &.-xl {
        font-family: ff("serif");
        @include responsive-type($min-font: 40, $max-font: 90, $min-width: 480, $max-width: 1440);

        .c-btn__border {

            &:before {
                height: 50%;

                @media #{md("xs")} {
                    width: 4px;
                }
            }

            &:after {
                @media #{md("xs")} {
                    height: 4px;
                }
            }
        }

    }

    &.-bg-primary-light {
        background-color: $color-primary-light;
    }

    &.-bg-dark {
        color: $color-light;
        background-color: $color-dark;

        .c-btn__border {
            display: none;
        }
    }

    &.-bg-light {
        background-color: $color-light;
    }

    &.-bg-none {
        background-color: transparent;

        .c-btn__border {
            display: none;
        }
    }

    &.-uppercase {

        .c-btn__label {
            font-size: .875em;
            text-transform: uppercase;
        }
    }

    &:disabled, [disabled=disabled], [disabled=true] {
        cursor: not-allowed;
        background-color: rgba($color-dark, 0.5);
    }

    &:hover {

        .c-btn__border {

            &:before {
                transform: scale(1, 0);
                transition: transform .2s $in-quad;
            }

            &:after {
                transform: scale(.7, 1) translate(0, -50%);
                transition: transform .3s $out-quad .2s;
            }
        }
    }

    @media #{md("sm")} {

        &.-md {

            .c-btn__label {
                font-size: 1.125em;
            }
        }
    }
}

.c-btn__inner {
    display: inline-flex;
    align-items: center;
}

.c-btn__label {
    font-size: .875em;

    & + .c-btn__icon {
        margin-left: 1rem;
    }
}

.c-btn__icon {
    top: $border-width/2;

    & + .c-btn__label {
        margin-left: 1rem;
    }
}

.c-btn__border {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    &:after,
    &:before {
        position: absolute;
        bottom: -$border-width;
        right: -$border-width;
    }

    &:before {
        @include pseudo-el($width: $border-width, $bg: $color-dark);
        transform-origin: 50% 100%;
        transition: transform .3s $out-quad .2s;
    }

    &:after {
        @include pseudo-el($height: $border-width, $bg: $color-dark);
        transition: transform .2s $in-quad;
    }
}

</style>
