/*
 ** GraphQL Configuration and variables
 */
import { checkStatus } from 'src/utils';

const initialData = window.__initialData__,
      shopify = initialData.shopify

export const storeFrontApi = (query, variables, recursive = false, queryType, previousResults = []) => {

    if ( !shopify || !shopify.accessToken ) {
        console.error('Storefront API: AccesToken isn’t provided', error)  // eslint-disable-line
        return undefined
    }

    const api = '/api/2020-07/graphql',
          shopUrl = shopify.shopUrl,
          accessToken = shopify.accessToken

    const params = {
        query: query,
        variables: variables || {}
    }

    const options = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Shopify-Storefront-Access-Token': accessToken
        },
        body: JSON.stringify(params)
    };

    if ( recursive ) {

        let hasNextPage, lastCursor, products

        return new Promise((resolve, reject) => fetch(shopUrl + api, options)
            .then(response => {
                if (response.status !== 200)  {
                    throw `${response.status}: ${response.statusText}`;
                }
                response.json().then(r => {

                    const data = r.data

                    hasNextPage = data[queryType].pageInfo.hasNextPage
                    products = data[queryType].edges
                    lastCursor = products.slice(-1)[0].cursor
                    previousResults = previousResults.concat(data[queryType].edges);

                    if (hasNextPage) {
                        variables.after = lastCursor
                        storeFrontApi(query, variables, hasNextPage, queryType, previousResults).then(resolve).catch(reject)
                    } else {
                        resolve(previousResults);
                    }
                }).catch(reject);
            }).catch(reject));

    } else {

        return fetch(shopUrl + api, options)
            .then(checkStatus)
            .then(res => res.json())
            .then(r => {
                return r.data || r.errors || null;
            })
            .catch(error => {
                console.error('Error: /storefront', error)  // eslint-disable-line
                return undefined
            })

    }
}
