<template>
    <div>
        <app-section
            :title="page.mainTitle"
            :cover="cover"
            :breadcrumb="true"
        />
        <builder :builder="page.builder" />
        <push :push="page.push" />
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Builder from 'builder/Builder';
import Push from 'components/Push';

export default {
    name: 'About',
    components: {
        AppSection,
        Builder,
        Push,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    computed: {
        cover() {

            if ( !this.page || !this.page.asset )
                return

            if(this.page.asset.length === 0) {
                return null
            } else {
                return this.page.asset[0]
            }
        }
    }
};

</script>

<style lang="scss">

.p-about {

    .b-slider {

        .o-slide {
            // width: calc(var(--slide-width));

            &:nth-child(3n) {
                // width: calc(var(--slide-width) * 2);

                .o-slide__bg {
                    // padding-top: calc(4/3 * 50%);
                }
            }
        }


        .o-slide__bg + .o-slide__content {
            padding: 2em;
        }
    }

    // .l-section__cover {

    //     @media #{md("xs")} {
    //         width: calc(5/6 * 100%);
    //     }

    //     @media #{md("sm")} {
    //         width: calc(3/4 * 100%);
    //     }

    //     @media #{md("md")} {
    //         width: calc(2/3 * 100%);
    //     }
    // }
}

</style>
