<template>
    <article class="o-pm">
        <h2 class="o-pm__title">
            <icon icon="lock" />
            <span class="t-caps">
                {{ $t('misc.secureCheckout') }}
            </span>
        </h2>
        <ul class="o-pm__list">
            <li
                v-for="method in payments"
                :key="`pm-${method}`"
                class="o-pm__item"
            >
                <icon :icon="`logo-${method}`" />
            </li>
        </ul>
    </article>
</template>

<script>

import Icon from 'objects/Icon';

export default {
    name: 'PaymentMethods',
    components: {
        Icon,
    },
    data: () => ({
        payments: [
            'mastercard',
            'amex',
            'visa',
            'discover',
        ],
    }),
};

</script>

<style lang="scss">

.o-pm {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.o-pm__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 3em;
    margin-top: .5em;
    margin-bottom: .5em;

    .o-icon {
        margin-top: -.2em;
        margin-right: .5em;
    }
}

.o-pm__list {
    @include reset-list;

    color: $color-grey;
    display: flex;
    align-items: center;
    margin: -.5em;
}

.o-pm__item {
    margin: .5em;
}

</style>
