<template>
    <div
        v-if="columns"
        class="b-row"
    >
        <div
            v-for="(col, i) in columns"
            :key="`row-column-${i}`"
            class="b-row__col"
        >
            <component
                v-for="(component, j) in col.children"
                :key="`row-column-${i}-component-${j}`"
                :is="component.typeHandle"
                :data="component"
                class="b__block"
            />
        </div>
    </div>
</template>

<script>

import BlockText from 'builder/BlockText'
import BlockAsset from 'builder/BlockAsset'
import BlockProduct from 'builder/BlockProduct'

export default {
    name: 'BlockRow',
    components: {
        BlockText,
        BlockAsset,
        BlockProduct,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        columns() {
            const columns = this.data.children
            return columns.length > 0 ? columns : false
        },
    },
};

</script>

<style lang="scss">

.b-row {
    display: grid;
    grid-row-gap: 2em;
    grid-column-gap: var(--grid-gutter);

    @media #{md("xs")} {
        padding-right: calc(1/12 * 100%);
        padding-left: calc(1/12 * 100%);
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(2, 1fr);
        padding-right: 0;
        padding-left: 0;
    }
}

.b-row__col {
    display: flex;
    flex-wrap: wrap;

    >*:not(:first-child) {
        margin-top: 2em;
    }
}

</style>
