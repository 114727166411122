import { fetchApi } from 'src/graphql/craft/config'
import queries from 'src/graphql/craft/queries'

/**********************************
 *
 *
 *  CRAFT/NAVIGATIONS
 *
 *
 **********************************/

// State
const state = {
    searches: [],
    currentQuery: null,
}

// Getters
const getters = {
    getSearch: state => query => state.searches[query],
}

// Actions
const actions = {
    searchProducts(store, {query}) {

        return new Promise((resolve, reject) => {

            store.commit('setQuery', query );

            // Search if page already exists
            let result = store.getters.getSearch(query)

            // If search already exists, resolve with the result value
            if(typeof result !== 'undefined') {
                resolve(result)

            // If search doesn't exist, fetch
            } else {

                store.dispatch('products/searchProducts', {query}, {root: true})
                .then((results) => {

                    store.commit('addSearch', { query, results })
                    resolve(results)
                })

            }
        })
    },
}

// Mutations
const mutations = {
    setQuery(state, query) {
        state.currentQuery = query
    },
    addSearch(state, { query, data }) {
        state.searches[query] = data
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
