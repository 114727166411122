<template>
    <app-not-found v-if="error"/>
    <div v-else-if="product">
        <product-structured-datas :product="product" />
        <app-section
            :title="page.title"
            titleClass="t-t2"
            :breadcrumb="true"
            :headerCenter="false"
            class="p-product__main"
        >
            <template #before-content>
                <slider
                    v-if="assets"
                    ref="assetsSlider"
                >
                    <template v-for="(item, i) in assets">
                        <div
                            v-if="(item.asset && item.asset.length > 0) || item.video"
                            :key="`product-asset-${product.handle}-${i}`"
                            class="p-product__asset | o-slide"
                            :class="{ '-large' : item.width == 100 }"
                        >
                            <asset
                                v-if="item.asset && item.asset.length > 0"
                                v-bind="item.asset[0]"
                                :contain="true"
                                @load="$refs.assetsSlider.resize()"
                                @click.native="$refs.pswp.open(i)"
                            />
                            <vimeo
                                v-else-if="item.video"
                                :id="item.video"
                                :cover="true"
                                @load="$refs.assetsSlider.resize()"
                            />
                        </div>
                    </template>
                </slider>
            </template>

            <add-to-cart :product="product" />

            <div
                v-if="product.features.length"
                class="p-product__features | t-content"
            >
                <ul>
                    <li
                        v-for="(tag, i) in product.features"
                        :key="`tag-${i}`"
                    >
                        {{ tag.title }}
                    </li>

                </ul>
            </div>

            <div class="p-product__content | t-content">
                <anim-text
                    tag="h3"
                    :text="$t('product.description')"
                    class="t-t4"
                />
                <anim-text
                    :cms="true"
                    :text="product.description || product.descriptionHtml"
                />
                <accordion
                    v-if="global.sizingGuide || product.sizingGuide"
                    :title="$t('product.sizingGuide')"
                    :content="product.sizingGuide || global.sizingGuide"
                />
                <accordion
                    v-if="global.shippingInstructions || product.shippingInstructions"
                    :title="$t('product.shippingAndReturns')"
                    :content="product.shippingInstructions || global.shippingInstructions"
                />
            </div>

        </app-section>

        <app-section
            v-if="relatedProducts.length > 0"
            :title="$t('product.relatedProducts')"
            titleTag="h2"
            titleClass="t-t2"
        >
            <product-list
                :products="relatedProducts"
                :slider="false"
                :big="true"
            />
        </app-section>
        <pswp
            ref="pswp"
            :items="galleryAssets"
            :options="galleryOpts"
        />
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import AppNotFound from 'layout/AppNotFound';

import Asset from 'objects/Asset';
import Vimeo from 'objects/Vimeo';
import AnimText from 'objects/AnimText';
import Pswp from 'components/Pswp';
import Slider from 'components/Slider';
import ProductList from 'components/ProductList';
import AddToCart from 'components/AddToCart';
import Accordion from 'components/Accordion';
import ProductStructuredDatas from 'components/ProductStructuredDatas';

import Variables from 'src/mixins/variables';

export default {
    name: 'Product',
    components: {
        AppSection,
        AppNotFound,
        Asset,
        Vimeo,
        AnimText,
        Pswp,
        Slider,
        AddToCart,
        ProductList,
        Accordion,
        ProductStructuredDatas,
    },
    mixins: [ Variables ],
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    data: () => ({
        error: null,
        product: null,
        global: [],
        sliderEnabled: false,
        galleryOpts: {
            shareEl: false,
            closeOnScroll: false
        },
        recommendations: []
    }),
    created() {

        const handle = this.$route.params.slug

        // Load product
        this.$store.dispatch('products/loadProductBySlug', handle)
            .then(product => {
                this.product = product
                this.fetchRelatedProducts(product)
            })
            .catch(e => {

                if (e.code === 404)
                    this.error = true

            })

        // Load info
        this.$store.dispatch('globalsets/loadSet', 'productSet')
            .then(global => {
                this.global = global
            })
            .catch(e => {
                console.error('Error: Product', e)  // eslint-disable-line
            })
    },
    computed: {
        // Fetch related products (limit to 4)
        relatedProducts() {
            return this.recommendations
        },
        assets() {
            if(!this.product) {
                return false
            }

            // const assets = [...this.product.productAssets]

            // // Move 1st element to 3rd place for slider order
            // const firstAsset = assets[0];
            // assets.splice(0, 1);
            // assets.splice(2, 0, firstAsset);

            const assets = this.product.productAssets

            return assets
        },
        galleryAssets() {
            if(!this.assets || this.assets.length === 0) {
                return false
            }

            const assets = []
            let asset
            this.assets.forEach(item => {
                asset = item.asset
                if(asset && asset.length > 0) {
                    asset = asset[0]
                    assets.push({
                        src: asset.url,
                        w: asset.width,
                        h: asset.height,
                    })
                }
            })

            return assets
        },
    },
    methods: {
        fetchRelatedProducts(product) {

            let handles, titles, query

            this.$store.dispatch('products/loadProductRecommendations', product.shopifyId)
                .then(recommendations => {

                    if (recommendations.length > 0 ) {
                        handles = recommendations.map(product => product.handle)
                        titles = recommendations.map(product => product.title)
                        query = `(title:"${titles.join('") OR (title:"')}")`

                        this.$store.dispatch('products/searchProducts', {query, keySort: handles})
                            .then(products => {
                                // Commit/Limit to three products
                                this.recommendations = products.slice(0,3)
                            })
                            .catch(e => {
                                console.error('Error: Product recommendations', e)  // eslint-disable-line
                            })
                    }
                })
        }
    }
};

</script>

<style lang="scss">

.p-product__main {


    .l-section__header {
        padding-bottom: 2em;
        margin-bottom: var(--grid-gutter);
        border-bottom: $border-secondary-light;
    }

    .l-section__content {
        grid-area: content;
    }

    .p-product__features {
        margin-top: 3em;
        padding-top: 1em;
        padding-bottom: 1em;

        ul {
            display: grid;
            grid-gap: var(--grid-gutter-half);
            grid-template-columns: repeat(2, 1fr);
            list-style-type: none;
        }
    }

    .p-product__content {
        margin-top: 2em;
        padding-top: 1em;
        padding-bottom: 1em;
        border-top: $border-secondary-light;

        table {
            margin: 0 0 0.5em 0;
            border-collapse: collapse;

            td {
                padding: 0.5em;
                border: $border-secondary-light;

                p {
                    margin: 0;
                }
            }
        }
    }

    .p-product__asset {
        --contain-ratio: calc(4/3 * 100%);

        .o-asset {
            cursor: zoom-in;
        }
        .o-vimeo {
            padding-top: var(--contain-ratio);
        }
    }

    @media #{md("sm", "max")} {

        .c-slider {
            width: 100%;
            max-width: 24em;
            margin-right: auto;
            margin-bottom: var(--grid-gutter);
            margin-left: auto;

            .c-slider__flkty {
                --slide-width: 100%;
            }
        }
    }

    @media #{md("xs")} {
        width: calc(5/6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("sm")} {
        width: 100%;

        .l-section__inner {
            @include clearfix;
        }

        .l-section__header,
        .l-section__content {
            float: left;
            width: calc(2/5 * 100% - var(--grid-gutter-half));
        }

        .c-slider {
            float: right;
            width: calc(3/5 * 100% - var(--grid-gutter-half));

            .c-slider__flkty {
                display: grid;
                grid-gap: var(--grid-gutter-half);
                grid-template-columns: repeat(2, 1fr);

                &:after {
                    content: "";
                }

                & + .c-slider__nav {
                    display: none;
                }
            }
        }

        .p-product__asset {

            &.-large {
                grid-column: 1/3;
            }

            // ORDER HACK
            // &:nth-child(3) {
            //     order: -1;
            // }
            // END ORDER HACK
        }

        .c-product-list {

            .c-product-card:nth-child(4) {
                display: none;
                visibility: hidden;
            }
        }
    }

    @media #{md("md")} {

        .l-section__header,
        .l-section__content {
            float: left;
            width: calc(1/3 * 100% - var(--grid-gutter-half));
        }

        .c-slider {
            width: calc(2/3 * 100% - var(--grid-gutter-half));
        }

        .p-product__features ul {
            grid-template-columns: repeat(4, 1fr);
        }

        .c-product-list {

            .c-product-card:nth-child(4) {
                display: block;
                visibility: visible;
            }
        }
    }
}

</style>
