<template>
    <div v-if="images" class="c-instagram">
        <div class="c-instagram__inner | grid">
            <a
                title="Instagram"
                :href="profileUrl"
                class="c-instagram__link | t-link"
                target="_blank"
                rel="noopener"
            >
                <anim-text
                    tag="h2"
                    :text="title"
                    class="c-instagram__title | t-t1"
                />
            </a>

            <slider
                class="c-instagram__slider"
                :slides="images"
                modifier="-full-vw -center"
            />
        </div>
    </div>
</template>

<script>

import AnimText from 'objects/AnimText';
import Slider from 'components/Slider';

import { mapGetters } from 'vuex'

export default {
    name: 'Instagram',
    components: {
        AnimText,
        Slider,
    },
    computed: {

        ...mapGetters({
            account: 'global/getInstagramAccount',
            images: 'global/getInstagramImages'
        }),
        title() {

            if (!this.account)
                return

            return `@${this.account.username}`
        },
        profileUrl() {

            if (!this.account)
                return

            return this.account.url
        }
    },
};

</script>

<style lang="scss">


.c-instagram {
    margin-top: var(--section-margin);
    margin-bottom: var(--section-margin);
}

.c-instagram__inner {
    text-align: center;
}

.c-instagram__link {

    &:after {
        display: none;
    }
}

.c-instagram__slider {

    .c-slider__flkty {
        --slide-width: calc(1/3 * 100%);
    }

    .o-slide {

        &:nth-child(even) {
            width: calc(var(--slide-width) * 1.5);
        }
    }

    @media #{md("sm")} {

        .c-slider__flkty {
            --slide-width: calc(1/4 * 100%);
        }
    }
}

</style>
