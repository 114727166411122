export default {
    btn: {
        addToCart: 'Add to cart',
        outOfStock: 'Out of Stock',
        credits: 'Credits',
        subscribe: 'Subscribe',
        readMore: 'Read More',
        readPosts: 'Read all publications',
        more: 'More',
        shop: 'Shop',
        shopAll: 'Shop All',
        shopCollections: 'Shop our collections',
        shopNow: 'Shop Now',
        viewAll: 'View All',
        shopCollection: 'Shop the Collection',
        backToHome: 'Back to home',
        adding: 'Adding...',
        attendEvent: 'Attend event',
        learnMore: 'Learn more',
        directions: 'Get directions',
        chat: 'Chat with us',
        findStore: 'Find a store',
        loadMore: 'Load More',
        newTab: `Open the link in a new tab`
    },
    header: {
        searchPlaceholder: 'Search for dresses, tops, bottoms',
    },
    misc: {
        product: 'product',
        display: 'Display',
        wantToBuy: 'Shop',
        categories: 'Category',
        emailUs: 'Email us',
        callUs: 'Call us',
        location: 'Location',
        openingHours: 'Opening Hours',
        loading: 'Loading',
        size: 'Size',
        sizes: 'Sizes',
        ourStores: 'Our Stores',
        storesIn: 'stores in',
        regions: 'regions',
        order: 'Order by',
        priceASC: 'Low to High',
        priceDESC: 'High to Low',
        newsletterTitle: `Don't miss a <i>thread</i>`,
        nextEvents: `Next <i>events</i>`,
        secureCheckout: 'Secure checkout'
    },
    detection: {
        message: `You're on the {currentSiteCode} site shopping in {currency}. You are from {userCountryName}, switch to the site that features more products available to you.`,
        the: `the {country}`
    },
    product: {
        description: 'Description',
        price: 'Price',
        outOfStock: 'Out of stock',
        inSale: 'Sale',
        relatedProducts: 'You might also like',
        sizingGuide: 'Sizing Guide',
        shippingAndReturns: 'Shipping and Returns',
    },
    form: {
        yourEmail: 'Your Email',
        loading: 'Loading...',
        success: 'Sucess. Welcome on board!',
    },
    aria: {
        displayBigGrid: 'Display as large thumbnails',
        displaySmallGrid: 'Display as small thumbnails',
        close: 'Close',
        closeSubnav: 'Close sub navigation',
        next: 'Next',
        prev: 'Previous',
        search: 'Search',
        toggle: 'Open/Close',
        toggleNav: 'Open/Close menu',
        toggleSearch: 'Open/Close search',
        toggleCart: 'Open/Close cart',
    },
    search: {
        label: 'Search the products',
        searched: 'You searched for',
        noResult: 'We found 0 products for',
        tryShop: 'Maybe try our shop?',
        seeMore: 'Not exactly what you’re looking for?',
        recommendations: 'You may like',
        favorites: 'Favorite Searchs',
    },
    cart: {
        empty: 'Your Cart is Empty',
        title: 'Your Cart',
        review: 'Review my cart',
        checkout: 'Checkout now',
        orderSummary: 'Order summary',
        orderSubtotal: 'Order subtotal',
        total: 'Total',
        subtotal: 'Items subtotal',
        shipping: 'Shipping',
        taxes: 'Taxes',
        addedAtCheckout: 'Added at checkout',
    },
    credits: 'Site by MamboMambo'
}
