<template>
    <div>
        <template v-if="totalProducts === 0">
            <app-section
                :title="$t('cart.empty')"
                titleTag="h2"
                titleClass="t-t2"
                class="p-cart__empty"
            >
                <template #after-title>
                    <btn
                        tag="router-link"
                        :href="'shop'|resolve"
                        :title="$t('btn.shopAll')"
                        :label="$t('btn.shopAll')"
                        :reveal="false"
                    />
                </template>
            </app-section>
        </template>
        <template v-else>
            <app-section
                :title="$t('cart.title')"
                titleTag="h2"
                titleClass="t-t4"
                :headerCenter="false"
                class="p-cart__review"
            >
                <template #after-title>
                    <rounded :number="totalProducts" />
                </template>
                <ul class="o-cart-list">
                    <li
                        v-for="line in products"
                        :key="`mini-cart-product-${line.variantId}`"
                        class="o-cart-list__item"
                    >
                        <asset
                            v-bind="line.thumb"
                            class="o-cart-list__image"
                            :reveal="false"
                            :cover="false"
                        />
                        <div class="o-cart-list__content">
                            <div class="o-cart-list__header">
                                <router-link :to="`/${line.uri}`" class="o-cart-list__name | t-small">
                                    {{ line.title }}
                                </router-link>
                                <button
                                    @click="remove(line)"
                                    class="o-cart-list__remove | u-as"
                                >
                                    <icon icon="cross" />
                                </button>
                            </div>
                            <div v-if="line.variant && line.variant.selectedOptions">
                                <span

                                    v-for="option in cleanOptions(line.variant.selectedOptions)"
                                    :key="`cart-option-${line.variantId}-${option.name}`"
                                    class="o-cart-list__size | t-caps"
                                    >
                                    {{ option.name }}: {{ option.value }}
                                </span>
                            </div>
                            <div class="u-dp-flex u-ai-center u-jc-between">
                                <span class="o-cart-list__update">
                                    <button
                                        :class="{ 'is-disabled' : line.quantity <= 1 }"
                                        class="u-as"
                                        @click="$store.dispatch('cart/decrement', line)"
                                    >-</button>
                                    <span class="o-cart-list__qty">
                                        {{ line.quantity }}
                                    </span>
                                    <button
                                        :class="{ 'is-disabled' : !line.hasMore }"
                                        class="u-as"
                                        @click="$store.dispatch('cart/increment', line)"
                                    >+</button>
                                </span>
                                <price
                                    :amount="line.price"
                                    :compareAt="line.compareAtPrice"
                                    class="o-cart-list__price"
                                />
                            </div>
                        </div>
                    </li>
                </ul>
                <box class="o-summary">
                    <h3 class="o-summary__title | t-t4">{{ $t('cart.orderSummary') }}</h3>
                    <ul class="o-summary__list">
                        <li class="o-summary__item">
                            <span class="o-summary__subtotal">
                                <h4 class="t-t5-sans">{{ $t('cart.subtotal') }}</h4>
                                <rounded :number="totalProducts" />
                            </span>
                            <price :amount="subtotal" />
                        </li>
                        <li class="o-summary__item">
                            <h4 class="t-t5-sans">{{ $t('cart.shipping') }}</h4>
                            <span class="t-t5-sans | u-c-grey">{{ $t('cart.addedAtCheckout') }}</span>
                        </li>
                        <li class="o-summary__item">
                            <h4 class="t-t5-sans">{{ $t('cart.taxes') }}</h4>
                            <span class="t-t5-sans | u-c-grey">{{ $t('cart.addedAtCheckout') }}</span>
                        </li>
                    </ul>
                    <h3 class="o-summary__title | t-t4">{{ $t('cart.orderSubtotal') }}</h3>
                    <btn
                        tag="span"
                        bg="dark"
                        size="md"
                        :reveal="false"
                        class="o-summary__total"
                    >
                        <h4 class="t-t5-sans">{{ $t('cart.total') }}</h4>
                        <price :amount="subtotal" />
                    </btn>
                    <btn
                        :label="$t('cart.checkout')"
                        iconAfter="arrow-long-right"
                        bg="dark"
                        size="md"
                        :reveal="false"
                        @click.native="decorateUrl(checkoutUrl)"
                    />
                    <payment-methods class="o-summary__pm" />
                </box>
            </app-section>
            <app-section
                v-if="relatedProducts.length > 0"
                :title="$t('product.relatedProducts')"
                titleTag="h2"
                titleClass="t-t2"
            >
                <product-list />
            </app-section>
        </template>
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Box from 'objects/Box';
import Icon from 'objects/Icon';
import Asset from 'objects/Asset';
import Price from 'objects/Price';
import Rounded from 'objects/Rounded';
import AnimText from 'objects/AnimText';
import PaymentMethods from 'objects/PaymentMethods';
import Btn from 'components/Btn';
import ProductList from 'components/ProductList';

import { mapState, mapGetters } from 'vuex'

export default {
    name: 'Blog',
    components: {
        AppSection,
        Box,
        Icon,
        Asset,
        Price,
        Rounded,
        AnimText,
        PaymentMethods,
        Btn,
        ProductList,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    data: () => ({
        checkoutUrl: null,
    }),
    created() {
        // Create checkout
        this.$store.dispatch('checkout/create')
            .then(checkout => {
                if (checkout && checkout.webUrl) {
                    this.checkoutUrl = checkout.webUrl
                }
            })
            .catch(e => {
                console.error('Error: checkout/create', e)  // eslint-disable-line
            })
    },
    computed: {
        ...mapState({
            products: state => state.cart.lines,
        }),

        ...mapGetters({
            subtotal: 'cart/getLinesSubtotal',
        }),

        totalProducts() {
            return this.products.length
        },

        // Fetch related products (limit to 4)
        relatedProducts() {
            return []
        },
    },
    methods: {

        remove(line) {

            this.$store.dispatch('cart/remove', line)
                .then(() => {
                    this.$emit('removed', line)
                })
                .catch(e => {
                    console.error('Error: cart/remove', e)  // eslint-disable-line
                })
        },
        cleanOptions(options) {
            return options.filter(option => option.name != 'Title')
        },
        decorateUrl(urlString) {

            //console.log('::decorateUrl() called')  // eslint-disable-line

            const ga = window[window['GoogleAnalyticsObject']];
            let tracker;

            if (ga && typeof ga.getAll === 'function') {

                //console.log('::decorateUrl() truly fired')  // eslint-disable-line
                tracker = ga.getAll()[0]
                urlString = (new window.gaplugins.Linker(tracker)).decorate(urlString)
                //console.log('::decorated Url: ', urlString)  // eslint-disable-line
            }

            window.location.href = urlString
        },
    }
};

</script>

<style lang="scss">

.p-cart {
}

.p-cart__empty {

    .l-section__title {
        margin-bottom: 1em;
    }
}

.p-cart__review {

    .l-section__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1em;
    }

    .l-section__title {
        margin-right: .4rem;
    }

    .l-section__content {
        display: grid;
    }

    @media #{md("sm", "max")} {

        .l-section__content {
            grid-row-gap: 2em;
        }
    }

    @media #{md("sm")} {

        .l-section__content {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-column-gap: var(--grid-gutter);
            align-items: flex-start;
        }
    }

    @media #{md("md")} {

        .l-section__content {
            grid-template-columns: 5fr 6fr;
            grid-column-gap: calc(1/12 * 100%);
        }
    }
}


/*----------  Cart list  ----------*/

.o-cart-list {
    @include reset-list;
}

.o-cart-list__item {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-column-gap: var(--grid-gutter);
    padding-top: 1em;
    padding-bottom: 1em;

    &:not(:first-child) {
        border-top: $border-secondary-light;
    }
}

.o-cart-list__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.o-cart-list__remove {
    margin-top: -.75em;
}

.o-cart-list__update {
    display: flex;
    align-items: center;

    button {
        background-color: $color-primary;
        border-radius: 50%;

        &.is-disabled {
            opacity: .5;
            pointer-events: none;
        }
    }
}

.o-cart-list__qty {
    display: block;
    width: 2em;
    text-align: center;
    margin-right: .5em;
    margin-left: .5em;
}



/*----------  Summary  ----------*/

.o-summary {
    text-align: left;

    .c-btn {
        width: 100%;
    }

    .c-btn__inner {
        width: 100%;
        justify-content: space-between;
    }

    @media #{md("sm")} {
        margin-top: -2.5em;
    }
}

.o-summary__title {}

.o-summary__list {
    @include reset-list;
    margin-top: 1em;
    margin-bottom: 2.5em;
}

.o-summary__item {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 1em;
    padding-bottom: 1em;

    &:not(:first-child) {
        border-top: $border-secondary-light;
    }
}

.o-summary__subtotal {
    display: inline-flex;
    align-items: center;

    .o-rounded {
        margin-left: .5em;
    }
}

.o-summary__total {
    margin-top: 1.5em;
    padding-top: 2em;
    padding-bottom: 2em;
    color: $color-light;
    background-color: $color-dark;
    cursor: initial;

    .c-btn__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &:after {
        @include pseudo-el($height: 1px, $bg: $color-light);
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.o-summary__pm {
    margin-top: 1em;
}

</style>
