/*
** Debounce function
*/
export const debounce = (callback, delay = 250) => {
    let timeoutId
    return (...args) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            timeoutId = null
            callback(...args)
        }, delay)
    }
}


/*
** Round number with n decimals
*/

export const round = (x, n=2) => {
  const decimals = 10 ** n
  return Math.round(x * decimals)/decimals
}


/*
** Reduced motion var
*/

export const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches


/*
** Add resizEnd event to window
*/

export const resizeEnd = () => {
    const resizeEnd = new Event('resizeEnd');

    window.addEventListener('resize', debounce(() => {
        window.dispatchEvent(resizeEnd)
    }, 200));
}


export const orderObjectByKey = (array, order, key) => {

    array.sort( function (a, b) {
        var A = a[key], B = b[key];

        if (order.indexOf(A) > order.indexOf(B)) {
            return 1;
        } else {
            return -1;
        }

    });

    // Then sort by isFeatured
    array.sort((a, b) => b.isFeatured - a.isFeatured);

    return array;
}


/*
 *    Check a response status
 */

export const checkStatus = (response) => {

    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
    }
}
