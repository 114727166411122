/*
** StoreFrontApi GraphQL Mutations
*/

import snippets from './snippets'


/**********************************
 *
 *
 *  CHECKOUT
 *
 *
 **********************************/

// checkoutCreate
const checkoutCreate = `mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
        ${snippets.checkout}
    }
}`;


const checkoutLineItemsAdd = `mutation checkoutLineItemsAdd($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
        ${snippets.checkout}
        checkoutUserErrors {
            code
            field
            message
        }
    }
}
`
// checkoutLineItemsUpdate
const checkoutLineItemsUpdate = `mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
        ${snippets.checkout}
        checkoutUserErrors {
            code
            field
            message
        }
    }
}
`

// checkoutLineItemsUpdate
const checkoutLineItemsRemove = `mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
  checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
        ${snippets.checkout}
        checkoutUserErrors {
            code
            field
            message
        }
    }
}
`

export default {
    checkoutCreate,
    checkoutLineItemsAdd,
    checkoutLineItemsUpdate,
    checkoutLineItemsRemove
}
