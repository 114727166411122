import {storeFrontApi} from 'src/graphql/shopify/config';
import {default as storefrontQueries} from 'src/graphql/shopify/queries';
//import {default as storefrontMutations} from 'src/graphql/shopify/mutations';

/**********************************
 *
 *
 *  SHOPIFY/SHOP
 *
 *
 **********************************/

// State
const state = {
    settings: {},
    tags: []
}

// Getters
const getters = {
    getSettings: state => state.settings,
    getMoneyFormat: state => state.settings.moneyFormat ? state.settings.moneyFormat : false,
    getCurrencyCode: state => state.settings.paymentSettings ? state.settings.paymentSettings.currencyCode : false,
    getTags: state => state.tags
}

// Actions
const actions = {

    init(store) {

        // Start load
        store.dispatch('loader/startLoad', null, {root: true});

        return Promise.all([
            store.dispatch('loadSettings'),
            //store.dispatch('loadTags')
        ]).then(() => {

            // End load
            store.dispatch('loader/endLoad', null, {root: true});
        })
        .catch(e => {
            console.error('Error: Shopify init', e) // eslint-disable-line
        })
    },
    loadSettings(store) {

        // Start load
        store.dispatch('loader/startLoad', null, {root: true});

        return new Promise((resolve, reject) => {

            // Get settings
            let settings = store.getters.getSettings

            // If exists, resolve right away.
            if (settings.length) {

                // Resolve
                resolve(settings)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // Else, fetch.
            } else {

                storeFrontApi(storefrontQueries.settings)
                    .then(r => {

                        if ( !r || !r.shop ) {
                            reject(r)

                        } else {

                            // Commit, resolve
                            store.commit('setSettings', r.shop)
                            resolve(r.shop)

                            // End load
                            store.dispatch('loader/endLoad', null, {root: true});
                        }
                    })
            }
        })

    },
    loadTags(store) {

        return new Promise((resolve, reject) => {

            // Get tags
            let tags = store.getters.getTags

            // If exists, resolve right away.
            if (tags.length) {

                // Resolve
                resolve(tags)

            // Else, fetch.
            } else {

                storeFrontApi(storefrontQueries.tags)
                    .then(r => {

                        if ( !r || !r.shop ) {
                            reject(r)

                        } else {
                            // Commit, resolve
                            store.commit('setTags', r.productTags.edges)
                            resolve(r.productTags.edges)
                        }
                    })
            }
        })
    },
}

// Mutations
const mutations = {
    setSettings(state, settings) {
        state.settings = settings
    },
    setTags(state, tags) {
        state.tags = tags
    }
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
