<template>
    <app-section
        tag="div"
        :key="$route.fullPath"
    >
        <div class="grid">
            <div
                v-if="isSearching"
                class="p-search__loading"
            >
                <h2 class="p-search__title | t-t2">
                    {{ $t('misc.loading') }}...
                </h2>
            </div>
            <div
                v-else-if="totalResults > 0"
                class="p-search__results"
            >
                <h2 class="p-search__title | t-t2">
                    {{ $t('search.searched') }} &quot;{{ queryInput }}&quot;
                </h2>
                <h3 class="p-search__sub | t-small">
                    {{ totalResults }} <em>{{ $t('misc.product') }}{{ totalResults > 1 ? 's' : '' }}</em>
                </h3>
                <product-list
                    :products="results"
                    :slider="false"
                />
                <div class="p-search__section">
                    <p class="t-t2">{{ $t('search.seeMore') }}</p>
                    <div class="u-dp-flex u-jc-center">
                        <btn
                            tag="router-link"
                            :href="'shop'|resolve"
                            :label="$t('btn.shopNow')"
                            bg="primary-light"
                            class="p-search__btn"
                        />
                    </div>
                </div>
            </div>
            <div
                v-else
                class="p-search__empty"
            >
                <h2 class="p-search__title | t-t2">
                    {{ $t('search.noResult') }} &quot;{{ queryInput }}&quot;
                </h2>
                <div class="p-search__section -border-top">
                    <p class="t-t2">{{ $t('search.tryShop') }}</p>
                    <div class="u-dp-flex u-jc-center">
                        <btn
                            tag="router-link"
                            :href="'shop'|resolve"
                            :label="$t('btn.shopNow')"
                            bg="primary-light"
                            class="p-search__btn"
                        />
                    </div>
                </div>
                <h3 class="p-search__sub | t-small">
                    {{ $t('search.recommendations') }}
                </h3>
                <product-list
                    :products="results"
                    :slider="false"
                />
            </div>
        </div>
    </app-section>
</template>

<script>

import AppSection from 'layout/AppSection';
import Btn from 'components/Btn';
import ProductList from 'components/ProductList';

import { mapState } from 'vuex'

export default {
    name: 'Search',
    components: {
        AppSection,
        Btn,
        ProductList,
    },
    props: {
        page: Object,
    },
    data: () => ({
        isSearching: false,
        results: [],
    }),
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    created() {
        this.search()
    },
    computed: {
        ...mapState({
            queryInput: state => state.search.currentQuery
        }),
        query() {
            const query = this.$route.query.q
            return typeof query !== 'undefined' ? query : ''
        },
        totalResults() {
            return this.results.length
        },
    },
    methods: {
        search() {

            // Fetch results
            this.isSearching = true
            const query = this.query

            this.$store.dispatch('search/searchProducts', {query})
            .then(results => {
                this.results = results
                this.isSearching = false
            })
            .catch(e => {
                console.error('Error: Search', e) // eslint-disable-line
            })



        },
    },
    watch: {
        $route() {
            this.search()
        }
    }
};

</script>

<style lang="scss">

.p-search__loading {}
.p-search__results {}

.p-search__empty {
}

.p-search__title {
    z-index: 1;
}

.p-search__sub {
    color: $color-secondary-dark;
}

.p-search__title,
.p-search__section {
    padding-top: var(--section-margin-half);
    padding-bottom: var(--section-margin-half);
    text-align: center;
}

.p-search__section {

    &.-border-top {
        border-top: $border-secondary-light;
    }

    @media #{md("xs")} {
        --padding-x: calc((100% - 100% * 3/4)/2);
        padding-right: var(--padding-x);
        padding-left: var(--padding-x);
    }

    @media #{md("sm")} {
        --padding-x: calc((100% - 100% * 2/3)/2);
    }

    @media #{md("md")} {
        --padding-x: calc((100% - 100% * 1/2)/2);
    }
}

.p-search__btn {
    margin-top: 1em;
}

</style>
