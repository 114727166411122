<template>

    <div
        v-if="currentVariant"
        class="c-adc"
        :class="{'-waiting' : waiting }"
    >

        <div class="c-adc__inner | t-content">

            <price
                :amount="currentVariant.price"
                :compareAt="currentVariant.compareAtPrice"
                class="c-adc__price"
            />

            <!-- Variations mecanic here-->

            <div
                v-if="hasVariants"
                v-for="option in product.options"
                :key="`option-${option.name}`"
            >

                <h4 v-if="product.options.length > 1">
                    {{ option.name }}
                </h4>

                <app-input-check
                    :group="option.name"
                    :radio="true"
                    :inputs="formatValuesForInput(option)"
                    :defaultValue="getSelectedValueByOptionName(option.name)"
                    @input="updateCurrentVariant"
                />
            </div>

            <btn
                :disabled="!isCurrentVariantAvailableForSale"
                :id="currentVariant.id"
                @click.native="addToCart()"
                :label="btnLabel"
                bg="dark"
                iconAfter="cart"
                size="md"
                class="c-adc__btn"
            />
        </div>
    </div>
</template>

<script>
// TODOS

// [X]
// S’assurer que la currentVariant est disponible à l'achat (availableForSale, currentlyNotInStock et quantityAvailable)
// Changer l'état du bouton (+ caption?) si elle est non disponible

// [ ]
// Attacher les option.value aux variantes (variant.selectedOptions) et s'assurer que ça fonctionne pour tous les cas de figures
// c'est-a-dire A) Les produits sans variantes B) les produits avec un seul niveau (ex. Size) et C) les produits avec plusieurs niveaux (ex. Size, Color, etc.)

// [ ]
// Refaire un fetch de la variante spécifique (node.id) au AddToCart pour s'assurer qu'elle est toujours disponible au moment de l'ajout

// [ ]
//
//

import Price from 'objects/Price';
import Btn from 'components/Btn';
import AppInputCheck from 'components/AppInputCheck';

export default {
    name: 'AddToCart',
    components: {
        Price,
        Btn,
        AppInputCheck,
    },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        error: false,
        waiting: false,
        selectedOptions: null,
    }),
    created() {

        // Start with the first variant
        if(this.product.available) {
            this.selectedOptions = this.variants.find(v => v.availableForSale && v.quantityAvailable > 0 && !v.currentlyNotInStock).selectedOptions
        } else {
            this.selectedOptions = this.variants[0].selectedOptions
        }

    },
    computed: {
        variants() {
            return this.product.variants
        },
        btnLabel() {

            return !this.isCurrentVariantAvailableForSale ? this.$t('btn.outOfStock') : this.waiting ? this.$t('btn.adding') : this.$t('btn.addToCart')
        },
        isCurrentVariantAvailableForSale() {

            return (this.currentVariant.availableForSale && this.currentVariant.quantityAvailable > 0 && !this.currentVariant.currentlyNotInStock)
        },
        currentVariant() {

            let _currentVariant = this.variants.find(v => v.selectedOptions === this.selectedOptions)

            if (_currentVariant) {
                return this.formatVariant(_currentVariant)
            }
        },
        hasVariants() {

            return (this.variants.length > 1) ? true : false
        }
    },
    methods: {

        addToCart() {

            const product = this.product
            const variant = this.currentVariant
            const quantity = 1

            // Check if variantId is in our variants
            if ( !this.variants.find(v => v.id === variant.id) ) {
                return
            }

            // Abort if the variant can't be purchase
            if ( !this.isCurrentVariantAvailableForSale ) {
                return
            }

            // Start waiting status
            this.waiting = true

            // addToCart
            this.$store.dispatch('cart/add', { product, variant, quantity })
                .then(line => {

                    if ( !line.id )
                        this.error = true

                    // Stop waiting status
                    setTimeout(() => this.waiting = false, 500)
                })
                .catch(e => {
                    this.error = true
                    setTimeout(() => this.waiting = false, 500)
                    console.error('Error: Add to cart', e)  // eslint-disable-line
                })

        },
        updateCurrentVariant(option) {

            let matchedVariant = this.getVariantByOption(option.group, option.value)

            if ( matchedVariant ) {
                this.selectedOptions = matchedVariant.selectedOptions
            }
        },
        getSelectedValueByOptionName(optionName) {

            return this.selectedOptions.find(option => option.name == optionName).value
        },
        getVariantByOption(name, value) {

            return this.variants.find(v => v.selectedOptions.find(o => o.name === name && o.value === value))
        },
        formatVariant(variant) {

            return {
                availableForSale: variant.availableForSale,
                compareAtPrice: variant.compareAtPriceV2 ? variant.compareAtPriceV2.amount : null,
                currentlyNotInStock: variant.currentlyNotInStock,
                id: variant.id,
                price: variant.priceV2.amount,
                quantityAvailable: variant.quantityAvailable,
                selectedOptions: variant.selectedOptions,
                sku: variant.sku
            }
        },
        formatValuesForInput(optionGroup) {

            //const prefix = optionGroup.name.toLowerCase()
            let values = []

            optionGroup.values.forEach(value => {
                values.push({
                   id: value,
                   checked: true,
                   title: value,
                   disabled: !this.getVariantByOption(optionGroup.name, value).availableForSale
                })
            })

            return values
        }
    }
};

</script>

<style lang="scss">

.c-adc {

    &.-waiting {
        opacity: 0.5;
        cursor: wait;
        pointer-events: none;
    }
}

.c-adc__price {
    display: block;
}

.c-adc__btn {
}

</style>
