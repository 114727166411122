<template>
    <div>
        <app-section
            :title="page.mainTitle"
            :breadcrumb="true"
        >
            <template #after-title>
                <anim-text
                    tag="h2"
                    :text="page.headline"
                    class="t-t4"
                />
            </template>

            <div class="p-contact__cs">
                <anim-text
                    tag="h2"
                    :text="customerService.headline"
                    class="t-t2"
                />
                <box :columns="true">
                    <div class="t-content">
                        <icon icon="chat" />
                        <h4
                            v-html="customerService.chatTitle"
                            class="t-t4"
                        ></h4>
                        <div
                            v-html="customerService.chatDesc"
                            class="t-cms"
                        ></div>
                        <btn
                            :label="$t('btn.chat')"
                        />
                    </div>
                    <div class="t-content">
                        <icon icon="phone" />
                        <h4
                            v-html="customerService.phoneTitle"
                            class="t-t4"
                        ></h4>
                        <div
                            v-html="customerService.phoneDesc"
                            class="t-cms"
                        ></div>
                        <btn
                            tag="a"
                            :href="customerService.phoneNumber|phoneHref"
                            title="Phone"
                            target="_blank"
                            rel="noopener"
                            :label="`Tel ${customerService.phoneNumber}`"
                        />
                    </div>
                    <div class="t-content">
                        <icon icon="mail" />
                        <h4
                            v-html="customerService.emailTitle"
                            class="t-t4"
                        ></h4>
                        <div
                            v-html="customerService.emailDesc"
                            class="t-cms"
                        ></div>
                        <btn
                            tag="a"
                            :href="`mailto:${customerService.emailAddress}`"
                            :title="$t('misc.emailUs')"
                            target="_blank"
                            rel="noopener"
                            :label="customerService.emailAddress"
                        />
                    </div>
                </box>
            </div>

            <list
                :items="page.contactList"
                modifier="contact"
                class="p-contact__list"
            >
                <template #left="item">
                    <anim-text
                        tag="h2"
                        :text="item.label"
                        class="t-t2"
                    />
                </template>
                <template #right="item">
                    <div
                        v-if="item.email"
                    >
                        <icon icon="mail" />
                        <anim-text
                            tag="h3"
                            :text="$t('misc.emailUs')"
                            class="t-t4"
                        />
                        <btn
                            tag="a"
                            :href="`mailto:${item.email}`"
                            :title="$t('misc.emailUs')"
                            target="_blank"
                            rel="noopener"
                            :label="item.email"
                            bg="primary-light"
                        />
                    </div>
                    <div
                        v-if="item.phone"
                    >
                        <icon icon="phone" />
                        <h3 class="t-t4">{{ $t('misc.callUs') }}</h3>
                        <btn
                            tag="a"
                            :href="item.phone|phoneHref"
                            title="Phone"
                            target="_blank"
                            rel="noopener"
                            :label="`Tel ${item.phone}`"
                            bg="primary-light"
                        />
                    </div>
                </template>
            </list>

            <box
                :columns="true"
                :transparent="true"
            >
                <div
                    v-for="office in page.officesList"
                    :key="`office-${office.label}`"
                    class="t-content"
                >

                    <icon icon="map-picker" />
                    <h4
                        v-html="office.label"
                        class="t-t4"
                    ></h4>
                    <app-address
                        :address="office.address"
                        :directions="true"
                    />
                </div>
                <div class="t-content">
                    <icon icon="map-picker" />
                    <h4 class="t-t4"
                    >
                        {{ $t('misc.ourStores') }}
                    </h4>
                    <btn
                        tag="router-link"
                        :href="'stores'|resolve"
                        :title="$t('misc.ourStores')"
                        :label="$t('btn.findStore')"
                        bg="primary-light"
                    />
                </div>
            </box>
        </app-section>
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Box from 'objects/Box';
import Icon from 'objects/Icon';
import AnimText from 'objects/AnimText';
import AppAddress from 'objects/AppAddress';
import Btn from 'components/Btn';
import List from 'components/List';

export default {
    name: 'Contact',
    components: {
        AppSection,
        Box,
        Icon,
        AnimText,
        AppAddress,
        Btn,
        List,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    computed: {
        customerService() {
            return this.page.customerService[0]
        },
    },
};

</script>

<style lang="scss">

.p-contact {

    .l-section__title {
        margin-bottom: .5em;
    }
}

.p-contact__cs {
    margin-top: var(--section-margin);
    text-align: center;
}

.p-contact__list {

    @media #{md("md")} {
        padding-right: calc(1/12 * 100%);
        padding-left: calc(1/12 * 100%);
    }
}

</style>
