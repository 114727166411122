<template>
    <component
        :is="tag"
        class="l-section"
    >
        <div class="l-section__inner | grid">

            <header
                class="l-section__header"
                :class="headerCenter ? '-center' : 'left'"
            >

                <breadcrumb
                    v-if="breadcrumb"
                    :center="headerCenter"
                />

                <anim-text
                    :tag="titleTag"
                    :text="title"
                    class="l-section__title"
                    :class="titleClass"
                />

                <slot name="after-title" />

            </header>

            <asset
                v-if="coverAsset"
                v-bind="coverAsset"
                class="l-section__cover"
            />

            <slot name="before-content" />

            <div class="l-section__content">
                <slot />
            </div>
        </div>
    </component>
</template>

<script>

import Breadcrumb from 'layout/Breadcrumb';
import Asset from 'objects/Asset';
import AnimText from 'objects/AnimText';

export default {
    name: 'AppSection',
    components: {
        Breadcrumb,
        Asset,
        AnimText,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        tag: {
            type: String,
            default: 'section',
        },
        titleTag: {
            type: String,
            default: 'h1',
        },
        titleClass: {
            type: String,
            default: 't-t1',
        },
        cover: {
            type: Object|Array,
            default: null,
        },
        headerCenter: {
            type: Boolean,
            default: true,
        },
        breadcrumb: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        coverAsset() {
            if(!this.cover) {
                return false
            } else {

                // If object, return object
                if (!Array.isArray(this.cover)) {
                    return this.cover
                }

                // Return first instance if array
                return this.cover.length > 0 ? this.cover[0] : false
            }
        },
    },
};

</script>

<style lang="scss">

.l-section {
    margin-top: var(--section-margin);
    margin-bottom: var(--section-margin);
}

.l-section__header {

    .l-breadcrumb {
        z-index: 2;
    }

    &.-center {
        text-align: center;
    }

    @media #{md("xs")} {

        &.-center {
            width: calc(5/6 * 100%);
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media #{md("sm")} {

        &.-center {
            width: calc(3/4 * 100%);
        }
    }

    @media #{md("md")} {

        &.-center {
            width: calc(7/8 * 100%);
        }
    }
}

.l-section__title {
    z-index: 1;
    line-height: 1;
    margin-top: .2em;
    margin-bottom: -.126em;
}

.l-section__cover {
    --width: 100%;

    width: var(--width);
    margin-right: auto;
    margin-left: auto;

    @media #{md("xs")} {
        --width: calc(5/6 * 100%);

        &.-portrait {
            --width: calc(3/4 * 100%);
        }
    }

    @media #{md("sm")} {
        --width: calc(3/4 * 100%);

        &.-portrait {
            --width: calc(2/3 * 100%);
        }
    }

    @media #{md("md")} {
        --width: calc(2/3 * 100%);

        &.-portrait {
            --width: calc(1/2 * 100%);
        }
    }
}

.l-section__desc {
    margin-top: 2em;
    margin-bottom: 2em;
}

</style>
