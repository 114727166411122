<template>
    <div
        :class="className"
        v-reveal.once="reveal ? revealHandle : false"
    >
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: 'Box',
    props: {
        columns: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        reveal: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isVisible: false,
    }),
    created() {
        this.isVisible = this.visible
    },
    computed: {
        className() {
            let classname = 'o-box'

            if(this.columns) {
                classname += ' -columns'
            }

            if(this.transparent) {
                classname += ' -transparent'
            }

            return classname
        },
    },
    methods: {
        revealHandle(state) {
            this.isVisible = state.isActive
        },
    },
    watch: {
        isVisible(visible) {
            this.$emit('toggle', visible)

            if(visible) {
                this.$emit('show')
            } else {
                this.$emit('hide')
            }
        },
        visible(visible) {
            this.isVisible = visible
        },
    }
};

</script>

<style lang="scss">

.o-box {
    padding: var(--section-margin-half);
    text-align: center;

    &:before {
        @include pseudo-el($bg: $color-primary-light);
        position: absolute;
        top: 0;
        left: 0;
    }

    &.-transparent {

        &:before {
            display: none;
        }

        &.js-reveal > * {
            --a-in-delay: 0;
        }
    }

    &.-columns {
        --box-column-width: 100%;

        padding-top: calc(var(--section-margin-half) - 1.5em);
        padding-bottom: calc(var(--section-margin-half) - 1.5em);

        > * {
            flex: 1 1 var(--box-column-width);
            padding-top: 1.5em;
            padding-bottom: 1.5em;
        }
    }

    &.js-reveal {

        &:before,
        > * {
            @include anim-reveal-hide;
        }

        &:before {
            --a-out-ease: ease-out;
            --a-in-ease: ease-out;
        }

        > * {
            --a-in-delay: .4s;
        }

        &.is-visible {

            &:before,
            > * {
                @include anim-reveal-show;
            }
        }
    }

    @media #{md("sm", "max")} {

        &.-columns {
        }
    }

    @media #{md("xs")} {

        &.-columns {
            width: calc(3/4 * 100%);
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media #{md("sm")} {

        &.-columns {
            --box-column-width: calc(1/3 * 100%);

            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
        }
    }

    @media #{md("md")} {

        &.-columns {
            --box-column-width: calc(1/4 * 100%);
        }
    }
}

</style>
