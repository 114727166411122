<template>
    <div :class="className">
        <box class="c-newsletter__inner">
            <h2
                class="c-newsletter__title | t-t2"
                v-html="$t('misc.newsletterTitle')"
            />

            <mailchimp />
        </box>
    </div>
</template>

<script>

import Box from 'objects/Box';
import AnimText from 'objects/AnimText';
import Btn from 'components/Btn';

import Mailchimp from 'components/Mailchimp'

export default {
    name: 'Newsletter',
    components: {
        Box,
        AnimText,
        Btn,
        Mailchimp,
    },
    props: {
        grid: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        className() {
            let classname = 'c-newsletter'

            if(this.grid) {
                classname += ' grid'
            }

            return classname
        },
    }
};

</script>

<style lang="scss">

.c-newsletter {

    @media #{md("xs", "max")} {
        padding-top: 100%;
    }
}

.c-newsletter__inner {

    &.js-reveal {

        .c-newsletter__title {
            --a-in-delay: .4s;
        }

        .o-mailchimp__form {
            --a-in-delay: .6s;
        }
    }

    @media #{md("xs", "max")} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.c-newsletter__title {
    margin-bottom: 1em;
}

</style>
