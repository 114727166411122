<template>
    <script type="application/ld+json">
        {{ jsonVariants }}
    </script>
</template>

<script>
export default {
    name: 'ProductStructuredDatas',
    props: {
        product: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        priceValidUntil: null
    }),
    created() {
        const priceValidUntil = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        this.priceValidUntil = priceValidUntil
    },
    computed: {
        jsonDescription() {
            const desc = this.product.description || this.product.descriptionHtml
            return desc.replace(/(<([^>]+)>)/gi, "")
        },
        jsonVariants() {

            const product = this.product

            return product.variants.map(variant => {

                const options = variant.selectedOptions.map(option => {

                    const key = option.name.toLowerCase()

                    if ( key != 'title' ) {
                        let obj = {}
                        obj[key] = option.value
                        return obj
                    }

                    return null
                })

                let json = {
                    "@context": "http://schema.org/",
                    "@type": "Product",
                    "inProductGroupWithID": product.id,
                    "sku": variant.sku,
                    "url": product.url,
                    "image": ( product.asset && product.asset[0] ) ? product.asset[0].url: "",
                    "name": product.title,
                    "description": this.jsonDescription,
                    "offers": {
                        "@type": "Offer",
                        "itemCondition": "https://schema.org/NewCondition",
                        "availability": this.getAvailability(variant),
                        "priceCurrency": this.getCurrency(variant),
                        "price": this.getPrice(variant),
                        "priceSpecification": `${this.getPrice(variant)} ${this.getCurrency(variant)}`,
                        "url": product.url,
                        "priceValidUntil": this.priceValidUntil
                    },
                    "brand": {
                        "@type": "Thing",
                        "name": "Silvia Tcherassi"
                    }
                }

                json = { ...json, ...this.flattenObject(options) }
                return json
            })
        }
    },
    methods: {
        getAvailability(variant) {
            if ( (variant.availableForSale && variant.quantityAvailable > 0 && !variant.currentlyNotInStock) )
                return 'http://schema.org/InStock'
            else
                return 'http://schema.org/OutOfStock'
        },
        getCurrency(variant) {
            return variant.priceV2.currencyCode
        },
        getPrice(variant) {
            return parseFloat(variant.priceV2.amount).toFixed(2)
        },
        flattenObject(ob) {
            var toReturn = {};

            for (var i in ob) {
                if (!ob.hasOwnProperty(i)) continue;

                if ((typeof ob[i]) == 'object') {
                    var flatObject = this.flattenObject(ob[i]);
                    for (var x in flatObject) {
                        if (!flatObject.hasOwnProperty(x)) continue;

                        toReturn[x] = flatObject[x];
                    }
                } else {
                    toReturn[i] = ob[i];
                }
            }
            return toReturn;
        }
    }
}
</script>

<style lang="scss">
</style>
