<template>
    <div
        class="o-input-group"
        :class="`-${type}`"
    >
        <div
            v-for="(input, i) in inputs"
            v-if="input && input.id"
            :key="`input-${i}`"
            :class="className(input.disabled)"
            ref="inputWrapper"
        >
            <input
                :id="`input-${_uid}-${i}`"
                class="o-input-check__input"
                :type="type"
                :name="group"
                :value="input.id"
                ref="input"
                :disabled="input.disabled"
                v-model="value"
            />
            <label
                :for="`input-${_uid}-${i}`"
                class="o-input-check__label"
            >
                <span
                    class="o-input-check__text | u-as"
                >
                    {{ input.title }}
                </span>
            </label>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AppInputCheck',
    props: {
        group: {
            type: String,
            default: 'input',
        },
        inputs: {
            type: Array,
            required: true,
        },
        radio: {
            type: Boolean,
            default: false,
        },
        defaultValue: {
            default: null
        }
    },
    data: () => ({
        value: false,
    }),
    created() {
        this.reset()

        if ( this.defaultValue )
            this.value = this.defaultValue
    },
    computed: {
        type() {
            return this.radio ? 'radio' : 'checkbox'
        },
    },
    methods: {
        className(disabled=false) {
            let className = 'o-input-check'
            className += disabled ? ' is-disabled' : ''

            return className
        },
        reset() {

            if(this.radio) {
                this.value = ''
            } else {
                this.value = []

                if(this.$refs.inputWrapper) {

                    this.$refs.inputWrapper.forEach(wrapper => {
                        wrapper.querySelector('input').checked = false
                    })
                }
            }
        },
    },
    watch: {
        value(val) {
            this.$emit('input', {group: this.group, value: val})
        },
    }
};

</script>

<style lang="scss">

.o-input-group {

    &.-checkbox {

        .o-input-check__label {
            padding-right: .5em;
            padding-left: .5em;
            transition: all .1s ease-in;
        }

        .o-input-check__input:checked + .o-input-check__label {
            color: $color-secondary;
            background-color: $color-primary-light;
            transition: all .2s ease-out;
        }
    }

    &.-radio {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-right: -.25em;
        margin-left: -.25em;

        .o-input-check {
            margin-right: .25em;
            margin-left: .25em;
        }

        .o-input-check__text {
            text-transform: uppercase;
            background-color: $color-light;
            border-radius: 50%;
            transition: all .2s ease-out;

            &:after {
                @include pseudo-el($width: 50%, $height: 50%);
                position: absolute;
                top: calc(50%/2);
                left: calc(50%/2);
                border-radius: 50%;
                transform: scale(0);
                transition: transform .1s ease-in;
            }
        }


        .o-input-check__input:checked + .o-input-check__label {

            .o-input-check__text {
                color: $color-light;
                background-color: $color-dark;
            }
        }
    }
}

.o-input-check {
    color: $color-dark;

    &.is-disabled {
        color: $color-grey;
        pointer-events: none;
    }
}

.o-input-check__input {
    display: none;
}

.o-input-check__label {
    display: inline-flex;
    align-items: flex-start;
    padding-top: .5em;
    padding-bottom: .5em;
    user-select: none;
    cursor: pointer;
}

.o-input-check__text {
    font-size: .875em;
}

</style>
