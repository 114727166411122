<template>
    <section
        v-if="builder.length > 0"
        class="b"
    >
        <div class="grid">
            <div
                v-for="(block, i) in builder"
                :key="`block-${i}`"
                class="b__item"
            >
                <component
                    :is="block.typeHandle"
                    :data="block"
                    class="b__block"
                />
            </div>
        </div>
    </section>
</template>

<script>

import BlockLink from 'builder/BlockLink'
import BlockRow from 'builder/BlockRow'
import BlockSlider from 'builder/BlockSlider'
import BlockText from 'builder/BlockText'
import BlockAsset from 'builder/BlockAsset'
import BlockVideo from 'builder/BlockVideo'
import BlockProduct from 'builder/BlockProduct'

export default {
    name: 'Builder',
    components: {
        BlockLink,
        BlockSlider,
        BlockRow,
        BlockText,
        BlockAsset,
        BlockVideo,
        BlockProduct,
    },
    props: {
        builder: {
            type: Array,
            default: () => [],
        },
    },
};

</script>

<style lang="scss">

.b {
    margin-top: var(--section-margin);
    margin-bottom: var(--section-margin);
}

.b__item {

    &:not(:first-child) {
        margin-top: var(--section-margin);
    }
}

.b__block {

    &.b-text,
    &.b-asset,
    &.b-video,
    &.b-product {
        --width: 100%;
        --align-self: center;
        --margin-right: auto;
        --margin-left: auto;

        width: var(--width);
        align-self: var(--align-self);
        margin-right: var(--margin-right);
        margin-left: var(--margin-left);

        &.-boxed {
            padding: calc(1/6 * 100%);
            background-color: $color-primary-light;
        }
    }
}

</style>
