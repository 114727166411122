<template>
    <ul
        v-if="items.length > 0"
        :class="className"
    >
        <li
            v-for="(item, i) in items"
            :key="`list-item-${i}`"
            class="c-list__item"
            ref="item"
            @mouseenter="mouseenter(i)"
            @mousemove="mousemove"
            v-reveal
        >
            <div class="c-list__col -left">
                <slot
                    v-bind="item"
                    name="left"
                />
            </div>
            <div
                v-if="hasAsset && item.asset.length > 0"
                class="c-list__asset"
                :ref="`list-item-asset-${i}`"
            >
                <asset
                    v-bind="item.asset[0]"
                    :reveal="false"
                />
            </div>
            <div class="c-list__col -right">
                <slot
                    v-bind="item"
                    name="right"
                />
            </div>
        </li>
    </ul>
</template>

<script>

import Asset from 'objects/Asset'

import { round } from 'src/utils'
import gsap from 'gsap'

export default {
    name: 'List',
    components: {
        Asset,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        modifier: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        activeItem: {},
    }),
    computed: {
        hasHover() {
            return window.matchMedia('(hover: hover)').matches
        },
        hasAsset() {
            return typeof this.items[0].asset !== 'undefined'
        },
        className() {
            let classname = 'c-list'

            if(this.modifier) {
                classname += ` -${this.modifier}`
            }

            if(this.hasAsset) {
                classname += ' -asset'
            }

            return classname
        },
    },
    methods: {
        mouseenter(i) {
            if(!this.hasHover) {
                return
            }

            const $el = this.$refs.item[i]
            const rect = $el.getBoundingClientRect()

            this.activeItem = {
                $asset: this.$refs[`list-item-asset-${i}`],
                rect
            }
        },
        mousemove(e) {
            if(!this.hasHover) {
                return
            }

            let x = (e.clientX - this.activeItem.rect.x) / this.activeItem.rect.width
            let y = (e.clientY - this.activeItem.rect.y) / this.activeItem.rect.height

            x = round((x - .5) * this.activeItem.rect.width/4)
            y = round((y - .5) * this.activeItem.rect.height/2)

            gsap.to(this.activeItem.$asset, {
                duration: .4,
                x,
                y,
            })
        },
    },
};

</script>

<style lang="scss">

.c-list {
    @include reset-list;

    &.-events, &.-blog {

        @media #{md("md")} {
            .c-list__col.-left {
                padding-right: 6em;
            }
        }
    }

    &.-contact {

        .c-list__col.-right {
            display: grid;
            grid-gap: var(--grid-gutter);
            text-align: center;

            .c-btn {
                margin-top: 1em;
            }
        }
    }

    @media #{md("sm", "max")} {

        &.-contact {
            text-align: center;

            .c-list__item {
                padding-top: 2em;
                padding-bottom: 2em;
            }

            .c-list__col.-left {
                margin-bottom: 1em;
            }
        }
    }

    @media #{md("xs")} {

        &.-contact {

            .c-list__col.-right {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    @media #{md("sm")} {

        &.-terms,
        &.-contact {

            .c-list__item {
                grid-template-columns: 1fr 2fr;
                grid-gap: var(--grid-gutter);
                align-items: flex-start;
           }
        }

        &.-contact {

            .c-list__item {
                padding-top: var(--section-margin-half);
                padding-bottom: var(--section-margin-half);
            }
        }
    }

    @media (hover: none)  {

        &.-asset {

            .c-list__item {
                grid-template-columns: 1fr;
                padding-right: 40%;

                .c-btn {
                    padding-left: 0;
                }
            }
        }
    }
}

.c-list__item {
    display: grid;
    grid-gap: var(--grid-gutter-half);
    grid-template-columns: 1fr;
    align-items: flex-end;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: $border-secondary-light;
    @include anim-reveal-default;

    .c-btn {
        white-space: nowrap;
    }

    @media (hover: hover) {

        &:hover {

            .o-asset__img {
                opacity: 1;
                transform: scale(1);
                transition: all .4s $out-quad;
            }
        }
    }

    @media #{md("sm", "max")} {

        .c-btn:only-child {
            padding-left: 0;
        }
    }

    @media #{md("sm")} {
        grid-template-columns: 3fr 1fr;
        padding-top: 2em;
        padding-bottom: 2em;

        .c-btn:only-child {
            padding-right: 0;
        }
    }
}

.c-list__asset {

    @media (hover: hover) {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30%;
        height: 200%;
        pointer-events: none;
        mix-blend-mode: darken;

        .o-asset {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            overflow: hidden;
        }

        .o-asset__img {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
            opacity: 0;
            transform: scale(1.2);
            transition: all .1s $in-quad;
        }
    }

    @media (hover: none)  {
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        height: 100%;

        .o-asset {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
        }

        .o-asset__img {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }
}

// .c-list__col {

//     @media (hover: hover) {
//         z-index: 2;
//     }
// }

</style>
