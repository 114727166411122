<template>
    <div>
        <app-section
            :title="page.mainTitle"
            :breadcrumb="true"
        >
            <box
                :columns="true"
                class="p-store__box"
            >
                <div v-if="page.openingTimes.length > 0">
                    <icon icon="clock" />
                    <h3 class="p-store__title | t-t4">{{ $t('misc.openingHours') }}</h3>
                    <ul class="o-opening-times">
                        <li
                            v-for="(item, i) in page.openingTimes"
                            :key="`opening-time-${i}`"
                        >
                            <span>{{ item.days }}</span>
                            <span>{{ item.time }}</span>
                        </li>
                    </ul>
                </div>
                <div v-if="page.address">
                    <icon icon="map-picker" />
                    <h3 class="p-store__title | t-t4">{{ $t('misc.location') }}</h3>
                    <app-address
                        :address="page.address"
                        :directions="true"
                    />
                </div>
                <div v-if="page.email">
                    <icon icon="mail" />
                    <h3 class="p-store__title | t-t4">{{ $t('misc.emailUs') }}</h3>
                    <btn
                        tag="a"
                        :href="`mailto:${page.email}`"
                        title="Email"
                        target="_blank"
                        rel="noopener"
                        :label="`${page.email}`"
                    />
                </div>
                <div v-if="page.phone">
                    <icon icon="phone" />
                    <h3 class="p-store__title | t-t4">{{ $t('misc.callUs') }}</h3>
                    <btn
                        tag="a"
                        :href="page.phone|phoneHref"
                        title="Phone"
                        target="_blank"
                        rel="noopener"
                        :label="`Tel ${page.phone}`"
                    />
                </div>
            </box>
        </app-section>
        <builder :builder="page.builder" />
        <app-section
            v-if="general && general.isActive && (general.headline || general.table.length > 0)"
            :title="general.headline"
            titleTag="h2"
            titleClass="t-t4"
            class="p-store__general"
        >
            <div
                v-if="general.table.length > 0"
                class="o-table"
            >
                <div
                    v-for="(col, i) in general.table"
                    :key="`general-col-${i}`"
                    class="o-table__col"
                >
                    <anim-text
                        tag="h4"
                        :text="col.label"
                        class="o-table__label | t-t4"
                    />
                    <ul
                        v-if="col.list.length > 0"
                        class="o-table__list"
                    >
                        <li
                            v-for="(item, j) in col.list"
                            :key="`general-col-${i}-item-${j}`"
                            v-reveal
                        >
                            {{ item.label }}
                        </li>
                    </ul>
                </div>
            </div>
        </app-section>
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Builder from 'builder/Builder';
import Box from 'objects/Box';
import Icon from 'objects/Icon';
import AnimText from 'objects/AnimText';
import AppAddress from 'objects/AppAddress';
import Btn from 'components/Btn';

export default {
    name: 'Store',
    components: {
        AppSection,
        Builder,
        Box,
        Icon,
        AnimText,
        AppAddress,
        Btn,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    data: () => ({
        general: null,
    }),
    created() {

        // Load info
        this.$store.dispatch('globalsets/loadSet', 'storeSet')
            .then(general => {
                this.general = general
            })
            .catch(e => {
                console.error('Error: Store', e)  // eslint-disable-line
            })
    }
};

</script>

<style lang="scss">

.p-store {}

.o-table,

.p-store__title {
    margin-bottom: .5em;
}

.p-store__general {

    .l-section__content:not(:first-child) {
        margin-top: var(--section-margin-half);
    }

    @media #{md("lg")} {

        .l-section__content {
            width: calc(5/6 * 100%);
            margin-right: auto;
            margin-left: auto;
        }
    }
}

/*----------  Opening Times list  ----------*/

.o-opening-times {
    @include reset-list;

    li {
        display: flex;
        flex-direction: column;
    }
}


/*----------  Table  ----------*/

.o-table {
    text-align: center;
}

.o-table__col {}

.o-table__label {
    margin-bottom: 1.5rem;
}

.o-table__list {
    @include reset-list;

    li {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-top: $border-secondary-light;
        @include anim-reveal-default;
    }
}


</style>
