<template>
    <transition name="anim-modal">
        <div
            v-if="modal && isVisible"
            :class="className"
        >
            <button
                class="l-modal__close"
                @click="close()"
                :aria-label="$t('aria.close')"
            >
                <icon icon="cross" />
            </button>
            <div
                class="l-modal__inner"
                :class="isEntry && modal.asset.length > 0 ? '-grid' : 't-content'"
            >
                <template v-if="isNewsletter">
                    <h3
                        v-if="modal.mainTitle"
                        class="l-modal__title | t-t3"
                        v-html="modal.mainTitle"
                    >
                    </h3>
                    <div
                        v-if="modal.description"
                        class="t-cms"
                        v-html="modal.description"
                    ></div>
                    <mailchimp />
                </template>
                <template v-else-if="isEntry">
                    <span
                        v-if="modal.mainTitle"
                        class="l-modal__sup | t-caps"
                        v-html="modal.mainTitle"
                    >
                    </span>
                    <h3
                        v-if="modal.headline"
                        class="l-modal__title | t-t3"
                        v-html="modal.headline"
                    >
                    </h3>
                    <asset
                        v-if="modal.asset.length > 0"
                        v-bind="modal.asset[0]"
                        :reveal="false"
                        class="l-modal__asset"
                    />
                    <btn
                        v-if="link"
                        :label="link.label"
                        :href="link.href"
                        tag="router-link"
                        bg="none"
                        :uppercase="true"
                        :reveal="false"
                        iconAfter="arrow-long-right"
                        class="l-modal__btn"
                        @click.native="close()"
                    />
                </template>
            </div>
        </div>
    </transition>
</template>

<script>

import Icon from 'objects/Icon';
import Asset from 'objects/Asset';
import Btn from 'components/Btn';
import Mailchimp from 'components/Mailchimp';

export default {
    name: 'AppModal',
    components: {
        Icon,
        Asset,
        Btn,
        Mailchimp,
    },
    data: () => ({
        isVisible: true
    }),
    computed: {
        className() {
            let classname = 'l-modal'

            if(this.isNewsletter) {
                classname += ' -newsletter'
            } else if(this.isEntry) {
                classname += ' -entry'
            }

            return classname
        },
        modal() {
            return this.$store.getters['globalsets/getSetByHandle']('modal')
        },
        isNewsletter() {
            return this.modal && this.modal.typeHandle === 'newsletter'
        },
        isEntry() {
            return this.modal && this.modal.typeHandle === 'entry'
        },
        link() {
            let entry = this.modal.selectedEntry

            if(!this.isEntry || entry.length === 0) {
                return false
            }

            entry = entry[0]

            return {
                label: this.modal.label ? this.modal.label : entry.title,
                href: `/${entry.uri}`
            }
        }
    },
    methods: {
        close() {
            this.isVisible = false
        },
    },
};

</script>

<style lang="scss">

.l-modal {
    z-index: 30;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    &.-entry {
    }

    &.-newsletter {

    }
}

.l-modal__close {
    z-index: 1;
    position: absolute;
    top: -$accessible-size/2;
    left: calc(50% - #{$accessible-size}/2);
    display: flex;
    width: $accessible-size;
    height: $accessible-size;
    align-items: center;
    justify-content: center;
    color: $color-light;
    background-color: $color-dark;
    border-radius: 50%;
    cursor: pointer;
}

.l-modal__inner {
    width: 100%;
    margin-top: 4em;
    padding: 1em var(--grid-gutter) 2.5em;
    text-align: center;
    background-color: $color-primary-light;
    clip-path: circle(100% at 50% 100%);

    &.-grid {
        display: grid;
        grid-template-areas:
            " sup   asset "
            " title asset "
            " btn   asset ";
        grid-template-columns: 2fr 1fr;
        grid-gap: var(--grid-gutter);
        align-items: center;
        text-align: left;

        .l-modal__sup {
            grid-area: sup;
        }

        .l-modal__title {
            grid-area: title;
        }

        .l-modal__btn {
            grid-area: btn;
            padding-left: 0;
        }

        .l-modal__asset {
            grid-area: asset;
        }
    }

    &:before {
        @include pseudo-el($height: 8em, $bg: inherit);
        position: absolute;
        top: -4em;
        left: 0;
        clip-path: ellipse(52% 50% at 50% 50%);
        transform: rotate(-1.5deg);
    }

    @media #{md("xs")} {
        width: calc(5/6 * 100%);
        margin-right: auto;
        margin-left: auto;
        padding-right: calc(1/8 * 100%);
        padding-left: calc(1/8 * 100%);
    }

    @media #{md("sm")} {
        width: calc(3/4 * 100%);
    }

    @media #{md("md")} {
        width: calc(2/3 * 100%);
        max-width: calc(2/3 * #{$grid-max-width});
    }
}


/*----------  Animation in/out  ----------*/


.anim-modal-enter-active {
    transition: opacity .4s ease-out, transform .8s $out-expo;
}


.anim-modal-leave-active {
    transition: opacity .4s ease-in .2s, transform .6s $out-expo;
}

.anim-modal-enter, .anim-modal-leave-to {
    opacity: 0;
    transform: translate(0, 100%);
}

</style>
