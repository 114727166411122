<template>
    <app-not-found />
</template>

<script>

import AppNotFound from 'layout/AppNotFound';

export default {
    name: 'Error404',
    components: {
        AppNotFound
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
};

</script>

<style lang="scss">
</style>
