import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from 'src/store'

import translations from 'src/translations'

// Define locales
export const locales = Object.keys(translations)

// Set current local
export const currentLocal = store.state.global.site.locale

// Set default local
export const defaultLocal = locales[0]

const dateTimeFormats = {
  'en': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  },
  'es': {
    short: {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }
  }
}

// Use plugin
Vue.use(VueI18n)

// Instanciate plugin
export const i18n = new VueI18n({
    locale: currentLocal || defaultLocal,
    fallbackLocale: currentLocal || defaultLocal,
    messages: translations,
    dateTimeFormats
})
