<template>
    <div>
        <app-section
            :title="page.mainTitle"
            :cover="cover"
            :breadcrumb="true"
            :headerCenter="false"
            class="p-atelier__heading"
        >
            <anim-text
                :cms="true"
                :text="page.body"
                class="l-section__desc"
            />
        </app-section>

        <app-section
            tag="div"
            :title="page.headline"
            titleTag="h2"
            titleClass="t-t2"
        >
            <box
                :columns="true"
                class="p-atelier__box"
            >
                <div
                    v-if="page.email"
                >
                    <icon icon="mail" />
                    <h3 class="t-t4">{{ $t('misc.emailUs') }}</h3>
                    <btn
                        tag="a"
                        :href="`mailto:${page.email}`"
                        title="Email"
                        target="_blank"
                        rel="noopener"
                        :label="page.email"
                    />
                </div>
                <div
                    v-if="page.phone"
                >
                    <icon icon="phone" />
                    <h3 class="t-t4">{{ $t('misc.callUs') }}</h3>
                    <btn
                        tag="a"
                        :href="page.phone|phoneHref"
                        title="Phone"
                        target="_blank"
                        rel="noopener"
                        :label="`Tel ${page.phone}`"
                    />
                </div>
                <div v-if="address">
                    <icon icon="map-picker" />
                    <h3 class="t-t5">Atelier</h3>
                    <app-address :address="address" />
                </div>
            </box>
        </app-section>
        <builder :builder="page.builder" />
        <push :push="page.push" />
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Builder from 'builder/Builder';
import Box from 'objects/Box';
import Icon from 'objects/Icon';
import AppAddress from 'objects/AppAddress';
import AnimText from 'objects/AnimText';
import Btn from 'components/Btn';
import Push from 'components/Push';

export default {
    name: 'Atelier',
    components: {
        AppSection,
        Builder,
        Box,
        Icon,
        AppAddress,
        AnimText,
        Btn,
        Push,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    computed: {
        cover() {
            const cover = this.page.asset
            if(cover.length === 0) {
                return null
            } else {
                return cover[0]
            }
        },
        address() {
            return this.page.address.length > 0 ? this.page.address[0] : false
        }
    }
};

</script>

<style lang="scss">

.p-atelier {}

.p-atelier__heading {

    .l-section__inner {
        display: grid;
        grid-template-areas:
            " header "
            " cover "
            " content ";
    }

    .l-section__cover {
        --width: 100%;
        grid-area: cover;

        @media #{md("sm", "max")} {
            margin-top: 2rem;
        }
    }

    .l-section__header {
        grid-area: header;
    }

    .l-section__content {
        grid-area: content;
    }

    @media #{md("sm")} {

        .l-section__inner {
            grid-template-areas:
                " header cover "
                " content cover ";
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto 100%;
            grid-column-gap: calc(1/12 * 100%);
        }

        .l-section__cover {
            margin-top: calc(-.5 * var(--section-margin-half));
        }

        .l-section__desc {
            margin-top: 1em;
        }
    }
}

.p-atelier__box {
    margin-top: var(--section-margin-half);

    a {
        display: inline-flex;
        margin-top: 1em;
    }

    @media #{md("sm")} {
        grid-gap: var(--grid-gutter);
        grid-template-columns: repeat(2, 1fr);
        width: 100%;

        div {
            padding-top: 4em;
            padding-bottom: 4em;

            &:nth-child(3n),
            &:nth-child(3n+2) {

                &:before {
                    @include pseudo-el($width: 1px, $bg: rgba($color-secondary, .3));
                    position: absolute;
                    top: 0;
                    left: calc(-.5 * var(--grid-gutter));
                }
            }

            // &:nth-child(3n) {
            //     grid-column: 1/3;
            //     padding-top: 0;
            //     padding-bottom: 0;
            // }
        }
    }
}

</style>
