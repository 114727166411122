<template>
    <div>
        <app-section
            :title="page.mainTitle"
            class="p-home__heading"
        >
            <template #after-title>
                <div
                    v-if="mainCta && mainCta.entry && mainCta.entry.uri"
                    class="p-home__shop"
                >
                    <btn
                        tag="router-link"
                        :href="`/${mainCta.entry.uri}`"
                        :title="mainCta.entry.title"
                        :label="mainCta.label"
                        bg="primary-light"
                    />
                </div>
            </template>
            <template #before-content>
                <div
                    v-if="page.desktopVideo"
                    class="p-home__video"
                >
                    <vimeo
                        :id="page.desktopVideo"
                        :cover="true"
                        class="-desktop"
                        :class="{ 'u-dp-none@sm-max' : page.mobileVideo }"
                    />
                    <vimeo
                        v-if="page.mobileVideo"
                        :id="page.mobileVideo"
                        :cover="true"
                        class="-mobile | u-dp-none@sm"
                    />
                </div>
                <template v-else>
                    <asset
                        v-if="coverMobile"
                        v-bind="coverMobile"
                        class="l-section__cover -mobile | u-dp-none@sm"
                    />
                    <asset
                        v-if="cover"
                        v-bind="cover"
                        class="l-section__cover -desktop"
                        :class="{ 'u-dp-none@sm-max' : coverMobile }"
                    />
                </template>
            </template>
            <blocks
                :blocks="page.blocks"
                class="p-home__blocks"
            />
        </app-section>
        <app-section
            v-if="featuredEntry"
            :title="featuredEntry.mainTitle"
            :headerCenter="false"
            class="p-home__featured"
        >
            <btn
                tag="router-link"
                :href="`/${featuredEntry.uri}`"
                :label="$t('btn.shopNow')"
                class="-cta"
                bg="primary-light"
            />
            <product-list
                v-if="featuredProducts"
                :products="featuredProducts"
                :slider="false"
                :big="true"
            >
                <!-- <template #before-loop>
                    <div
                        class="c-product-list__preview"
                    >
                        <vimeo
                            v-if="featuredProducts[0].video"
                            :id="featuredProducts[0].video"
                            :cover="true"
                        />
                    </div>
                </template> -->
            </product-list>
        </app-section>
        <app-section
            v-if="posts.length > 0"
            :title="page.blog.mainTitle"
            :headerCenter="false"
            class="p-home__blog"
        >
            <btn
                tag="router-link"
                :href="'blog'|resolve"
                :label="$t('btn.readPosts')"
                bg="primary-light"
                class="-cta"
            />

            <slider
                ref="postsSlider"
                modifier="-alternate"
            >
                <router-link
                    v-for="(post, i) in posts"
                    :key="`posts-slide-${i}`"
                    :to="`/${post.uri}`"
                    :title="post.title"
                    class="o-slide"
                >
                    <asset
                        v-if="post.asset.length > 0"
                        v-bind="post.asset[0]"
                        @load="$refs.postsSlider.resize()"
                    />
                    <article class="o-slide__content | t-content">
                        <time
                            class="t-small"
                            :datetime="post.postDate|datetime"
                        >
                            {{ post.postDate|date }}
                        </time>
                        <h1
                            class="t-t4"
                            v-html="post.mainTitle"
                        ></h1>
                    </article>
                </router-link>
            </slider>

        </app-section>
        <app-section>
            <div
                class="p-home__bottom"
                :class="{'-has-events': events.length}"
            >
                <div v-if="events.length" class="p-home__events">
                    <anim-text
                        tag="h2"
                        :text="$t('misc.nextEvents')"
                        class="t-t2"
                    />
                    <list
                        :items="events"
                        modifier="events"
                    >
                        <template #left="item">
                            <router-link :to="`/${item.uri}`">
                            <time
                                class="t-small"
                                :datetime="item.date|datetime"
                            >
                                {{ item.date|date }}
                            </time>
                            <h2 class="t-t4">
                                {{ item.title }}
                            </h2>
                            </router-link>
                        </template>
                        <template #right="item">
                            <btn
                                tag="router-link"
                                :href="`/${item.uri}`"
                                :title="$t('btn.attendEvent')"
                                :label="$t('btn.attendEvent')"
                                iconAfter="arrow-long-right"
                                bg="none"
                            />
                        </template>
                    </list>
                </div>
                <newsletter />
            </div>
        </app-section>
        <instagram />
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Asset from 'objects/Asset';
import Vimeo from 'objects/Vimeo';
import AnimText from 'objects/AnimText';
import Btn from 'components/Btn';
import List from 'components/List';
import Blocks from 'components/Blocks';
import ProductList from 'components/ProductList';
import Slider from 'components/Slider';
import Instagram from 'components/Instagram';
import Newsletter from 'components/Newsletter';

export default {
    name: 'Home',
    components: {
        AppSection,
        Asset,
        Vimeo,
        AnimText,
        Btn,
        List,
        Blocks,
        ProductList,
        Slider,
        Instagram,
        Newsletter,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    data: () => ({
        posts: [],
        events: [],
        mainCta: false,
        featuredEntry: false,
        featuredProducts: false,
    }),
    created() {

        // Header mainCta
        const mainCta = this.page.mainCta && this.page.mainCta[0] ? this.page.mainCta[0] : null

        this.mainCta = {
            entry: mainCta && mainCta.entry && mainCta.entry[0] ? mainCta.entry[0] : null,
            label: mainCta.btnLabel || this.$t('btn.shopNow')
        }

        // Load post list
        this.$store.dispatch('pages/loadList', 'posts')
            .then(posts => {
                // Get first 6 posts
                this.posts = posts.slice(0, 6)
            })
            .catch(e => {
                console.error('Error: Home/loadList: posts', e)  // eslint-disable-line
            })

        // Load events
        this.$store.dispatch('pages/loadList', 'events')
            .then(events => {
                // Get first 3 events
                this.events = events.slice(0, 3)
            })
            .catch(e => {
                console.error('Error: Home/loadList: events', e)  // eslint-disable-line
            })


        // Load Featured Entry
        let featuredEntry = this.page.featuredEntry
        if(featuredEntry.length > 0) {
            featuredEntry = featuredEntry[0]
            this.$store.dispatch('pages/loadPartial', { id: featuredEntry.id })
                .then(entryData => {
                    this.featuredEntry = entryData
                })
                .catch(e => {
                    console.error('Error: Home/Featured Entry', e)  // eslint-disable-line
                })
        }

        // Load Featured Products
        let handles, titles, query
        let featuredProducts = this.page.featuredProducts

        if(featuredProducts.length > 0) {

            handles = featuredProducts.map(product => product.storefrontId)
            titles = featuredProducts.map(product => product.title)
            query = `(title:"${titles.join('") OR (title:"')}")`

            this.$store.dispatch('products/searchProducts', {query, keySort: handles})
            .then(products => {
                // Commit/Limit to three products
                this.featuredProducts = products.slice(0,3)
            })
            .catch(e => {
                console.error('Error: Home', e)  // eslint-disable-line
            })
        }
    },
    computed: {
        cover() {

            if ( !this.page || !this.page.asset )
                return

            return this.getAsset(this.page.asset)
        },
        coverMobile() {

            if ( !this.page || !this.page.coverMobile )
                return

            return this.getAsset(this.page.coverMobile)
        },
    },
    methods: {
        getAsset(asset) {

            if( !asset || asset.length === 0) {
                return false
            } else {
                return asset[0]
            }
        }
    }
};

</script>

<style lang="scss">

.p-home__heading {

    .l-section__cover {
        --width: 100%;
    }

    .l-section__header {
        width: calc(5/6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("xs")} {

        .l-section__header {
            width: calc(3/4 * 100%);
        }
    }

    @media #{md("sm")} {

        .l-section__header {
            width: calc(5/6 * 100%);
        }
    }
}

.p-home__shop {
    position: absolute;
    top: calc(76vh - #{$header-height} - var(--section-margin-half));
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 2;

    @media #{md("sm")} {
        top: auto;
        bottom: -4em;
    }
    @media #{md("md")} {
        bottom: -4em;
    }
    @media #{md("lg")} {
        bottom: -4em;
    }
}

.p-home__video {
    height: 0;
    padding-top: vh(70);

    @media #{md("sm")} {
        padding-top: calc(9/16 * 100%);
    }
}

.p-home__blocks {
    margin-top: calc(-1 * var(--offset-2n));
}

.p-home__blog,
.p-home__featured {

    .c-btn.-cta {
        z-index: 1;
        margin-top: 1.75em;
        margin-bottom: -.5em;
    }
}

.p-home__featured {

    @media #{md("xs", "max")} {
        .c-product-list__preview {
            display: none;

            .c-product-list.-big.-no-slider .c-product-list__grid > *:nth-child(6n+2) {
                grid-column: 0/6;
            }
            .c-product-list.-big.-no-slider .c-product-list__grid > *:nth-child(6n+3) {
                grid-column: 6/9;
            }
        }
    }
    @media #{md("xs")} {
        .c-product-list.-big.-no-slider .c-product-list__grid > *:nth-child(6n+1) {
            // grid-column: 1/3;
            // width: 100%;
        }

        .c-product-list.-big.-no-slider .c-product-list__grid > *:nth-child(6n+2) {
            // grid-column: 3/6;
        }

        .c-product-list.-big.-no-slider .c-product-list__grid > *:nth-child(6n+3) {
            // grid-column: 6/9;
        }
    }
}

.p-home__bottom {

    @media #{md("sm", "max")} {
        display: flex;
        flex-direction: column-reverse;

        .c-newsletter {
            margin-bottom: var(--section-margin-half);
        }
    }

    @media #{md("sm")} {

        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: var(--grid-gutter);
        grid-template-areas: "newsletter";

        &.-has-events {
            grid-template-columns: 5fr 7fr;
            grid-template-areas: "newsletter events";

            .c-newsletter {
                padding-top: 100%;
            }
        }

        .c-newsletter {
            grid-area: newsletter;
            display: flex;
            align-items: flex-end;
            height: 0;
            padding-top: 25%;
        }

        .c-newsletter__inner {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding-right: 1em;
            padding-left: 1em;
        }
        // .c-newsletter__form {
        //     flex-wrap: nowrap;
        // }
    }
}

.p-home__events {

    .c-list {
        margin-top: 2em;
    }

    @media #{md("sm")} {
        grid-area: events;
    }
}

</style>
