<template>
    <notification
        v-if="isDisplayed"
        @close="close"
        class="c-country-detection"
    >
        <template #left>
            <div class="t-cms">
                <p>{{ $t('detection.message', {currentSiteCode, currency, userCountryName}) }}</p>
            </div>
        </template>
        <template #right>
            <div class="u-dp-flex u-jc-end">
                <span
                    v-for="site in filteredSites"
                    :key="`button-site-${site.id}`"
                >
                    <btn
                        tag="a"
                        :href="`${currentSite.localized[site.handle] || site.baseUrl}`"
                        v-if="site.handle != currentSite.handle"
                        :reveal="false"
                        :label="site.description"
                    />
                </span>
            </div>
        </template>
    </notification>
</template>

<script>

import Notification from 'components/Notification';
import Btn from 'components/Btn';

import { mapGetters } from 'vuex'

export default {
    name: 'CountryDetection',
    components: {
        Btn,
        Notification
    },
    data: () => ({
        match: {
            'US': 'NA',
            'CO': 'SA',
            'EU': 'EU'
        }
    }),
    computed: {
        ...mapGetters({
            user: 'global/getUser',
            sites: 'global/getSites',
            currentSite: 'global/getCurrentSite',
            shopifySettings: 'shop/getSettings'
        }),
        filteredSites() {

            let sites = [...this.sites],
                idxCO = sites.findIndex(site => site.handle == 'es_CO'),
                idxEU = sites.findIndex(site => site.handle == 'en_EU'),
                idxUS = sites.findIndex(site => site.handle == 'en_US')

            if (this.userCountry.code == 'CO') {
                sites = [sites[idxCO]]
            } else if (this.userContinent.code == 'EU') {
                sites = [sites[idxEU]]
            } else {
                sites = [sites[idxUS]]
            }

            return sites
        },
        isDisplayed() {

            // Wait for currency
            if ( !this.currency )
                return

            const userContinent = this.userContinent.code
            const userCountry = this.userCountry.code

            let status = this.match[this.currentSiteCode] == userContinent

            // Cavemen fallback for South America
            if (this.currentSiteCode == 'US' && userContinent == 'SA' && userCountry != 'CO')
                status = true

            this.$emit('update', !status)
            return !status

        },
        currency() {

            if ( !this.shopifySettings || !this.shopifySettings.paymentSettings )
                return

            return this.shopifySettings.paymentSettings.currencyCode
        },
        userCountryName() {
            let country = this.user.country.name
            const exceptions = ['United States', 'United Kingdom']

            if ( exceptions.includes(country) )
                country = this.$t('detection.the', {country})

            return country
        },
        userCountry() {
            return this.user.country
        },
        userContinent() {
            return this.user.continent
        },
        currentSiteCode() {
            const site = this.currentSite.handle
            return site.slice(site.length - 2)
        },
        currentSiteName() {
            return this.currentSite.name
        }
    },
    methods: {
        close() {
            this.$emit('update', false)
        }
    }
};

</script>

<style lang="scss">

.c-country-detection {
    .c-btn {
        margin-left: 0.5em;
    }
}
</style>
