<template>
    <div :class="className">

        <component
            :is="component"
            :modifier="slider ? '-full-slide' : false"
            class="c-product-list__grid"
        >
            <slot name="before-loop" />
            <template v-for="(product, i) in products">
                <div
                    v-if="hasVideos && big && (i % 4 === 2)"
                    :key="`product-${product.slug}-video-before`"
                    class="c-product-list__preview"
                >
                    <vimeo
                        :id="getVideoId()"
                        :cover="true"
                    />
                </div>
                <product-card
                    v-bind="product.card"
                    :key="`product-${product.slug}-card`"
                    :big="big"
                />
                <div
                    v-if="hasVideos && big && (i % 4 === 1)"
                    :key="`product-${product.slug}-video-after`"
                    class="c-product-list__preview"
                >
                    <vimeo
                        :id="getVideoId()"
                        :cover="true"
                    />
                </div>
            </template>
        </component>
    </div>
</template>

<script>

import Asset from 'objects/Asset';
import Vimeo from 'objects/Vimeo';
import Slider from 'components/Slider';
import ProductCard from 'components/ProductCard';

export default {
    name: 'ProductList',
    components: {
        Asset,
        Vimeo,
        Slider,
        ProductCard,
    },
    props: {
        products: {
            type: Array,
            default: null,
        },
        big: {
            type: Boolean,
            default: false,
        },
        slider: {
            type: Boolean,
            default: true,
        },
        videos: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        component: 'div',
        videoIndex: 0,
    }),
    created() {

        if(this.slider) {
            this.component = Slider
        }
    },
    computed: {
        className() {
            let classname = 'c-product-list'

            classname += this.slider ? ' -slider' : ' -no-slider'

            if(this.big) {
                classname += ` -big`
            }

            if(this.hasVideos) {
                classname += ` has-videos`
            }

            return classname
        },
        totalVideos() {
            return this.videos ? this.videos.length : 0
        },
        hasVideos() {
            return this.totalVideos > 0
        },
    },
    methods: {
        getVideoId() {
            const videoItem = this.videos[this.videoIndex].video

            this.videoIndex = this.videoIndex < this.totalVideos - 1 ? this.videoIndex + 1 : 0

            return typeof videoItem !== 'undefined' ? videoItem : null
        }
    },
    watch: {
        big() {
            window.dispatchEvent(new Event('resize'));
        }
    }
};

</script>

<style lang="scss">

.c-product-list {
    --grid-columns: repeat(2, minmax(0, 1fr));

    &.-big {
        --grid-columns: 1fr;
    }

    &.-no-slider {

        .c-product-list__grid {
            display: grid;
            grid-gap: var(--grid-gutter-half);
            grid-template-columns: var(--grid-columns);
            overflow-anchor: none;

            .c-product-card__inner {
                height: 100%;
            }
        }
    }

    @media #{md("xs")} {

        &.-big {

            .c-product-card {
                width: 70%;
                margin-right: auto;
                margin-left: auto;
                // padding-right: 10%;
                // padding-left: 10%;
            }
        }
    }

    @media #{md("sm")} {
        --grid-columns: repeat(3, minmax(0, 1fr));

        &.-slider {

            // Disable slider
            .c-product-list__grid > .c-slider__flkty {
                display: grid;
                grid-gap: var(--grid-gutter-half);
                grid-template-columns: var(--grid-columns);

                &:after {
                    content: '';
                }

                & + .c-slider__nav {
                    display: none;
                }

                .c-product-card__inner {
                    height: 100%;
                }
            }
        }

        // 3 - 3 - 2
        &.-big {
            --grid-columns: repeat(3, minmax(0, 1fr));

            &.has-videos {
                --grid-columns: repeat(8, minmax(0, 1fr));

                &.-no-slider .c-product-list__grid > *,
                &.-slider .c-product-list__grid > .c-slider__flkty > * {

                    &:nth-child(6n+1) {
                        grid-column: 1 / 4;
                    }

                    &:nth-child(6n+2) {
                        grid-column: 4 / 7;
                    }

                    &:nth-child(6n+3) {
                        grid-column: 7 / 9;
                    }

                    &:nth-child(6n+4) {
                        grid-column: 1 / 3;
                    }

                    &:nth-child(6n+5) {
                        grid-column: 3 / 6;
                    }

                    &:nth-child(6n) {
                        grid-column: 6 / 9;
                    }
                }
            }

            .c-product-card {
                width: 100%;
            }
        }
    }

    @media #{md("md")} {

        &:not(.-big) {
            --grid-columns: repeat(4, minmax(0, 1fr));
        }
    }
}

.c-product-list__preview {
    display: none;

    @media #{md("sm", "max")} {
        width: 100vw;

        &:nth-child(6n+3) {
            display: none;
        }
    }

    @media #{md("xs")} {
        display: block;
        width: calc(100% + var(--grid-gutter-half));

        &:nth-child(1),
        &:nth-child(12n+4) {
            left: calc(-1 * var(--grid-gutter-half));
        }
    }

    @media #{md("sm")} {
        width: calc(100% + var(--grid-gutter-half));

        &:nth-child(1),
        &:nth-child(12n+4) {
            left: 0;
        }

        &:nth-child(6n+4) {
            left: calc(-1 * var(--grid-gutter-half));
        }
    }
}

</style>
