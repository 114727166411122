<template>
    <app-section
        v-if="list.length > 0"
    >
        <div class="grid__row">
            <div class="grid__col--12 grid__col--6@sm">
                <anim-text
                    v-if="title"
                    tag="h2"
                    :text="title"
                    class="t-t2"
                />
            </div>
            <div class="grid__col--12 grid__col--6@sm">
                <list
                    :items="list"
                    modifier="blog"
                >
                    <template #left="item">
                        <router-link :to="`/${item.uri}`">
                        <h3 class="t-t4" v-html="item.title" />
                        </router-link>
                    </template>
                    <template #right="item">
                        <btn
                            tag="router-link"
                            :href="`/${item.uri}`"
                            :title="$t('btn.readMore')"
                            :label="$t('btn.readMore')"
                            iconAfter="arrow-long-right"
                            bg="none"
                        />
                    </template>
                </list>
            </div>
        </div>
    </app-section>
</template>

<script>

import AppSection from 'layout/AppSection';
import AnimText from 'objects/AnimText';
import Btn from 'components/Btn';
import List from 'components/List';

export default {
    name: 'EntriesList',
    components: {
        AppSection,
        AnimText,
        Btn,
        List,
    },
    props: {
        type: {
            type: String,
            default: 'posts',
        },
        exclude: {
            type: String|Number|Array,
            default: -1,
        },
    },
    data: () => ({
        title: null,
        list: [],
    }),
    created() {

        const titleHandle = this.type === 'posts' ? 'blog' : this.type

        // Load blog title
        this.$store.dispatch('pages/loadSingle', titleHandle)
            .then(page => {
                this.title = page.mainTitle
            })
            .catch(e => {
                console.error('Error: EntriesList/loadSingle', e)  // eslint-disable-line
            })

        // Load post list
        this.$store.dispatch('pages/loadList', this.type)
            .then(entries => {

                // Filter all entries without exlude
                if(typeof this.exclude === 'object') {
                    this.list = entries.filter(post => !this.exclude.includes(post.id))
                } else if(this.exclude !== -1) {
                    this.list = entries.filter(post => post.id !== this.exclude)
                } else {
                    this.list = entries
                }
            })
            .catch(e => {
                console.error('Error: EntriesList/loadList', e)  // eslint-disable-line
            })

    },
};

</script>

<style lang="scss">
