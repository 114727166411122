<template>
    <div>
        <app-section
            :title="page.mainTitle"
            titleClass="t-t2"
            :cover="cover"
            :breadcrumb="true"
        />
        <builder :builder="page.builder" />
        <entries-list
            :exclude="page.id"
            type="events"
        />
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Builder from 'builder/Builder';
import EntriesList from 'components/EntriesList';

export default {
    name: 'Event',
    components: {
        AppSection,
        Builder,
        EntriesList,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    computed: {
        cover() {
            if(this.page.asset.length === 0) {
                return null
            } else {
                return this.page.asset[0]
            }
        }
    }
};

</script>

<style lang="scss">

.p-event {
}


</style>
