<template>
    <div :class="className">
        <form
            class="c-search__form"
            @submit.prevent="submit(query)"
        >
            <label class="c-search__label | -screen-reader-text" for="searchInput">{{ $t('search.label') }}</label>
            <input
                id="searchInput"
                type="text"
                v-model="query"
                :placeholder="$t('header.searchPlaceholder')"
                class="c-search__input | t-small"
            />
            <button
                :aria-label="$t('aria.search')"
                type="submit"
                class="c-search__submit | u-as"
            >
                <icon icon="search" />
            </button>
            <span class="c-btn__border"></span>
        </form>
        <div class="c-search__inner">
            <div
                v-if="recommendations"
                class="c-search__reco"
            >
                <button
                    v-for="(rec, i) in recommendations"
                    :key="`search-recommendations-${i}`"
                    class="t-link"
                    @click="submit(rec.slug)"
                >
                    {{ rec.title }}
                </button>
            </div>
            <div class="c-search__favorites">
                <span class="u-dp-block">{{ $t('search.favorites') }}</span>
                <button
                    v-for="(fav, i) in favorites"
                    :key="`search-favorite-${i}`"
                    class="t-link"
                    @click="submit(fav.slug)"
                >
                    {{ fav.title }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import Icon from 'objects/Icon';
import Btn from 'components/Btn';
import ProductList from 'components/ProductList';

import { mapState } from 'vuex'

export default {
    name: 'Search',
    components: {
        Icon,
        Btn,
        ProductList,
    },
    data: () => ({
        isOpen: false,
        query: '',
        results: [],
    }),
    computed: {
        ...mapState({
            currentQuery: state => state.search.currentQuery
        }),
        className() {
            let classname = 'c-search'

            if(this.isOpen) {
                classname += ' is-open'
            }

            if(this.query === '') {
                classname += ' is-empty'
            }

            return classname
        },

        // TODO
        // Generate recommendations and limit to 4
        recommendations() {
            if(this.query.length < 3) {
                return false
            }

            return [
                {
                    title: 'Dress',
                    slug: 'dress',
                },
                {
                    title: 'Silk',
                    slug: 'silk',
                },
                {
                    title: 'Tops',
                    slug: 'tops',
                },
                {
                    title: 'Bottoms',
                    slug: 'bottoms',
                },
            ]
        },

        // TODO
        // Fetch favorites terms (4)
        favorites() {
            return [
                {
                    title: 'Dress',
                    slug: 'dress',
                },
                {
                    title: 'Silk',
                    slug: 'silk',
                },
                {
                    title: 'Tops',
                    slug: 'tops',
                },
                {
                    title: 'Bottoms',
                    slug: 'bottoms',
                },
            ]
        },
    },
    methods: {
        open() {
            if(this.isOpen) {
                return
            }

            this.isOpen = true
        },
        close() {
            if(!this.isOpen) {
                return
            }

            this.isOpen = false
        },
        toggle() {
            if(this.isOpen) {
                this.close()
            } else {
                this.open()
            }
        },
        submit(query) {

            if(!query) {
                return
            }

            if ( this.currentQuery == query ) {
                this.close()
                return
            }

            this.$router.push({
                name: 'search',
                query: {
                    q: query
                }
            })

            this.close()
        },
    },
    watch: {
        isOpen(isOpen) {
            this.$emit('toggle', isOpen)
        }
    },
};

</script>

<style lang="scss">

$search-bar-height: $accessible-size;

.c-search {

    &:before {
        @include pseudo-el($bg: $color-light);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transform: scale(1, 0);
        transform-origin: 50% 0;
        transition: all .4s $out-quint;
    }

    .c-btn__border {
        pointer-events: none;
    }

    &.is-open {

        &:before {
            opacity: 1;
            transform: scale(1);
            transition: all .8s $out-expo;
        }

        .c-search__form,
        .c-search__inner {
            opacity: 1;
            transition: opacity .3s ease-out .3s;
        }
    }

    &.is-empty {

        .c-search__submit {
            opacity: 0;
            pointer-events: none;
        }
    }

    &.is-loading {

    }

    .c-btn__border {

        &:before {
            right: 0;
            bottom: 0;
            height: calc(100% - 2px);
        }

        &:after {
            right: 0;
            width: calc(100% - 2px);
        }
    }

    @media #{md("sm")} {
        max-width: 22em;
    }
}

/*----------  Search bar  ----------*/

.c-search__form {
    display: flex;
    align-items: center;
    opacity: 0;
    background-color: $color-primary-light;
}

.c-search__input {
    display: block;
    width: 100%;
    height: $search-bar-height;
    padding-left: .75rem;
}

.c-search__submit {
    right: 0;
}


/*----------  Inner  ----------*/

.c-search__inner {
    padding: 1.25rem .75rem;
    opacity: 0;
    font-size: .875em;

    .t-link {
        margin-top: .3em;
        margin-bottom: .3em;
        color: $color-secondary-dark;
    }
}

.c-search__reco {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2em;

    .t-link {

    }
}

.c-search__favorites {

    .t-link {
        display: inline-block;

        &:nth-child(n+3) {
            margin-left: 1em;
        }
    }
}


</style>
