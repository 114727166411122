<template>
    <article :class="className">
        <div class="c-product-card__inner">

            <div
                v-if="assets.length > 0"
                class="c-product-card__asset"
            >
                <div
                    v-if="hasSlider"
                    class="c-product-card__slider"
                >
                    <slider
                        ref="flkty"
                    >

                        <router-link
                            v-if="thumb && thumb.url && thumb.url != images[0].asset[0].url"
                            :to="`/${uri}`"
                            class="c-product-card__img"
                        >
                            <asset
                                v-bind="thumb"
                                :reveal="false"
                                :contain="true"
                                @load="$refs.flkty.resize()"
                            />
                        </router-link>
                        <router-link
                            v-for="(a, i) in images"
                            :key="`product-${uri}-img-${i}`"
                            :to="`/${uri}`"
                            class="c-product-card__img"
                        >
                            <asset
                                v-bind="a.asset"
                                :reveal="false"
                                :contain="true"
                                @load="$refs.flkty.resize()"
                            />
                        </router-link>
                    </slider>
                </div>

                <router-link
                    v-else-if="thumb && thumb.url"
                    :to="`/${uri}`"
                    class="c-product-card__img"
                >
                    <asset
                        v-bind="thumb"
                        :contain="true"
                    />
                </router-link>

                <div
                    v-if="unavailable"
                    class="c-product-card__unavailable"
                >
                    <tags
                        :tags="[ $t('product.outOfStock') ]"
                    />
                </div>

                <div
                    v-else-if="inSale"
                    class="c-product-card__inSale"
                    :class="{'-has-variants': variantSizes.length > 0 && variantSizes[0].title != 'U'}"
                >
                    <span>
                        {{ minPrice|price }}
                    </span>
                </div>

                <app-input-check
                    v-if="!unavailable && variantSizes.length > 0 && variantSizes[0].title != 'U'"
                    :radio="true"
                    :inputs="variantSizes"
                    class="c-product-card__sizes"
                />
            </div>

            <div class="c-product-card__content">
                <router-link
                    :to="`/${uri}`"
                    v-if="title"
                    class="c-product-card__title | t-small"
                >
                    {{ title }}
                </router-link>
                <btn
                    v-if="minPrice"
                    :href="`/${uri}`"
                    tag="router-link"
                    class="c-product-card__price"
                    :class="{'-strike': inSale}"
                    :label="displayPrice|price"
                    bg="none"
                    iconAfter="arrow-long-right"
                    :reveal="false"
                />
            </div>
        </div>
    </article>
</template>

<script>
import Tags from 'objects/Tags';
import Asset from 'objects/Asset';
import Btn from 'components/Btn';
import Slider from 'components/Slider';
import AppInputCheck from 'components/AppInputCheck';

import Flickity from 'vue-flickity'

export default {
    name: 'ProductCard',
    components: {
        Tags,
        Asset,
        Btn,
        Slider,
        AppInputCheck,
        Flickity,
    },
    props: {
        uri: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        price: {
            type: Number,
            default: 0
        },
        thumb: {
            type: Object,
            default: null
        },
        assets: {
            type: Array,
            default: () => []
        },
        sizes: {
            type: Array,
            default: () => []
        },
        variantSizes: {
            type: Array,
            default: () => []
        },
        inSale: {
            type: Boolean,
            default: false
        },
        unavailable: {
            type: Boolean,
            default: false
        },
        minPrice: {
            type: Number
        },
        regularPrice: {
            type: Number
        },
        big: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        flktyOptions: {
            wrapAround: true,
            prevNextButtons: false,
            pageDots: false,
        },
    }),
    computed: {
        hasSlider() {
            return this.images.length > 0
        },
        className() {
            let classname = 'c-product-card'

            if(this.unavailable) {
                classname += ' is-unavailable'
            }

            if(this.inSale) {
                classname += ' is-in-sale'
            }

            if(this.big) {
                classname += ' -big'
            }

            return classname
        },
        images() {
            return this.assets.filter(a => a.asset)
        },
        displayPrice() {
            return (this.inSale) ? this.regularPrice : this.minPrice
        }
    },
    watch: {
        big() {
            this.$refs.flkty.resize()
        }
    }
};

</script>

<style lang="scss">

.c-product-card {
    --padding: 2.25em;

    display: block;

    &.-big {

        .c-product-card__content {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
            justify-content: space-between;
            text-align: left;
        }

        .c-product-card__price {
            padding-left: 0;
            padding-right: 0;
            white-space: nowrap;
        }

        .c-product-card__title {
            margin-right: 1em;
        }
    }

    &.is-unavailable {

        .c-product-card__img {
            opacity: .5;
        }
    }

    &:hover {

        .c-product-card__slider {

            .c-slider__btn {

                &.-prev,
                &.-next {

                    &:not(.is-disabled) {
                        transform: translate(0);
                        transition: transform .4s $out-quint;
                    }
                }
            }
        }

        .c-product-card__inSale {

            @media #{md("sm")} {
                &.-has-variants {
                    transform: translateY(-3.5rem);
                    transition: transform .46s $out-quint;
                }
            }
        }

        .c-product-card__sizes {
            transform: translate(0);
            transition: transform .4s $out-quint;
        }

    }

    @media #{md("sm")} {

        &.-big {
            --padding: 3.75em;
        }
    }
}

.c-product-card__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: calc( var(--padding) / 2);

    background-color: $color-primary-light;

    @media #{md("sm")} {
        padding: calc( var(--padding) / 1.5);
    }

    @media #{md("md")} {
        padding: var(--padding);
    }
}

.c-product-card__asset {
    margin-top: auto;
    margin-bottom: auto;

    overflow: hidden;
}

.c-product-card__img {
    --contain-ratio: calc(4/3 * 100%);

    display: block;
    width: 100%;
    background-color: $color-light;
}

.c-product-card__slider {
    background-color: $color-light;

    .c-product-card__img {
        width: 100%;
    }

    .c-slider__nav {
        position: absolute;
        top: calc(50% - #{$accessible-size} * 1.5/2);
        left: 0;
        width: 100%;
        overflow: hidden;
        pointer-events: none;
    }

    .c-slider__btn {
        width: $accessible-size;
        height: $accessible-size;
        font-size: .8em;
        transition: transform .2s $out-quad;
        pointer-events: auto;

        &.-prev {
            left: -$accessible-size/2;
            justify-content: flex-end;
            padding-right: .4em;
            transform: translate(-50%, 0);
        }

        &.-next {
            right: -$accessible-size/2;
            justify-content: flex-start;
            padding-left: .4em;
            transform: translate(50%, 0);
        }

        .o-icon {
            position: static;

            &:before {
                @include pseudo-el($bg: $color-primary-light);
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 50%;
            }
        }
    }

    @media #{md("xs")} {

        .c-slider__btn {
            width: $accessible-size * 1.5;
            height: $accessible-size * 1.5;
            font-size: 1em;

            &.-prev {
                left: -$accessible-size * 1.5/2;
                padding-right: .75em;
            }

            &.-next {
                right: -$accessible-size * 1.5/2;
                padding-left: .75em;
            }
        }
    }
}

.c-product-card__unavailable {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    .o-tag {
        display: block;
    }
}

.c-product-card__inSale {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    transition: transform .22s $out-quad;

    span {
        padding: .25em .35em;
        color: $color-light;
        background: $color-red;
        font-size: .875em;
    }
}

.c-product-card__sizes {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: $color-light;
    transform: translate(0, 100%);
    transition: transform .2s $out-quad;

    height: 3.5rem;

    &.-radio {
        margin: 0;
        justify-content: center;
    }

    //--- TODO
    //--- Everything under is temporary
    //--- The __sizes will need to be horizontally scrollable when there's more than 4-5 values
    //--- + it will need to be displayed under the asset(s) on touch devices
    pointer-events: none;

    @media #{md("md", "max")} {

        .o-input-check {
            margin: 0 !important;
            font-size: 0.75rem;
        }
    }

    @media #{md("sm", "max")} {
        display: none !important;

        label {
            transform: scale(0.5);
        }
    }
}

.c-product-card__content {
    margin-top: 1em;
    text-align: center;

    @media #{md("xs", "max")} {
        font-size: .8rem;
    }
}

.c-product-card__price {
    padding-bottom: 0;

    &.-strike {
        text-decoration: line-through;
    }
}

</style>
