<template>
    <div>
        <app-section
            :title="page.mainTitle"
            :breadcrumb="true"
            :cover="!page.video ? cover : false"
            class="p-collection__lookbook"
        >
            <template
                v-if="page.video"
                #before-content
            >
                <vimeo
                    :id="page.video"
                    :controls="page.showControls"
                    :muted="page.showControls && page.muteByDefault"
                    :autoplay="autoplay"
                    class="o-collection__video"
                />
            </template>
            <navbar
                v-if="catalog"
                class="p-collection__navbar"
            >
                <btn
                    tag="router-link"
                    :label="catalogLabel"
                    :href="`/${catalog.uri}`"
                    :reveal="false"
                    bg="none"
                    iconAfter="arrow-long-right"
                    class="p-collection__nav-btn"
                />
            </navbar>
        </app-section>
        <builder :builder="page.builder" />
        <push :push="page.push" />
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Builder from 'builder/Builder';
import Icon from 'objects/Icon';
import Vimeo from 'objects/Vimeo';
import Btn from 'components/Btn';
import Push from 'components/Push';
import Navbar from 'components/Navbar';

export default {
    name: 'Collection',
    components: {
        AppSection,
        Builder,
        Icon,
        Vimeo,
        Btn,
        Push,
        Navbar,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    computed: {
        autoplay() {

            const _page = this.page

            if (!_page.showControls)
                return true

            else if ( _page.showControls && !_page.muteByDefault )
                return false

            else if ( _page.showControls && _page.muteByDefault && !_page.autoPlayByDefault )
                return false

            else
                return true

        },
        cover() {
            const asset = this.page.landscapeImage || this.page.asset
            return asset && asset.length > 0 ? asset[0] : false
        },
        catalog() {
            const catalog = this.page.selectedCatalog
            return catalog && catalog.length > 0 ? catalog[0] : false
        },
        catalogLabel() {
            return this.page.label || `${this.$t('btn.shop')} ${this.page.title}`
        }
    }
};

</script>

<style lang="scss">

.p-collection__navbar {

    .c-navbar__inner {
        padding: 0;
    }

    .c-btn {
        justify-content: center;
        width: 100%;
        padding: 1.2em var(--grid-gutter-half);
    }
}

</style>
