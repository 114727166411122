<template>
    <app-base
        id="app"
        :style="`--vh: ${W.h/100}px`"
    />
</template>

<script>

import 'assets/scss/styles.scss';

import Variables from 'src/mixins/variables';
import { reducedMotion } from 'src/utils';

import AppBase from 'layout/AppBase';

export default {
    name: 'app',
    components: {
        AppBase,
    },
    mixins: [ Variables ],
    metaInfo() {
        return {
            titleTemplate: `%s`
        }
    },
    created() {

        this.$store.dispatch('loader/startLoad')

        const $html = document.documentElement
        // Browser and OS classes
        $html.classList.add(`is-${this.browser.os}`)
        $html.classList.add(`is-${this.browser.name}`)

        if(this.isMobile) {
            $html.classList.add('is-mobile')
        } else {
            $html.classList.add('is-desktop')
        }

        if(reducedMotion) {
            $html.classList.add(`reduced-motion`)
        }

    },
    mounted() {
        this.$store.dispatch('loader/endLoad')
    },
}
</script>

<style lang="scss">
</style>
