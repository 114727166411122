<template>
    <div>
        <app-section
            :title="featured ? featured.title : null"
            :cover="featured && page.highlightedAssets.length === 0 ? featured.asset : null"
            :breadcrumb="true"
            titleClass="t-t2"
        >
            <template #after-title>
                <div
                    v-if="page.highlightedAssets.length > 0"
                    class="p-blog__assets"
                >
                    <asset
                        v-for="(asset, i) in page.highlightedAssets"
                        :key="`asset-${i}`"
                        v-bind="asset"
                    />
                </div>
            </template>
            <div class="u-dp-flex u-jc-center">
                <btn
                    v-if="featured"
                    tag="router-link"
                    :href="`/${featured.uri}`"
                    :label="$t('btn.readMore')"
                    bg="primary-light"
                />
            </div>
            <blocks :blocks="blocks" />
        </app-section>
        <entries-list :exclude="excludes" />
        <push :push="page.push" />
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Asset from 'objects/Asset';
import AnimText from 'objects/AnimText';
import Btn from 'components/Btn';
import List from 'components/List';
import Blocks from 'components/Blocks';
import Push from 'components/Push';
import EntriesList from 'components/EntriesList';

export default {
    name: 'Blog',
    components: {
        AppSection,
        Asset,
        AnimText,
        Btn,
        List,
        Blocks,
        Push,
        EntriesList,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    computed: {
        featured() {
            let selectedPost = this.page.selectedPost
            if(selectedPost.length > 0) {
                selectedPost = selectedPost[0].id
                return this.page.posts.find(e => e.id === selectedPost)
            } else {
                return this.page.posts[0]
            }
        },
        blocks() {

            if ( !this.page || !this.page.blocks )
                return

            if(this.page.blocks.length > 0) {
                return this.page.blocks
            } else {
                // Format for block component
                return [{ entry: [this.postsNotFeatured[0]]}, { entry: [this.postsNotFeatured[1]] }]
            }
        },
        postsNotFeatured() {

            if ( !this.page || !this.page.posts )
                return

            return this.page.posts.filter(e => e.id !== this.featured.id)
        },
        excludes() {
            // Retrieve blocks ids
            const blocksIDS = this.blocks.filter(block => block.entry && block.entry.length > 0).map(block => block.entry[0].id)
            return [this.featured.id, ...blocksIDS]
        },
    },
};

</script>

<style lang="scss">

.p-blog {

    .c-blocks {
        margin-top: var(--section-margin);
    }
}

.p-blog__assets {
    display: flex;
    align-items: center;
    justify-content: center;

    .o-asset {

        &.-portrait:only-child {
            width: calc(5/6 * 100%);
        }

        &:not(:only-child) {

            &:nth-child(1),
            &:nth-child(3) {
                width: calc(3/10 * 100%);
            }

            &:nth-child(2) {
                width: calc(4/10 * 100%);
            }
        }
    }

    @media #{md("xs")} {

        .o-asset.-portrait:only-child {
            width: calc(3/4 * 100%);
        }
    }
}

</style>
