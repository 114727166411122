<template>
    <div
        v-if="data"
        class="c-push"
    >
        <anim-text
            tag="h2"
            :text="data.headline"
            class="c-push__title | t-t5"
        />
        <btn
            tag="router-link"
            :href="`/${entry.uri}`"
            :label="data.btnLabel ? data.btnLabel : entry.title"
            size="xl"
            class="c-push__btn"
        />
    </div>
</template>

<script>

import AnimText from 'objects/AnimText';
import Btn from 'components/Btn';

export default {
    name: 'Push',
    components: {
        AnimText,
        Btn,
    },
    props: {
        push: {
            type: Object|Array,
            default: () => {},
        },
    },
    computed: {
        data() {
            // If object, return object
            if (!Array.isArray(this.push)) {
                return this.push
            }

            // Return first instance if array
            return this.push.length > 0 ? this.push[0] : false
        },
        entry() {
            if(!this.data || this.data.entry.length === 0) {
                return false
            } else {
                return this.data.entry[0]
            }
        }
    },
};

</script>

<style lang="scss">

.c-push {
    margin-top: var(--section-margin);
    margin-bottom: calc(1.5 * var(--section-margin));
    text-align: center;
}

.c-push__title {
}

.c-push__btn {
}

</style>
