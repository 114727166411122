<template>
    <div
        :class="className"
        ref="container"
    >
        <template v-if="totalSlides > 0">
            <flickity
                ref="flkty"
                :options="flktyOptions"
                class="c-slider__flkty"
            >
                <slot>
                    <div
                        v-for="(slide, i) in slides"
                        :key="`slider-slide-${i}`"
                        class="o-slide"
                    >
                        <asset
                            v-bind="slide"
                            @load="resize()"
                        />
                    </div>
                </slot>
            </flickity>

            <nav class="c-slider__nav">
                <button
                    :aria-label="$t('aria.prev')"
                    class="c-slider__btn -prev | u-as"
                    :class="{ 'is-disabled' : selectedIndex === 0 }"
                    @click="previous()"
                >
                    <icon icon="arrow-long-left" />
                </button>
                <button
                    :aria-label="$t('aria.next')"
                    class="c-slider__btn -next | u-as"
                    :class="{ 'is-disabled' : selectedIndex === totalSlides - 2 }"
                    @click="next()"
                >
                    <icon icon="arrow-long-right" />
                </button>
            </nav>
        </template>
    </div>
</template>

<script>

import Icon from 'objects/Icon'
import Asset from 'objects/Asset';

import Flickity from 'vue-flickity'

export default {
    name: 'Slider',
    components: {
        Icon,
        Asset,
        Flickity,
    },
    data: () => ({
        flktyOptions: {
            contain: true,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            watchCSS: true,
            groupCells: true
        },
        selectedIndex: 0,
        totalSlides: 0,
        isDragging: false,
    }),
    props: {
        slides: {
            type: Array
        },
        modifier: {
            type: String,
            default: null,
        },
    },
    created() {
        this.totalSlides = this.$slots.default ? this.$slots.default.length : this.slides ? this.slides.length : 0
    },
    mounted() {
        const flkty = this.$refs.flkty

        if (flkty) {

            flkty.on('dragStart', () => this.isDragging = true );
            flkty.on('dragEnd', () => this.isDragging = false );

            flkty.on('select', i => {
                this.selectedIndex = i
            })
        }
    },
    computed: {
        className() {
            let classname = 'c-slider'

            if(this.modifier) {
                classname += ` ${this.modifier}`
            }

            if(this.isDragging) {
                classname += ' is-dragging'
            }

            return classname
        }
    },
    methods: {
        resize() {
            this.$refs.flkty.resize()
        },
        previous() {
            this.$refs.flkty.previous()
        },
        next() {
            this.$refs.flkty.next()
        },
        reloadCells() {
            this.$refs.flkty.reloadCells()
        }
    },
};

</script>

<style lang="scss">

.c-slider {

    //
    // Modifiers
    //

    &.-full-slide {

        .c-slider__flkty {
            --slide-width: 100vw;
        }
    }

    &.-full-vw {

        .c-slider__flkty {
            width: 100vw;
            left: calc(50% - 50vw);
        }

        .o-slide {

            &:first-child {
                padding-left: var(--grid-gutter-half);
            }

            &:last-child {
                padding-right: var(--grid-gutter-half);
            }
        }
    }

    &.-alternate {

        .flickity-slider {
            display: flex;
            align-items: center;
        }

        .o-slide {
            width: auto;

            img {
                width: auto;
                height: 65vmin;
            }

            .o-slide__bg {
                width: 45vmin;
                height: 65vmin;
            }

            &:nth-child(2n) {
                margin-top: 2em;
                margin-bottom: 2em;

                img {
                    height: 50vmin;
                }

                .o-slide__bg {
                    height: 55vmin;
                }
            }

            &:nth-child(3n) {

                .o-slide__bg {
                    width: 90vmin;
                }
            }
        }
    }

    &.-center {

        .flickity-slider {
            display: flex;
            align-items: center;
        }
    }

    // Dragging event
    &.is-dragging {

        a,
        button {
            pointer-events: none;
        }
    }
}

.c-slider__flkty {
    --slide-width: calc(3/4 * 100%);

    &:after {
        content: "flickity";
        display: none;
    }

    .flickity-viewport {
        width: 100%;
        overflow: hidden;
    }

    .flickity-slider {
        position: absolute;
        width: 100%;
        height: 100%;

        > * {
            width: var(--slide-width);
        }
    }

    @media #{md("xs")} {
        --slide-width: calc(2/3 * 100%);
    }

    @media #{md("sm")} {
        --slide-width: calc(1/3 * 100%);
    }
}


.c-slider__nav {
    display: flex;
    justify-content: space-between;
}

.c-slider__btn {
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 2em;
    transition: transform .2s ease-out;

    &:hover {
        transform: scale(.9);
        transition: transform .3s ease-out;
    }

    &.is-disabled {
        opacity: .2;
        pointer-events: none;
    }

    &.-prev {
        transform-origin: 0 50%;
    }

    &.-next {
        transform-origin: 100% 50%;
    }
}


/*==================================
=            Slide item            =
==================================*/

.o-slide {
    display: block;

    &:nth-child(2n+1) {

        .o-slide__bg {
            background-color: $color-primary;
        }
    }
}

.o-slide__bg {
    display: block;
    width: 100%;
    height: 0;
    // padding-top: calc(3/2 * 100%);
    background-color: $color-primary-light;

    & + .o-slide__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        color: $color-dark;

        &:before {
            display: none;
        }

        .o-slide__title {
            margin-top: auto;
            margin-bottom: auto;
            text-align: center;
        }
    }
}

.o-slide__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2em 25% 2em var(--grid-gutter);
    color: $color-light;

    &:first-child {
        position: relative;
        color: $color-dark;
        background-color: $color-primary-light;

        &:before {
            display: none;
        }
    }

    &:before {
        @include pseudo-el;
        @include linear-gradient(to bottom, rgba(#fff, 0), $color-dark);
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: .6;
    }

    @media #{md("xs", "max")} {
        padding: 1.5em;
        font-size: .7rem;

        &.t-content {

            > *:nth-child(n+2) {
                margin-top: .5rem;
            }
        }
    }
}

</style>
