<template>
    <component
        v-if="text"
        :is="cms ? 'div' : tag"
        :class="className"
        v-html="text"
        v-reveal.once="reveal ? revealHandle : false"
    />
</template>

<script>

export default {
    name: 'AnimText',
    props: {
        text: {
            type: String,
            default: null,
        },
        tag: {
            type: String,
            default: 'span',
        },
        reveal: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        cms: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isVisible: false,
    }),
    created() {
        this.isVisible = this.visible
    },
    methods: {
        revealHandle(state) {
            this.isVisible = state.isActive
        },
    },
    computed: {
        className() {
            let classname = 'o-at'

            if(this.reveal) {
                classname += ' js-reveal'
            }

            if(this.isVisible) {
                classname += ' is-visible'
            }

            if(this.cms) {
                classname += ' t-cms'
            }

            return classname
        }
    },
    watch: {
        isVisible(visible) {
            this.$emit('toggle', visible)

            if(visible) {
                this.$emit('show')
            } else {
                this.$emit('hide')
            }
        },
        visible(visible) {
            this.isVisible = visible
        },
    }
};

</script>

<style lang="scss">

.o-at {
    display: block;
    @include anim-reveal-default;

    // &.t-cms {
    //     @include anim-reveal-default;
    // }

    html.reduced-motion & {

        &.t-cms > *,
        .o-at__w {
            transition: none !important;
        }
    }
}

</style>
