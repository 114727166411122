import Vue from 'vue'
import store from 'src/store'
import { linkResolver } from 'src/router'

const currentLocale = window.__initialData__.currentSite.lang || 'en-US'

// Prefix url for translation
Vue.filter('resolver', function (handle, slug) {
    if (!handle) {
        return '/'
    }

    return linkResolver(handle, slug)
})

/*
** Date formats
*/

// Datetime
Vue.filter('datetime', function (date) {

    date = new Date(date)

    let dd = date.getDate()
    let mm = date.getMonth() + 1
    mm = mm < 10 ? `0${mm}` : mm
    let yyyy = date.getFullYear()

    return `${yyyy}-${mm}-${dd}`
})

// Date display
Vue.filter('date', function (date) {

    date = new Date(date)

    let dd = date.getDate()
    dd = dd < 10 ? `0${dd}` : dd
    let month = date.toLocaleString(currentLocale, { month: 'long' })
    let yyyy = date.getFullYear()

    return `${month} ${dd}, ${yyyy}`
})


/*
** Format phone number for href
*/
Vue.filter('phoneHref', function (phone) {
    phone = phone.replace(/[^0-9]/g, '')
    phone = `tel:+1${phone}`
    return phone
});



/*
** Format price
*/
Vue.filter('price', function (amount) {

    const currency = store.getters['shop/getCurrencyCode']
    const country = store.getters['global/getCurrentCountry']

    if (!amount || !currency || !country) {
        return amount
    }

    // Create the formatter with the current site|store settings
    const formatter = new Intl.NumberFormat(country, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0
    })

    // Format amount
    amount = formatter.format(amount)

    // Return with currency when needed
    return `${currency ? currency : '' } ${amount}`
});
