// Craft
import {fetchApi} from 'src/graphql/craft/config';
import queries from 'src/graphql/craft/queries';

/**********************************
 *
 *
 *  GLOBAL
 *
 *
 **********************************/

const initialData = window.__initialData__

// State
const state = {
    site: initialData.currentSite,
    sites: initialData.sites,
    currentPage: {},
    currentPageName: null,
    colors: {
        main: '#FFFAED',
        darker: '#F3ECDA',
    },
    socials: null,
    address: null,
    instagram: initialData.instagram,
    user: initialData.currentUser,
    error: false
};

// Getters
const getters = {
    getCurrentSite: state => state.site,
    getSites: state => state.sites,
    getCurrentCountry: state => state.site.lang,
    getCurrentPageId: state => state.currentPage.id,
    getCurrentPageTitle: state => state.currentPage.title,
    getSocials: state => state.socials,
    getAddress: state => state.address,
    getInstagramAccount: state => state.instagram ? state.instagram.account : null,
    getInstagramImages: state => state.instagram ? state.instagram.images : [],
    getUser: state => state.user,
    hasCurrentPage: state => !(Object.keys(state.currentPage).length === 0 && state.currentPage.constructor === Object),
    error: state => state.error
};

// Actions
const actions = {

    init(store) {

        // Start load
        store.dispatch('loader/startLoad', null, {root: true});

        return Promise.all([
            store.dispatch('navigations/init', null, {root: true}),
            store.dispatch('shop/init', null, {root: true}),
            store.dispatch('globalsets/loadModal', null, {root: true}),
            store.dispatch('loadGeneral'),
        ]).then(() => {

            // Need this here to have access to shop/settings (ie. currencyCode)
            store.dispatch('checkout/loadFromLocalStorage', null, {root: true})
                .then(() => {
                    store.dispatch('cart/loadFromLocalStorage', null, {root: true})
                })
                .catch(e => {
                    if ( e.removeCart == true ) {

                        store.dispatch('cart/removeFromLocalStorage', null, {root: true})
                        .then(() => {
                            //
                        })
                        .catch(() => {
                            //
                        })
                    }
                    console.error('Error: Load Shopify Checkout', e.message) // eslint-disable-line
                })

            // End load
            store.dispatch('loader/endLoad', null, {root: true});
        })
        .catch(e => {
            console.error('Error: Initial dispatch', e) // eslint-disable-line
            store.dispatch('loader/endLoad', null, {root: true});
        })
    },
    loadGeneral(store) {
        fetchApi(queries.general)
            .then(data => {
                data = data.globalSet

                // Set social list
                store.commit('setSocials', data.socialList);

                // Set general colors
                const colors = {
                    main: data.colorMain,
                    darker: data.colorDarker,
                }
                store.commit('setColors', colors)

                // Set address
                store.commit('setAddress', data.address[0]);
            })
            .catch(e => {
                console.error('Error: load/General', e) // eslint-disable-line
            })
    },
    loadContent(store, to) {

        return new Promise((resolve, reject) => {

            // Timer in seconds
            const timer = new Date

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Load page content
            let fetchContent;
            const handle = to.meta.section;
            const singleEntry = to.meta.single;


            // Empty promise if nothing to load (static page)
            if(typeof handle === 'undefined') {
                //fetchContent = new Promise(r => r({}))
                fetchContent = new Promise(r => r({ static: true }))
            }
            // For single entries, load page with handle
            else if (singleEntry) {
                fetchContent = store.dispatch('pages/loadSingle', handle, {root: true});
            // For channel/structure, load page with handle and slug
            } else {
                const slug = to.params.slug;
                fetchContent = store.dispatch('pages/loadEntry', { handle, slug }, {root: true});
            }

            fetchContent.then(page => {
                // Duration in ms of the fetchContent + 300ms for the loader cover delay (see loader component) + 10ms for latency
                let delay = (timer - new Date) + 300
                delay = delay < 0 ? 0 : delay

                setTimeout(() => {
                    store.commit('setPage', {});
                    store.commit('setPageName', to.name)
                    resolve(page);

                    // Timeout to let template to render data
                    setTimeout(() => {
                        // Set page after router page has updated to avoid js errors
                        store.commit('setPage', page);
                        store.dispatch('loader/endLoad', null, {root: true});
                    }, 10);

                }, delay);
            })
            .catch(e => {

                reject(e)
                store.dispatch('loader/endLoad', null, {root: true})

            })

        });
    },
    fetchPageUrl(store, { pageID, siteID }) {
        return fetchApi(queries.pageURL(pageID, siteID))
            .then(data => {
                // If entry exists, return site entry url
                if(data && data.entry) {
                    return data.entry.url
                // Else return site homepage
                } else {
                    const baseUrl = store.state.sites.find(s => s.id === siteID).baseUrl
                    return `${window.location.origin}${baseUrl}`
                }
            })
    },
    error(store, error = true) {
        store.commit('setError', error);
    }
};

// Mutations
const mutations = {
    setPage(state, page) {
        state.currentPage = page;
    },
    setPageName(state, name) {
        state.currentPageName = name
    },
    setSocials(state, data) {
        state.socials = data;
    },
    setColors(state, data) {

        if ( data.main )
            state.colors.main = data.main

        if ( data.darker )
            state.colors.darker = data.darker
    },
    setAddress(state, address) {
        state.address = address;
    },
    setError(state, value) {
        state.error = value;
    },
};


// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
