import { fetchApi } from 'src/graphql/craft/config'
import queries from 'src/graphql/craft/queries'

/**********************************
 *
 *
 *  CRAFT/PAGES
 *
 *
 **********************************/

// State
const state = {
    pages: [],
    lists: {},
}

// Getters
const getters = {
    getPageById:        state => id => state.pages.find(page => page.id === id),
    getPageByHandle:    state => section => state.pages.find(page => page.sectionHandle === section),
    getPageBySlug:      state => (slug, handle) => state.pages.find(page => page.slug === slug && page.typeHandle === handle),
    getPageIndexBySlug: state => slug => state.pages.findIndex(page => page.slug === slug),
    getPageIndexById:   state => id => state.pages.findIndex(page => page.id === id),
    getPagesCount:      state => state.pages.length,
    getListByHandle:    state => handle => state.lists[handle],
}

// Actions
const actions = {
    loadSingle(store, handle) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if page already exists
            let page = store.getters.getPageByHandle(handle)

            // If page already exists, resolve with the page value
            if (typeof page !== 'undefined') {

                resolve(page)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If page doesn't exist, load page
            } else {

                fetchApi(queries[handle])
                    .then(r => {

                        // Exit on error
                        if (!r || !r.entry) {
                            reject({
                                code: 404,
                                message: 'Page not found'
                            })
                            store.dispatch('loader/endLoad', null, {root: true});

                        } else {

                            // Flatten entry and add other data to object
                            let { entry, ...data} = r
                            data = { ...r.entry, ...data }

                            store.commit('addPage', {data, index: -1})
                            resolve(data)
                            store.dispatch('loader/endLoad', null, {root: true});

                        }
                    })

            }

        })
    },
    loadEntry(store, {handle, slug}) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if page already exists
            let page = store.getters.getPageBySlug(slug, handle)

            // If page already exists, resolve with the page value
            if(typeof page !== 'undefined' && page.singleFetch) {
                resolve(page)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If page doesn't exist, load page
            } else {
                fetchApi(queries[handle](slug))

                    .then(r => {

                        // Exit on error
                        if (!r || !r.entry) {
                            reject({
                                code: 404,
                                message: 'Page not found'
                            })
                            store.dispatch('loader/endLoad', null, {root: true});

                        } else {

                            // Flatten entry and add other data to object
                            let { entry, ...data} = r
                            data = { ...r.entry, ...data }
                            data.singleFetch = true

                            const index = store.getters.getPageIndexById(data.id)
                            store.commit('addPage', {data, index})
                            resolve(data)
                            store.dispatch('loader/endLoad', null, {root: true});
                        }
                    })

            }

        })
    },
    loadPartial(store, {id, query='defaultPartial'}) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if partial already exists
            let page = store.getters.getPageById(id)

            // If partial already exists, resolve with the partial value
            if(typeof page !== 'undefined') {
                resolve(page)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If page doesn't exist, load page
            } else {
                fetchApi(queries[query](id))

                    .then(r => {

                        if ( !r || !r.entry) {
                            reject({
                                code: 200,
                                message: 'Craft loadPartial() failed.'
                            })
                            store.dispatch('loader/endLoad', null, {root: true});

                        } else {

                            let entry = r.entry
                            entry.singleFetch = false

                            if(!store.getters.getPageBySlug(entry.slug, entry.typeHandle)) {
                                store.commit('addPage', {data: entry, index: -1})
                            }

                            resolve(entry)
                            store.dispatch('loader/endLoad', null, {root: true});
                        }
                    })

            }
        })
    },
    loadList(store, handle) {

        const query = `${handle}List`

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if partial already exists
            let list = store.getters.getListByHandle(handle)

            // If list already exists, resolve with the list value
            if(typeof list !== 'undefined') {
                resolve(list)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If list doesn't exist, load list
            } else {
                fetchApi(queries[query])

                    .then(r => {

                        if (!r || !r.entries) {
                            reject({
                                code: 200,
                                message: 'Craft loadList() failed.'
                            })
                            store.dispatch('loader/endLoad', null, {root: true});

                        } else {

                            const entries = r.entries
                            entries.forEach((entry, i) => {
                                if(!store.getters.getPageBySlug(entry.slug, entry.typeHandle)) {
                                    entry.singleFetch = false

                                    store.commit('addPage', {data: entry, index: -1})
                                }
                            })

                            store.commit('addList', { handle, entries })

                            resolve(entries)
                            store.dispatch('loader/endLoad', null, {root: true});
                        }
                    })

            }
        })
    },
}

// Mutations
const mutations = {
    addPage(state, { data, index }) {
        if(index > 0) {
            state.pages[index] = data
        } else {
            state.pages.push(data)
        }
    },
    addList(state, { name, data }) {
        state.lists[name] = data
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
