<template>
    <div
        :class="className"
        :style="styleColors"
    >
        <loader />

        <country-detection @update="toggleCountryDetection" />

        <app-header
            @toggleNav="toggleNav"
            :class="{'has-notification': hasNotification}"
        />

        <main class="l-main" ref="main">
            <app-not-found
                v-if="error"
            />
            <router-view v-else-if="hasCurrentPage" :page="currentPage" />
        </main>

        <newsletter
            v-if="currentPageName !== 'home'"
            :grid="true"
        />

        <app-modal />
        <app-footer />
    </div>
</template>

<script>

import AppHeader from 'layout/AppHeader';
import AppModal from 'layout/AppModal';
import AppFooter from 'layout/AppFooter';
import AppNotFound from 'layout/AppNotFound';

import Loader from 'objects/Loader';
import CountryDetection from 'components/CountryDetection';
import Newsletter from 'components/Newsletter';

import { mapState, mapGetters } from 'vuex'

export default {
    name: 'AppBase',
    components: {
        AppHeader,
        AppModal,
        AppFooter,
        AppNotFound,
        CountryDetection,
        Loader,
        Newsletter
    },
    data: () => ({
        navIsOpen: false,
        hasNotification: false
    }),
    computed: {
        ...mapState({
            baseColors: state => state.global.colors,
            error: state => state.global.error,
            currentPage: state => state.global.currentPage,
            currentPageName: state => state.global.currentPageName,
        }),
        ...mapGetters({
            hasCurrentPage: 'global/hasCurrentPage',
        }),
        className() {
            let classname = 'l-wrapper'

            if (this.currentPageName) {
                classname += ` p-${this.currentPageName}`
            }

            // Add nav state
            if (this.navIsOpen) {
                classname += ' nav-is-open'
            }

            return classname
        },
        styleColors() {

            const main = this.currentPage.colorMain || this.baseColors.main,
                  darker = this.currentPage.colorDarker || this.baseColors.darker

            return `--page-color-primary-light: ${main};--page-color-primary: ${darker};`

        }
    },
    methods: {
        toggleNav(isOpen) {
            this.navIsOpen = isOpen
        },
        toggleCountryDetection(hasNotification) {
            this.hasNotification = hasNotification
        },
    }
}

</script>

<style lang="scss">

.l-main {
    padding-top: $header-height;

    > .l-section:first-of-type,
    > div > .l-section:first-of-type {
        margin-top: var(--section-margin-half);
    }
}

</style>
