<template>
    <div
        v-if="url"
        :class="className"
        :style="styles"
        v-reveal.once="reveal ? revealHandle : false"
    >
        <picture
            v-if="srcset !== false"
            class="o-asset__picture"
        >
            <source
                :data-srcset="lazyload ? srcset : false"
                :type="mimeType"
                @load="markAsLoaded"
            >
            <img
                :data-src="lazyload ? url : false"
                :data-sizes="lazyload ? 'auto' : false"
                :src="!lazyload ? url : false"
                :alt="title"
                :width="width"
                :height="height"
                class="o-asset__img"
                :class="lazyload ? 'lazyload' : false"
                loading="lazy"
                @load="markAsLoaded"
            />
        </picture>
        <img
            v-else
            :data-src="lazyload ? url : false"
            :data-sizes="lazyload ? 'auto' : false"
            :src="!lazyload ? url : false"
            :alt="title"
            :width="width"
            :height="height"
            loading="lazy"
            class="o-asset__img"
            :class="lazyload ? 'lazyload' : false"
            @load="markAsLoaded"
        />
    </div>
</template>

<script>

import { round } from 'src/utils'
//import gsap from 'gsap'

import 'lazysizes';

export default {
    name: 'Asset',
    props: {
        url: String,
        title: String,
        width: Number,
        height: Number,
        srcset: String,
        mimeType: false,
        cover: {
            type: Boolean,
            default: false,
        },
        contain: {
            type: Boolean,
            default: false,
        },
        reveal: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        lazyload: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        loading: true,
        isVisible: false,
    }),
    created() {
        this.isVisible = this.visible
    },
    computed: {
        className() {
            let classname = 'o-asset'

            // Portrait - Landscape
            classname += this.ratio > 1 ? ' -landscape' : ' -portrait'

            if(this.cover) {
                classname += ' -cover'
            }

            if(this.contain) {
                classname += ' -contain'
            }

            if(this.loading) {
                classname += ' is-loading'
            }

            return classname
        },
        styles() {
            let styles = `--ratio: ${this.ratio};`

            return styles
        },
        ratio() {
            return round(this.width/this.height, 3)
        }
    },
    methods: {
        markAsLoaded() {
            this.loading = false
            this.$emit('load')
        },
        revealHandle(state) {
            this.isVisible = state.isActive
        },
    },
    watch: {
        isVisible(visible) {
            this.$emit('toggle', visible)

            if(visible) {
                this.$emit('show')
            } else {
                this.$emit('hide')
            }
        },
        visible(visible) {
            this.isVisible = visible
        },
    }
};

</script>

<style lang="scss">

.o-asset {
    transition: opacity .3s ease-in;

    &.-cover,
    &.-contain {
        width: 100%;

        .o-asset__picture {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .o-asset__img {
            height: 100%;
            object-position: 50% 50%;
        }
    }

    &.-cover {
        height: 100%;

        .o-asset__img {
            object-fit: cover;
        }
    }

    &.-contain {
        height: 0;
        padding-top: var(--contain-ratio, var(--ratio));

        .o-asset__img {
            object-fit: contain;
        }
    }

    &.is-loading {
        opacity: 0;
    }

    &.js-reveal {
        overflow: hidden;
        opacity: 0;

        & > * {
            transform: scale(1.2);
            transition: transform var(--a-out-duration) var(--a-out-ease) var(--a-out-delay);
            will-change: transform;
        }

        &.is-visible {
            opacity: 1;

            & > * {
                transform: scale(1);
                transition: transform var(--a-in-duration) var(--a-in-ease) var(--a-in-delay);
            }
        }
    }

    @supports not (object-fit: cover) {

        &.-cover {
            overflow: hidden;

            .o-asset__img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: auto;
                min-width: 100%;
                height: auto;
                min-height: 100%;
                transform: translate(-50%, -50%);
            }
        }
    }

    @supports not (object-fit: contain) {

        &.-contain {

            .o-asset__picture {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .o-asset__img {
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 100%;
            }
        }
    }
}

.o-asset__picture {
    display: block;
    width: 100%;
}

.o-asset__img {
    @include img;
}

</style>
