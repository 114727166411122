<template>
    <div
        v-if="asset"
        :class="className"
        :style="styles"
    >
        <asset v-bind="asset" />
    </div>
</template>

<script>

import Asset from 'objects/Asset'

export default {
    name: 'BlockAsset',
    components: {
        Asset,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        className() {
            let classname = 'b-asset'

            if(this.settings.boxed) {
                classname += ' -boxed'
            }

            return classname
        },
        asset() {
            const asset = this.data.asset
            if(asset.length === 0) {
                return false
            } else {
                return asset[0]
            }
        },
        settings() {
            return this.data.blockSettings[0]
        },
        styles() {
            let styles = `
                --width: ${this.settings.width};
                --align-self: ${this.settings.alignY};
            `

            if(this.settings.alignX === 'left') {
                styles += '--margin-left: 0;'
            } else if(this.settings.alignX === 'right') {
                styles += '--margin-right: 0;'
            }

            return styles
        },
    },
};

</script>

<style lang="scss">

</style>
