import { fetchApi } from 'src/graphql/craft/config'
import queries from 'src/graphql/craft/queries'

//import { linkResolver } from 'src/router';

/**********************************
 *
 *
 *  CRAFT/NAVIGATIONS
 *
 *
 **********************************/

// State
const state = {
    main: [],
    footer: []
}

// Getters
const getters = {
    getMain: state => state.main,
    getFooter: state => state.footer
}

// Actions
const actions = {
    init(store) {

        return new Promise((resolve, reject) => {

            fetchApi(queries.nav)
                .then(data => {

                    if ( !data || !data.globalSet ) {

                        reject(data)

                    } else {

                        // Format nav and add route path to each nav item
                        const mainNav = data.globalSet.mainNav
                        const subNavs = data.globalSet.subNavs
                        const footerNav = data.globalSet.footerNav

                        mainNav.forEach(item => {
                            item.entry = item.entry.length > 0 ? item.entry[0] : false

                            // Assign subnav
                            if(item.subnav) {
                                let sub = subNavs.find(s => s.navHandle === item.subnav)
                                item.subNavHandle = item.subnav
                                item.subnav = sub.columns
                            } else {
                                item.subnav = false
                            }
                        });

                        footerNav.forEach(item => {
                            item.entry = item.entry[0]
                        });

                        const nav = {
                            main: mainNav,
                            footer: footerNav
                        }

                        store.commit('setMain', mainNav);
                        store.commit('setFooter', footerNav);
                        resolve(nav);
                    }


                });
        })
    },
}

// Mutations
const mutations = {
    setMain(state, element) {
        state.main = element
    },
    setFooter(state, element) {
        state.footer = element
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
