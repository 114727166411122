<template>
    <span
        v-if="number"
        class="o-rounded"
    >
        {{ number }}
    </span>
</template>

<script>

export default {
    name: 'Rounded',
    props: {
        number: {
            type: Number | String,
        },
    },
};

</script>

<style lang="scss">

.o-rounded {
    --size: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    font-size: .75em;
    color: $color-light;
    background-color: $color-dark;
    border-radius: 50%;
}

</style>
