import Vue from 'vue'

//import { storeFrontApi } from 'src/graphql/shopify/config';
//import {default as storefrontQueries} from 'src/graphql/shopify/queries';

import mainStore from 'src/store/index'

/**********************************
 *
 *
 *  SHOPIFY/CART
 *
 *
 **********************************/

// State
const state = {
    lines: []
}

// Getters
const getters = {
    isEmpty: state => state.lines.length === 0,
    hasLines: state => state.lines.length > 0,
    getLineByVariantId: state => id => state.lines.find(l => l.variantId === id),
    getLineIndex: state => id => state.lines.findIndex(l => l.variantId === id),
    getLines: state => state.lines,
    getLinesCount: state => state.lines.length,
    getLinesQuantity: state => {
        let sum = 0;
        state.lines.forEach(line => {
            sum += line.quantity;
        });
        return sum;
    },
    getLinesSubtotal: state => {
        let sum = 0;
        state.lines.forEach(line => {
            sum += line.price * line.quantity;
        });
        return sum;
    }
}

// Actions
const actions = {

    loadFromLocalStorage(store) {

        return new Promise((resolve, reject) => {

            const currentSite = mainStore.getters['global/getCurrentSite']

            if ( store.state.lines.length === 0 && localStorage.getItem(currentSite.handle + '_cart') !== null ) {

                try {
                    const cart = JSON.parse(localStorage.getItem(currentSite.handle + '_cart'));

                    store.commit('setCart', cart)
                    resolve(cart)
                } catch(e) {
                    localStorage.removeItem(currentSite.handle + '_cart')
                    reject(e)
                }
            }
        })
    },
    removeFromLocalStorage(store) {

        return new Promise((resolve, reject) => {

            const currentSite = mainStore.getters['global/getCurrentSite']

            try {

                const cart = []
                localStorage.removeItem(currentSite.handle + '_cart')
                store.commit('setCart', cart)
                resolve(cart)
            } catch(e) {
                reject(e)
            }
        })
    },
    add(store, {product, variant, quantity}) {

        return new Promise((resolve, reject) => {

            if (!product || !variant) {
                reject('Error: `product` and `variant` must be provided.')
            }

            // Try to get cartLine that may match the variant.id
            const cartLine = store.getters.getLineByVariantId(variant.id)

            // If line exists, update the quantity
            if ( typeof cartLine !== 'undefined') {
                quantity = quantity + cartLine.quantity
            }

            // Check for quantity min/max
            quantity = quantity < 1 ? 1 : quantity
            quantity = quantity > variant.quantityAvailable ? variant.quantityAvailable : quantity

            // Build the new line object
            const newLine = {
                // Shopify Essential
                variantId: variant.id,
                quantity,
                // Extras
                title: product.title,
                thumb: product.asset[0],
                price: variant.price,
                compareAtPrice: variant.compareAtPrice,
                variant,
                uri: product.uri,
                hasMore: quantity < variant.quantityAvailable,
            }

            store.commit('addCartLine', newLine)

            // If we already have a Shopify checkout, update it too
            if ( mainStore.getters['checkout/getCheckoutId'] ) {

                // If new, add
                if ( typeof cartLine === 'undefined') {

                    store.dispatch('checkout/addLineItem', {variantId: newLine.variantId, quantity}, {root: true})
                        .then(() => {
                            resolve(newLine)
                        })
                        .catch(e => {
                            reject(e)
                        })

                // Else, update
                } else {

                    store.dispatch('checkout/updateLineItem', null, {root: true})
                        .then(() => {
                            resolve(newLine)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }

            } else {
                resolve(newLine)
            }

        })
    },
    increment(store, line) {

        return new Promise((resolve, reject) => {

            if (!line) {
                reject('Error: `line` must be provided.')
            }

            // Try to get cartLine that may match the variant.id
            const cartLine = store.getters.getLineByVariantId(line.variantId)

            // If line exists, override the quantity
            if (typeof cartLine === 'undefined' || !cartLine.hasMore) {
                resolve(false)
                return false
            }

            // Check for quantity min/max
            const quantity = line.quantity + 1 >= line.variant.quantityAvailable ? line.variant.quantityAvailable : line.quantity + 1
            const hasMore = quantity < line.variant.quantityAvailable

            const newLine = {...cartLine, quantity, hasMore}

            store.commit('updateCartLine', newLine)

            // If we already have a Shopify checkout, update it too
            if ( mainStore.getters['checkout/getCheckoutId'] ) {

                store.dispatch('checkout/updateLineItem', null, {root: true})
                    .then(() => {
                        resolve(newLine)
                    })
                    .catch(e => {
                        reject(e)
                    })

            } else {
                resolve(newLine)
            }

        })

    },
    decrement(store, line) {

        return new Promise((resolve, reject) => {

            if (!line) {
                reject('Error: `line` must be provided.')
            }

            // Try to get cartLine that may match the variant.id
            const cartLine = store.getters.getLineByVariantId(line.variantId)

            // If line exists, override the quantity
            if (typeof cartLine === 'undefined') {
                resolve(false)
                return false
            }

            const quantity = line.quantity - 1

            // Remove if quantity is 0 {
            if (quantity === 0) {
                store.dispatch('remove', cartLine)
                resolve(false)
                return
            }

            const hasMore = quantity < line.variant.quantityAvailable
            const newLine = {...cartLine, quantity, hasMore}

            store.commit('updateCartLine', newLine)

            // If we already have a Shopify checkout, update it too
            if ( mainStore.getters['checkout/getCheckoutId'] ) {

                store.dispatch('checkout/updateLineItem', null, {root: true})
                    .then(() => {
                        resolve(newLine)
                    })
                    .catch(e => {
                        reject(e)
                    })

            } else {
                resolve(newLine)
            }

        })

    },
    remove(store, lines) {

        return new Promise((resolve, reject) => {

            if (!lines)
                reject('Error: `lines` must be provided.')

            // Convert to array
            if ( !Array.isArray(lines) )
                lines = [lines]

            // Get a clean array from handles
            let ids = []
            lines.forEach(line => {
                ids.push(line.variantId)
            })

            store.commit('removeCartLines', lines)

            // If we already have a Shopify checkout, update it too
            if ( mainStore.getters['checkout/getCheckoutId'] ) {

                store.dispatch('checkout/removeLineItems', ids, {root: true})
                    .then(() => {
                        resolve(lines)
                    })
                    .catch(e => {
                        reject(e)
                    })

            } else {
                resolve(lines)
            }

            //resolve(lines)
        })
    },
}

// Mutations
const mutations = {
    addCartLine(state, line) {

        const idx = state.lines.findIndex(l => l.variantId === line.variantId)

        // Update the store
        if (idx !== -1) {
            Vue.set(state.lines, idx, line)
        } else {
            state.lines.push(line)
        }

        // Update the localStorage
        const currentSite = mainStore.getters['global/getCurrentSite']
        let parsed = JSON.stringify(state.lines)
        localStorage.setItem(currentSite.handle + '_cart', parsed)
    },
    // TODO
    updateCartLine(state, line) {

        const idx = state.lines.findIndex(l => l.variantId === line.variantId)

        // Update the store
        if (idx !== -1) {
            Vue.set(state.lines, idx, line)
        }

        // Update the localStorage
        const currentSite = mainStore.getters['global/getCurrentSite']
        let parsed = JSON.stringify(state.lines)
        localStorage.setItem(currentSite.handle + '_cart', parsed)
    },
    removeCartLines(state, lines) {

        lines.forEach(line => {

            const idx = state.lines.findIndex(l => l.variantId === line.variantId)

            // Remove the line
            if (idx !== -1) {
                state.lines.splice(idx, 1);
            }
        })

        // Update the localStorage
        const currentSite = mainStore.getters['global/getCurrentSite']
        let parsed = JSON.stringify(state.lines)
        localStorage.setItem(currentSite.handle + '_cart', parsed)
    },
    setCart(state, cart) {

        // Update the store, but no need to update de localStorage since this mutation is fired because of it.
        state.lines = cart
    }
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
