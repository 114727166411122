<template>
    <div>
        <app-section
            :title="page.mainTitle"
            titleClass="t-t2"
            :breadcrumb="true"
        >

            <div class="grid__row | u-jc-center">
                <div class="grid__col--12 grid__col--10@xs">
                    <div class="p-terms__header">
                        <anim-text
                            tag="h2"
                            :text="page.headline"
                            class="t-t4"
                        />
                        <anim-text
                            :cms="true"
                            :text="page.body"
                        />
                    </div>
                    <list
                        class="p-terms__list"
                        :items="page.list"
                        modifier="terms"
                    >
                        <template #left="item">
                            <h3 class="t-t4">
                                {{ item.label }}
                            </h3>
                        </template>
                        <template #right="item">
                            <div
                                class="t-cms"
                                v-html="item.body"
                            ></div>
                        </template>
                    </list>
                </div>
            </div>
        </app-section>
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import AnimText from 'objects/AnimText';
import List from 'components/List';

export default {
    name: 'Terms',
    components: {
        AppSection,
        AnimText,
        List,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
};

</script>

<style lang="scss">

.p-terms {}

.p-terms__header {
    display: grid;
    grid-row-gap: 2em;
    grid-column-gap: var(--grid-gutter);
    margin-top: var(--section-margin);
    margin-bottom: var(--section-margin);


    @media #{md("sm")} {
        grid-template-columns: repeat(2, 1fr);
    }
}


</style>
