<template>
    <div
        class="c-accordion"
        :class="{
            'is-open': isOpen,
            'c-accordion--upside-down': upsideDown
        }"
    >
        <header
            class="c-accordion__header"
            @click="isOpen ? close() : open()"
        >
            <slot name="title">
                <h3
                    v-if="title"
                    class="c-accordion__title || t-t4"
                >
                    {{ title }}
                </h3>
            </slot>
            <button
                class="c-accordion__btn"
                :aria-label="$t('aria.toggle')"
            >
                <icon icon="plus" />
            </button>
        </header>
        <div
            ref="container"
            class="c-accordion__container"
            :class="{ 'has-scroll' : hasScroll }"
        >
            <div ref="inner" class="c-accordion__content">
                <slot>
                    <div
                        class="t-cms"
                        v-html="content"
                    />
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from 'objects/Icon';

import gsap from 'gsap';

export default {
    name: 'Accordion',
    components: {
        Icon,
    },
    data: () => ({
        isOpen: false,
        isActive: true,
        hasScroll: false,
        opts: {
            scroll: false,
            watchCSS: false,
            maxHeight: false,
            open: false
        }
    }),
    props: {
        title: {
            type: String,
            default: null,
        },
        triggerLabel: {
            type: String,
            default: null,
        },
        content: {
            type: String,
            default: null,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        upsideDown: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.opts = Object.assign(this.opts, this.options)
    },
    mounted() {

        if(this.opts.open) {
            this.open()
        }

        // Window event
        window.addEventListener('resizeEnd', this.watchCSS);
    },
    methods: {
        open() {
            if (!this.isActive) {
                return;
            }

            this.isOpen = true;

            const height = this.$refs.inner.offsetHeight;

            gsap.to(this.$refs.container, {
                duration: 1,
                height: this.opts.maxHeight && height > this.opts.maxHeight ? this.opts.maxHeight : height,
                opacity: 1,
                onStart: () => {
                    if (height === this.opts.maxHeight) {
                        this.hasScroll = true;
                    }
                },
                onComplete: () => {
                    gsap.set(this.$refs.container, {
                        height: 'auto'
                    });
                },
                ease: 'expo.out',
            });

            if (this.opts.scroll) {
                const scrollY = this.$refs.container.getBoundingClientRect().top + window.scrollY;
                this.animScroll(scrollY);
            }
        },
        close() {
            if (!this.isActive) {
                return;
            }

            this.isOpen = false;

            gsap.to(this.$refs.container, {
                duration: .6,
                height: 0,
                opacity: 0,
                onComplete: () => {
                    gsap.set(this.$refs.container, {
                        height: 0
                    });
                    this.hasScroll = false;
                },
                ease: 'expo.out',
            });
        },
        toggle() {
            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        },
        animScroll(y) {
            gsap.to(window, {
                duration: 1,
                scrollTo: {
                    y: y,
                    autoKill: false,
                },
                ease: 'expo.inOut',
            });
        },

        watchCSS() {
            if (!this.opts.watchCSS) return;

            const afterContent = getComputedStyle(this.$el, ':after').content;

            if (afterContent.indexOf('accordion') !== -1) {
                this.isActive = true;
            } else {
                this.isActive = false;
                this.$refs.container.removeAttribute('style');
            }
        },
    },
    watch: {
        isOpen(open) {
            if(open) {
                this.open()
            } else {
                this.close()
            }
        }
    },
    beforeDestroy() {
        // Remove Window event
        window.removeEventListener('resizeEnd', this.watchCSS);
    },
};
</script>

<style lang="scss">

.c-accordion {

    // Code to add to enable accordion if watchCSS is enabled
    // &:after {
    //     content: "accordion";
    //     display: none;
    // }

    &.is-open {

        .c-accordion__content {
            pointer-events: initial;
        }

        .c-accordion__btn {
            color: $color-light;
            background-color: $color-dark;
            transform: rotate(45deg);
        }
    }

    &--upside-down {
        display: flex;
        flex-direction: column;

        .c-accordion__header {
            order: 2
        }

        .c-accordion__container {
            order: 1
        }
    }
}


.c-accordion__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1em;
    padding-bottom: 2em;
    user-select: none;
    cursor: pointer;
    border-top: $border-secondary-light;

    &:hover,
    .is-open & {
    }

    &:hover {
    }

    &:hover,
    .is-open &:hover {
    }

    .is-open & {
    }
}

.c-accordion__title {
    width: 100%;
}


.c-accordion__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 2em;
    border-radius: 50%;
    background-color: $color-primary;
    transition: color .3s ease-out, background-color .3s ease-out, transform .3s $out-quad;
}

.c-accordion__container {
    height: 0;
    opacity: 0;
    overflow: hidden;

    &.has-scroll {

        &:after {
            @include pseudo-el($height: 4em);
            position: absolute;
            bottom: 0;
            left: 0;
            @include linear-gradient(to top, $color-light, $color-light 1.5em, rgba(255, 255, 255, 0) 100%);
            pointer-events: none;
        }

        .c-accordion__content {
            padding-bottom: 3em;
        }
    }
}


.c-accordion__content {
    // max-height: 400px;
    padding-bottom: 1em;
    pointer-events: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

</style>
