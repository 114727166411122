import Vue from 'vue';
import VueRouter from 'vue-router'
import store from 'src/store'

// Views
import * as Components from './templates/views'

// Use plugin
Vue.use(VueRouter)

// Variables
const initialData = window.__initialData__
const baseUrl = store.state.global.site.baseUrl
// const currentSiteHandle = initialData.currentSite.handle

// Dynamic Routes from Craft
const dynamicRoutes = initialData.routes.map(r => ({ ...r, component: Components[r.component] }));

// Create aliases
const shopIdx = dynamicRoutes.findIndex(route => route.name === 'shop')
dynamicRoutes[shopIdx].alias = '/shop/products'

// Static routes
const staticRoutes = [
    {
        name: 'cart',
        path: '/cart',
        component: Components.Cart,
    },
    {
        name: 'Error404',
        path: '*',
        redirect: '/404'
    },
]

// Merge dynamic and static routes
const routes = [...dynamicRoutes, ...staticRoutes]

// Create the router with the routes
const router = new VueRouter({
    mode: 'history',
    base: `${baseUrl}`,
    routes,
    linkActiveClass: 'is-current',
})

// Before each route load
router.beforeEach((to, from, next) => {

    store.dispatch('global/error', false)

    store
        .dispatch('global/loadContent', to)
        .then(next)
        .catch(error => {

            if (error.code === 404) {
                store.dispatch('global/error')
                next()
            }
        })
})

// Link resolver
const linkResolver = (section) => {

    const route = routes.find(route => route.name === section)
    if (typeof route !== 'undefined' && section !== 'home') {
        return route.path
    } else {
        return '/';
    }
}

Vue.filter('resolve', (section) => {
  return linkResolver(section)
})

// Export
export default router
export { router, routes, linkResolver }
