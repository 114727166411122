<template>
    <div
        v-if="body"
        :class="className"
        :style="styles"
    >
        <anim-text
            :cms="true"
            :text="body"
            class="b-text__content"
        />
    </div>
</template>

<script>

import AnimText from 'objects/AnimText';

export default {
    name: 'BlockText',
    components: {
        AnimText,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        className() {
            let classname = 'b-text'

            if(this.settings.boxed) {
                classname += ' -boxed'
            }

            return classname
        },
        body() {
            const body = this.data.body
            return body ? body : false
        },
        settings() {
            return this.data.blockSettings[0]
        },
        styles() {
            let styles = `
                --width: ${this.settings.width};
                --align-self: ${this.settings.alignY};
            `

            if(this.settings.alignX === 'left') {
                styles += '--margin-left: 0;'
            } else if(this.settings.alignX === 'right') {
                styles += '--margin-right: 0;'
            }

            return styles
        },
    },
};

</script>

<style lang="scss">

</style>
