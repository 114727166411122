<template>
    <!-- Root element of PhotoSwipe. Must have class pswp. -->
    <div class="c-pswp | pswp" tabindex="-1" role="dialog" aria-hidden="true">

        <!-- Background of PhotoSwipe.
             It's a separate element as animating opacity is faster than rgba(). -->
        <div class="pswp__bg"></div>

        <!-- Slides wrapper with overflow:hidden. -->
        <div class="pswp__scroll-wrap">

            <!-- Container that holds slides.
                PhotoSwipe keeps only 3 of them in the DOM to save memory.
                Don't modify these 3 pswp__item elements, data is added later on. -->
            <div class="pswp__container">
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
            </div>

            <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
            <div class="pswp__ui pswp__ui--hidden">

                <div class="pswp__top-bar">

                    <!--  Controls are self-explanatory. Order can be changed. -->

                    <div class="pswp__counter | t-small"></div>

                    <button class="pswp__button pswp__button--close" title="Close (Esc)">
                        <icon icon="cross" />
                    </button>

                    <button class="pswp__button pswp__button--share" title="Share"></button>

                    <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

                    <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

                    <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                    <!-- element will get class pswp__preloader--active when preloader is running -->
                    <div class="pswp__preloader">
                        <div class="pswp__preloader__icn">
                          <div class="pswp__preloader__cut">
                            <div class="pswp__preloader__donut"></div>
                          </div>
                        </div>
                    </div>
                </div>

                <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                    <div class="pswp__share-tooltip"></div>
                </div>

                <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
                    <icon icon="arrow-long-left" />
                </button>

                <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
                    <icon icon="arrow-long-right" />
                </button>

                <div class="pswp__caption">
                    <div class="pswp__caption__center"></div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>


import Icon from 'objects/Icon';

import PhotoSwipe from 'photoswipe/dist/photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export default {
    name: 'PSWP',
    components: {
        Icon,
    },
    props: {
        items: {
            type: Array,
            default: null,
        },
        options: {
            type: Object,
            default: () => ({
                shareEl: false,
                fullscreenEl: false,
                zoomEl: false,
                counterEl: true,
            }),
        },
    },
    data: () => ({
        pswp: null
    }),
    methods: {
        open(index=0) {
            const options = {...this.options, index}
            // Initialize galler
            this.pswp = new PhotoSwipe(this.$el, PhotoSwipeUI_Default, this.items, options);

            this.pswp.init()
        },
        close() {
            this.pswp.close()
        },
    },
}

</script>

<style lang="scss">

@import "photoswipe/dist/photoswipe";
//@import "photoswipe/dist/default-skin/default-skin";

.c-pswp {

    .pswp__bg {
        background-color: rgba($color-grey, .8);
    }

    .pswp__button {
        --size: 5.5em;

        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .o-icon {
            transition: transform .2s ease-out;
        }

        &--close {
            top: 0;
            right: 0;
            width: var(--size);
            height: var(--size);
            color: $color-light;
            background-color: $color-dark;

            .o-icon {
                margin-top: calc(-.2 * var(--size));
                margin-right: calc(-.2 * var(--size));

                pointer-events: none;
            }

            &:hover {

                .o-icon {
                    transform: scale(1.1);
                }
            }

            @supports (clip-path: circle(100% at 100% 0)) {
                clip-path: circle(100% at 100% 0);
            }

            @supports not (clip-path: circle(100% at 100% 0)) {
                border-bottom-left-radius: 50%;
            }
        }

        &--arrow--left,
        &--arrow--right {
            position: fixed;
            top: calc(50% - var(--size)/2);
            width: calc(var(--size)/2);
            height: var(--size);
            background-color: $color-primary;

            .o-icon {
                pointer-events: none;
            }
        }

        &--arrow--left {
            left: 0;

            &:hover {

                .o-icon {
                    transform: translate(-25%, 0);
                }
            }

            @supports (clip-path: ellipse(100% 50% at 0 50%)) {
                clip-path: ellipse(100% 50% at 0 50%);
            }

            @supports not (clip-path: ellipse(100% 50% at 0 50%)) {
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
            }
        }

        &--arrow--right {
            right: 0;

            &:hover {

                .o-icon {
                    transform: translate(25%, 0);
                }
            }

            @supports (clip-path: ellipse(100% 50% at 100% 50%)) {
                clip-path: ellipse(100% 50% at 100% 50%);
            }

            @supports not (clip-path: ellipse(100% 50% at 0 50%)) {
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
            }
        }
    }

    .pswp__counter {
        position: absolute;
        top: 0;
        left: 1rem;
        display: flex;
        align-items: center;
        height: 44px;
    }
}

</style>
