<template>
    <app-section
        v-if="slides"
        :title="data.mainTitle"
        tag="div"
        titleTag="h2"
        class="b-slider"
    >
        <slider
            ref="slider"
            modifier="-alternate"
        >
            <div
                v-for="(slide, i) in slides"
                :key="`blockslider-slide-${i}`"
                class="o-slide"
            >
                <asset
                    v-if="slide.asset.length > 0"
                    :url="slide.asset[0].url"
                    :title="slide.asset[0].title"
                    :width="slide.asset[0].width"
                    :height="slide.asset[0].height"
                    @load="$refs.slider.resize()"
                />
                <span
                    v-else
                    class="o-slide__bg"
                ></span>
                <div
                    v-if="slide.headline || slide.desc"
                    class="o-slide__content | t-content"
                >
                    <h3
                        v-if="slide.headline"
                        class="o-slide__title"
                        :class="slide.asset.length > 0 ? 't-t3' : 't-t1'"
                    >
                        {{ slide.headline }}
                    </h3>
                    <p
                        v-if="slide.desc"
                        class="o-slide__desc | t-small"
                    >
                        {{ slide.desc }}
                    </p>
                </div>
            </div>
        </slider>
    </app-section>
</template>

<script>

import AppSection from 'layout/AppSection'
import Asset from 'objects/Asset'
import Slider from 'components/Slider'

export default {
    name: 'BlockSlider',
    components: {
        AppSection,
        Asset,
        Slider,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        slides() {
            const slides = this.data.slide
            return slides.length > 0 ? slides : false
        },
    },
};

</script>

<style lang="scss">

.b-slider {

    .l-section__header {
        margin-bottom: var(--section-margin-half);
    }
}

</style>
