/*
 *
 * IMPORTANT
 * Not loaded (yet) in /store/index.js
 *
 */

import { fetchApi } from 'src/graphql/craft/config'
import queries from 'src/graphql/craft/queries'
import Vue from 'vue'

/*
** globalSets
*/

// State
const state = {
    sets: {},
}

// Getters
const getters = {
    getSetByHandle: state => handle => state.sets[handle],
}

// Actions
const actions = {
    loadSet(store, handle) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if page already exists
            let set = store.getters.getSetByHandle(handle)

            // If set already exists, resolve with the set value
            if(typeof set !== 'undefined') {
                resolve(set)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If set doesn't exist, load set
            } else {
                //console.log(queries[handle])

                fetchApi(queries[handle])
                    .then(r => {

                        store.commit('addSet', {handle, data: r.globalSet})
                        resolve(r.globalSet)

                        // End load
                        store.dispatch('loader/endLoad', null, {root: true});
                    })
            }

        })
    },
    loadModal(store, handle) {

        return new Promise((resolve, reject) => {

            const handle = 'modal'

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            store.dispatch('loadSet', 'modalSet')
                .then(r => {
                    let selectedModal = r.selectedModal


                    if(selectedModal.length === 0) {
                        store.commit('addSet', {handle, data: null})
                        resolve(null)

                        store.dispatch('loader/endLoad', null, {root: true});
                    } else {
                        fetchApi(queries.modal(selectedModal[0].id))
                            .then(r => {

                                store.commit('addSet', {handle, data: r.entry})
                                resolve(r.entry)

                                // End load
                                store.dispatch('loader/endLoad', null, {root: true});
                            })
                    }
                })
        })
    },
}

// Mutations
const mutations = {
    addSet(state, {handle, data}) {
        Vue.set(state.sets, handle, data)
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
