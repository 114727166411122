<template>
    <header :class="className">
        <div
            class="l-header__bg"
            :class="{ 'is-active' : subnavActive > -1 }"
            @click="closeAll"
        ></div>
        <div class="l-header__inner | grid">
            <button
                class="l-header__burger | o-burger | u-as"
                @click="toggleNav()"
                :aria-label="$t('aria.toggleNav')"
            >
                <span class="o-burger__line"></span>
                <span class="o-burger__line"></span>
                <span class="o-burger__line"></span>
            </button>
            <router-link
                to="/"
                title="Homepage"
                class="l-header__logo"
            >
                <icon icon="logo" />
            </router-link>
            <button
                class="l-header__search-btn | u-as"
                :class="{ 'is-active' : searchIsOpen }"
                @click="toggleSearch()"
                :aria-label="$t('aria.toggleSearch')"
            >
                <icon
                    v-if="searchIsOpen"
                    icon="cross"
                />
                <icon
                    v-else
                    icon="search"
                />
            </button>

            <search
                class="l-header__search"
                ref="search"
                @toggle="state => searchIsOpen = state"
            />

            <button
                class="l-header__cart-btn | u-as"
                :class="{ 'is-active' : miniCartIsOpen }"
                @click="toggleCart()"
                :aria-label="$t('aria.toggleCart')"
            >
                <icon
                    v-if="miniCartIsOpen"
                    icon="cross"
                />
                <template v-else>
                    <icon icon="cart" />
                    <rounded
                        class="l-header__cart-qty"
                        :number="cartLinesQuantity"
                    />
                </template>
            </button>

            <mini-cart
                class="l-header__cart"
                ref="cart"
                @toggle="state => miniCartIsOpen = state"
            />

            <nav
                class="l-nav"
                itemscope
                itemtype="http://schema.org/SiteNavigationElement"
            >
                <button
                    class="l-nav__return"
                    :class="{ 'is-visible' : subnavActive > -1 }"
                    @click="updateSubnav(-1)"
                    :aria-label="$t('aria.closeSubnav')"
                >
                    <icon icon="arrow-left" />
                </button>
                <ul class="l-nav__list">
                    <li
                        v-for="(node, i) in mainNav"
                        :key="`nav-node-${i}`"
                        class="l-nav__item"
                        :class="[
                            { 'is-active' : subnavActive === i },
                            { '-subnav' : node.subnav },
                        ]"
                        itemprop="name"
                    >
                        <template v-if="node.subnav">
                            <router-link
                                :to="`/${node.entry.uri}`"
                                class="l-nav__link"
                                event
                                @click.native.prevent="updateSubnav(i)"
                            >
                                <btn
                                    tag="span"
                                    :label="node.label"
                                    :reveal="false"
                                />
                                <icon
                                    icon="arrow-right"
                                    class="l-nav__arrow"
                                />
                            </router-link>
                            <div class="l-subnav">
                                <div
                                    v-for="(subnav, j) in node.subnav"
                                    :key="`nav-node-${i}-subnav-${j}`"
                                    class="l-subnav__item"
                                >
                                    <h3
                                        v-if="subnav.sub"
                                        class="t-small t-uppercase"
                                    >
                                        {{ subnav.sub }}
                                    </h3>
                                    <ul
                                        v-if="subnav.nodes.length > 1"
                                        class="l-subnav__list"
                                    >
                                        <li v-if="subnav.typeHandle == 'listCatalogs' && subnav.nodes.length > 1">
                                            <btn
                                                tag="router-link"
                                                :href="'shop'|resolve"
                                                :label="$t('btn.shopAll')"
                                                iconAfter="arrow-long-right"
                                                bg="none"
                                                :reveal="false"
                                            />
                                        </li>
                                        <li v-if="subnav.typeHandle == 'listCollections' && subnav.nodes.length > 1">
                                            <btn
                                                tag="router-link"
                                                :href="'collections'|resolve"
                                                :label="$t('btn.viewAll')"
                                                iconAfter="arrow-long-right"
                                                bg="none"
                                                :reveal="false"
                                            />
                                        </li>
                                        <li
                                            v-for="(item, k) in subnav.nodes"
                                            :key="`nav-node-${i}-subnav-${j}-item-${k}`"
                                        >
                                            <btn
                                                tag="router-link"
                                                :href="`/${item.uri}`"
                                                :label="item.title"
                                                bg="none"
                                                :reveal="false"
                                            />
                                        </li>
                                    </ul>
                                    <span
                                        v-else-if="subnav.nodes.length === 1"
                                        class="l-subnav__thumb"
                                    >
                                        <btn
                                            tag="router-link"
                                            :href="`/${subnav.nodes[0].uri}`"
                                            :title="subnav.nodes[0].title"
                                            :label="subnav.nodes[0].title"
                                            iconAfter="arrow-long-right"
                                            bg="none"
                                            :reveal="false"
                                        />
                                        <router-link
                                            :to="`/${subnav.nodes[0].uri}`"
                                            :title="subnav.nodes[0].title"
                                            class="l-subnav__thumb-img"
                                            :class="[
                                                {'-sale': saleCatalogs.includes(subnav.nodes[0].id)},
                                                {'-black-friday': blackFridayCatalogs.includes(subnav.nodes[0].id)},
                                            ]"
                                        >
                                            <asset
                                                v-if="subnav.nodes[0].asset"
                                                v-bind="subnav.nodes[0].asset[0]"
                                                :reveal="false"
                                                :lazyload="false"
                                            />
                                        </router-link>
                                    </span>
                                </div>
                                <!-- Tricky fix -->
                                <span class="l-subnav__item -all" v-if="node.subNavHandle == 'collections'">
                                    <btn
                                        tag="router-link"
                                        :href="'collections'|resolve"
                                        :label="$t('btn.viewAll')"
                                        iconAfter="arrow-long-right"
                                        bg="none"
                                        :reveal="false"
                                    />
                                </span>
                            </div>
                        </template>
                        <template v-else>
                            <router-link
                                :to="`/${node.entry.uri}`"
                                :title="node.entry.title"
                                class="l-nav__link"
                                exact
                                itemprop="url"
                            >
                                <btn
                                    tag="span"
                                    :label="node.label ? node.label : node.entry.title"
                                    :reveal="false"
                                />
                            </router-link>
                        </template>
                    </li>
                </ul>
                <ul class="l-header__currencies-list">
                    <li
                        v-for="currency in currencies"
                        :key="`header-currency-${currency.handle}`"
                    >
                        <button
                            :title="currency.label"
                            @click="updateCurrency(currency)"
                        >
                            {{ currency.label }}
                        </button>
                    </li>
                </ul>
            </nav>
            <app-select
                :options="currencies"
                :defaultValue="currency.label"
                class="l-header__currencies-select"
                label="label"
                @input="updateCurrency"
            />
        </div>
    </header>
</template>

<script>

import Icon from 'objects/Icon';
import Asset from 'objects/Asset';
import Rounded from 'objects/Rounded';
import AppSelect from 'objects/AppSelect';
import Btn from 'components/Btn';
import MiniCart from 'components/MiniCart';
import Search from 'components/Search';

import { mapGetters } from 'vuex'

export default {
    name: 'AppHeader',
    components: {
        Icon,
        Asset,
        Rounded,
        AppSelect,
        Btn,
        MiniCart,
        Search,
    },
    data: () => ({
        isSticky: false,
        navIsOpen: false,
        searchIsOpen: false,
        miniCartIsOpen: false,
        cartQuantity: 0,
        subnavActive: -1
    }),
    mounted() {

        // Window event
        window.addEventListener('scroll', this.scroll)
    },
    computed: {
        ...mapGetters({
            cartLinesQuantity: 'cart/getLinesQuantity',
            mainNav: 'navigations/getMain',
            pageId: ['global/getCurrentPageId'],
            getSite: 'global/getCurrentSite',
            getSites: 'global/getSites',
            isLoading: 'loader/isLoading',
        }),

        currency() {
            return this.getSite
        },
        currencies() {
            return this.getSites
        },
        className() {
            let classname = 'l-header'

            if (this.isSticky) {
                classname += ' is-sticky'
            }

            if(this.isLoading) {
                classname += ' is-loading'
            }

            return classname
        },
        blackFridayCatalogs() {
            return [
                '43841',
                '45058',
                '45061'
            ]
        },
        saleCatalogs() {
            return [
                '297'
            ]
        }
    },
    methods: {
        openNav() {
            if(this.navIsOpen) {
                return
            }

            this.$refs.search.close()
            this.$refs.cart.close()

            this.updateSubnav(-1)

            this.navIsOpen = true

            this.currentScroll = window.scrollY
            document.documentElement.style.overflowY = 'hidden';
        },
        closeNav() {
            if(!this.navIsOpen) {
                return
            }

            this.navIsOpen = false

            // Add previous scroll
            document.documentElement.style.overflowY = 'scroll';
            window.scrollTo(0, this.currentScroll)
        },
        toggleNav() {
            //alert(`AppHeader:toggleNav ${this.navIsOpen ? '(close)' : '(open)'}`)
            if(this.navIsOpen) {
                this.closeNav()
            } else {
                this.openNav()
            }
        },
        toggleSearch() {
            this.$refs.search.toggle()
            this.$refs.cart.close()
            this.closeNav()
            this.subnavActive = -1
        },
        toggleCart() {
            this.$refs.cart.toggle()
            this.$refs.search.close()
            this.closeNav()
            this.subnavActive = -1
        },
        updateSubnav(index) {

            if(this.searchIsOpen) {
                this.$refs.search.close()
            }

            if(this.miniCartIsOpen) {
                this.$refs.cart.close()
            }

            this.subnavActive = this.subnavActive === index ? -1 : index
        },
        updateCurrency(c) {
            // Return if same than current currency
            if(typeof c.id === 'undefined' || c.id === this.currency.id) {
                return false
            }

            // Load alternative url
            this.$store.dispatch('global/fetchPageUrl', { pageID: this.pageId, siteID: c.id})
                .then(url => {
                    // Load page
                    window.location.href = url
                })

        },
        closeAll() {
            if(this.navIsOpen) {
                this.closeNav()
            }

            if(this.searchIsOpen) {
                this.$refs.search.close()
            }

            if(this.miniCartIsOpen) {
                this.$refs.cart.close()
            }

            this.subnavActive = -1
        },
        scroll() {
            this.isSticky = window.scrollY > 0
        },
    },
    watch: {
        navIsOpen(isOpen) {
            this.$emit('toggleNav', isOpen)
        },
        $route() {
            this.closeAll()
        },
        isLoading(loading) {
            if(loading) {
                setTimeout(() => {
                    window.scrollTo(0, 0)
                }, 200);
            }
        },
    }
};

</script>

<style lang="scss">


/*==============================
=            Header            =
==============================*/


.l-header {
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    &:before {
        @include pseudo-el($bg: $color-primary);
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(0, calc(-100% + #{$border-width-thick}));
        transition: transform .4s $out-quint, background-color .4s $out-quint;
    }

    &.is-sticky {

        &:before {
            transform: translate(0);
            background-color: $color-primary-light;
        }

        .l-header__logo {
            transform: scale(.85) translate(0, -25%);
        }
    }

    &.is-loading {

        .l-header__inner {

            &:before {
                opacity: 1;
                transition: opacity .4s ease-out;
            }

            &:after {
                z-index: 5;
                pointer-events: initial;
                opacity: 1;
                transition: opacity .3s ease-in;
            }
        }
    }

//     &.has-notification {
//
//         top: 4rem;
//
//         &.is-sticky {
//             top: 4.5rem;
//         }
//         @media #{md("md", "max")} {
//
//             top: 0;
//
//             &.is-sticky {
//                 top: 0;
//             }
//         }
//     }

    @media #{md("sm")} {

        &.is-sticky {

            .l-header__logo {
                transform: translate(0, .7em);
            }

            .l-header__inner {
                transform: translate(0, -.75em);
            }

            &:not(:hover) {

                &:before {
                    transform: translate(0, -1.5em);
                }

                .l-header__inner {
                    transform: translate(0, -1.5em);
                }

                .l-header__currencies-select {
                    transform: translate(0, -.5em);
                }
            }

            &:hover {

                .l-header__search,
                .l-header__cart {
                    transform: translate(0, .5em);
                }
            }
        }
    }

    @media #{md("md")} {

        &.is-sticky {

            .l-header__logo {
                transform: scale(.7) translate(0, .1em);
            }
        }
    }
}

.l-header__bg {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;

    @media #{md("sm")} {

        &.is-active {
            display: block;
        }
    }
}

.l-header__inner {
    display: flex;
    align-items: center;
    height: $header-height;
    transition: transform .4s $out-quint;

    &:before {
        @include pseudo-el($width: 100vw, $height: $border-width-thick, $bg: $color-primary);
        z-index: 6;
        position: absolute;
        top: 0;
        left: calc(50% - 50vw);
        transform: translate(-100%, 0);
        opacity: 0;
        transition: opacity .2s ease-out .6s;
        animation: anim-loading-line 2s ease-in-out infinite;
    }

    &:after {
        @include pseudo-el($width: 100vw, $height: 100vh, $bg: $color-light);
        z-index: -1;
        position: absolute;
        top: 0;
        left: calc(50% - 50vw);
        pointer-events: none;
        opacity: 0;
        transition: z-index 0s 1s, opacity .6s ease-out .4s;
    }

    @media #{md("sm")} {
        z-index: 1;
    }
}

.l-header__logo {
    z-index: 10;
    left: 2.5%;
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
    transform-origin: 50% 100%;
    transition: transform .4s $out-quad;

    @media #{md("xs", "max")} {
        font-size: .8rem;
    }

    @media (min-width: 350px) {
        left: $accessible-size/2;
    }

    @media #{md("sm")} {
        left: auto;
        transform: translate(0, .7em);
    }

    @media #{md("md")} {
        font-size: 2rem;
        transform: translate(0);
    }
}

.l-header__cart,
.l-header__search {
    z-index: 100;
    position: absolute;
    top: -100vh;
    width: 100%;
    transition: top 0s .4s, transform .4s $out-quint;

    &.is-open {
        top: 100%;
        transition: top 0s 0s, transform .4s $out-quint;
    }
}

.l-header__search {
    left: 0;

    @media #{md("sm")} {
        left: var(--grid-gutter-half);
    }
}


.l-header__cart {
    right: 0;

    @media #{md("sm")} {
        right: var(--grid-gutter-half);
        max-width: 26em;
    }
}

.l-header__cart-btn,
.l-header__search-btn {

    .o-icon {
        position: static;

        &:before {
            @include pseudo-el($width: 80%, $height: 80%, $bg: $color-light);
            position: absolute;
            top: 10%;
            left: 10%;
            border-radius: 50%;
            transition: background-color .2s ease-out;
        }
    }

    &.is-active {
        color: $color-light;

        .o-icon:before {
            background-color: $color-dark;
        }
    }

    &:hover {

        .o-icon:before {
            background-color: $color-primary;
        }
    }

    @media #{md("sm")} {
        top: $accessible-size/4;
    }
}

.l-header__search-btn {

    @media #{md("sm")} {
        order: -1;
    }
}

.l-header__cart-qty {
    position: absolute;
    right: calc(var(--size)/3);
    bottom: calc(var(--size)/3);
}

.l-header__currencies-list {
    @include reset-list;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: var(--grid-gutter-half);
    opacity: 0;

    .nav-is-open & {
        opacity: 1;
        transition: opacity .3s ease-out .4s;
    }

    button {
        cursor: pointer;
        font-size: .75em;
    }

    @media #{md("sm")} {
        display: none;
        visibility: hidden;
    }
}

.l-header__currencies-select {
    z-index: 4;
    position: absolute;
    top: $border-width-thick;
    right: var(--grid-gutter);
    font-size: .75em;
    transition: transform .4s $out-quad;

    .vs__dropdown-menu,
    &.vs--open .vs__dropdown-toggle {
        background-color: $color-light;
    }

    .vs__dropdown-toggle {
        border: 0;
    }

    @media #{md("sm", "max")} {
        display: none;
        visibility: hidden;
    }
}


/*==================================
=            Navigation            =
==================================*/

.l-nav,
.l-nav__list,
.l-nav__item {
    position: static;
}

.l-nav {

    @media #{md("sm", "max")} {
        position: fixed;
        top: $header-height;
        bottom: 0;
        left: -200%;
        width: 100%;
        height: auto;
        padding-top: .5em;
        padding-right: var(--grid-gutter-half);
        padding-left: var(--grid-gutter-half);
        transition: left 0s .6s;

        &:before {
            @include pseudo-el($bg: $color-light);
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(1, 0);
            transform-origin: 50% 0;
            transition: transform .4s $out-quint;
        }

        .nav-is-open & {
            left: 0;
            transition: left 0s 0s;

            &:before {
                transform: scale(1);
                transition: transform .8s $out-expo;
            }

            .l-nav__list {
                opacity: 1;
                transition: opacity .3s ease-out .3s;
            }
        }
    }

    @media #{md("sm")} {
        position: absolute;
        top: 35%;
        right: calc(var(--grid-gutter) + #{$accessible-size});
        left: calc(var(--grid-gutter) + #{$accessible-size});
        display: flex;
        width: auto;
    }
}

.l-nav__list {
    @include reset-list;

    display: flex;
    width: 100%;

    @media #{md("sm", "max")} {
        flex-direction: column;
        opacity: 0;
    }
}

.l-nav__item {

    &.-subnav {

        .l-nav__link {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            user-select: none;
        }
    }

    &.is-active {

        .l-nav__arrow {
            opacity: 0;
        }

        .l-subnav {
            left: 50%;

            &:before {
                transform: scale(1);
            }
        }

        .l-subnav__item {
            opacity: 1;
        }
    }

    @media #{md("sm", "max")} {
        width: 100%;

        &:not(:last-child) {

            .l-nav__link {
                border-bottom: $border-secondary-light;
            }
        }

        @for $i from 2 through 4 {

            &.-subnav.is-active:nth-child(#{$i}) {

                .l-nav__link {
                    transform: translate(0, calc(#{$i - 1} * -100%));
                }
            }
        }
    }

    @media #{md("sm")} {

        .l-subnav {
            transition: left 0s 0s;

            &:before {
                transition: transform .8s $out-expo;
            }
        }

        .l-subnav__item {
            transition: opacity .3s ease-out .3s;
        }

        &:nth-child(2) {
            margin-left: 1em;
        }

        &:nth-child(3) {
            margin-right: 1em;
            margin-left: auto;
        }
    }

    @media #{md("md")} {

        &:nth-child(2) {
            margin-left: 2em;
        }

        &:nth-child(3) {
            margin-right: 2em;
        }
    }
}

.l-nav__link {
    top: .5em;
    display: inline-block;

    .c-btn__border {
        opacity: 0;
    }

    &:hover {}

    @media #{md("sm", "max")} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 1.25em;
        padding-bottom: 1.25em;
        background-color: $color-light;

        .c-btn {
            padding: 0;
        }
    }

    @media #{md("md", "max")} {

        .c-btn {
            padding-right: .2em;
            padding-left: .2em;
        }
    }

    @media #{md("sm")} {

        &.is-current {

            .c-btn__border {
                opacity: 1;
            }
        }
    }
}

.l-nav__arrow {

    @media #{md("sm")} {
        display: none;
    }
}

.l-nav__return {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: $accessible-size;
    height: $accessible-size;
    cursor: pointer;
    transform: scale(0);
    transform-origin: 0 50%;
    transition: transform .15s $in-sine;

    &.is-visible {
        transform: scale(1);
        transition: transform .3s $out-sine;
    }

    @media #{md("sm")} {
        display: none;
    }
}


/*==============================
=            Subnav            =
==============================*/

.l-subnav {
    z-index: 1;
    position: absolute;
    left: -200vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--grid-gutter);
    width: 100%;
    max-width: $grid-max-width;
    padding-bottom: var(--grid-gutter);
    transform: translate(-50%, 0);

    @media #{md("sm", "max")} {
        bottom: 0;
        top: calc(#{$accessible-size} + 5em);
        height: auto;
        padding-right: var(--grid-gutter-half);
        padding-left: var(--grid-gutter-half);
        overflow-y: auto;
        background-color: $color-light;

        > *:last-child {
            padding-bottom: var(--grid-gutter);
        }
    }

    @media #{md("sm")} {
        z-index: -1;
        top: $header-height;
        grid-template-columns: repeat(4, 1fr);
        transition: left 0s .4s;

        &:before {
            @include pseudo-el($width: 100vw, $height: calc(100% + #{$header-height} - .1em), $bg: $color-light);
            position: absolute;
            bottom: 0;
            left: calc(50% - 50vw);
            transform: scale(1, 0);
            transform-origin: 50% 0;
            transition: transform .4s $out-quint;
        }
    }
}

.l-subnav__item {

    h3 {
        padding-top: .6rem;
    }

    &.-all {
        .c-btn {
            padding-right: 0;
            padding-left: 0;
        }
    }
    @media #{md("sm")} {
        opacity: 0;
    }
}

.l-subnav__list {
    @include reset-list;
    margin-top: 1em;

    .c-btn {
        padding-left: 0;
    }
}

.l-subnav__thumb {
    display: block;

    .c-btn {
        align-items: flex-start;
        min-height: 3.5em;
        padding-right: 0;
        padding-left: 0;
    }

    .c-btn__inner {
        align-items: baseline;
    }
}

.l-subnav__thumb-img {
    display: block;
    overflow: hidden;

    .o-asset {
        transition: transform .2s ease-out;
    }

    &.-sale {
        background: $color-red;
        .o-asset {
            transform: scale(0.6);
        }
    }

    &.-black-friday {
        background: $color-dark;
        .o-asset {
            transform: scale(0.6);
        }
    }

    &:hover {

        .o-asset {
            transform: scale(1.1);
            transition: transform 5s ease-out;
        }

        &.-sale,  &.-black-friday {
            .o-asset {
                transform: scale(0.65);
                transition: transform 3s ease-out;
            }
        }
    }
}



/*=====================================
=            Burger button            =
=====================================*/


.o-burger {

    @media #{md("sm")} {
        display: none;
    }
}

.o-burger__line {
    position: absolute;
    left: 0;
    display: block;
    height: 1px;
    background-color: $color-dark;
    transform-origin: 100% 50%;

    &:nth-child(1),
    &:nth-child(2) {
        transition: transform .4s $out-quad .2s;
    }

    &:nth-child(1) {
        top: 35%;
        width: 100%;
    }

    &:nth-child(2) {
        bottom: 35%;
        width: 60%;
    }

    &:nth-child(3) {
        top: 50%;
        width: 100%;
        transform: scale(0, 1);
        transform-origin: 0 50%;
        transition: transform .3s $out-quad 0s;
    }

    .nav-is-open & {
        transition: transform .4s $out-quad;

        &:nth-child(1),
        &:nth-child(2) {
            transform: scale(0, 1);
            transition: transform .3s $in-quad 0s;
        }

        &:nth-child(3) {
            transform: scale(1);
            transition: transform .4s $out-quad .2s;
        }
    }
}



/*==================================
=            Animations            =
==================================*/

@keyframes anim-loading-line {
    0% {
        transform: translate(-100%, 0);
    }
    100% {
        transform: translate(100%, 0);
    }
}

</style>
