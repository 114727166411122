<template>
    <catalog
        v-if="products.length"
        :page="page"
        :allProducts="products"
    />
</template>

<script>

import Catalog from 'views/Catalog';

export default {
    name: 'Shop',
    components: {
        Catalog,
    },
    data: () => ({
        products: []
    }),
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    created() {

        this.$store.dispatch('products/loadAll')
            .then(products => {
                this.products = products
            })
            .catch(e => {
                console.error('Error: Shop/loadAll', e)  // eslint-disable-line
            })
    }
};

</script>

<style lang="scss" scoped>
</style>
