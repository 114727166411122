<template>
    <div
        v-if="!!$slots.left || !!$slots.right"
        :class="className"
    >
        <div class="c-notification__inner | grid">
            <div
                v-if="!!$slots.left"
                class="c-notification__col -left"
            >
                <slot name="left"></slot>
            </div>
            <div
                v-if="!!$slots.right"
                class="c-notification__col -right"
            >
                <slot name="right"></slot>
            </div>
        </div>
        <button
            class="c-notification__close | u-as"
            @click="close()"
        >
            <span class="o-cross"></span>
        </button>
    </div>
</template>

<script>

import Btn from 'components/Btn';

import { mapGetters } from 'vuex'

export default {
    name: 'Notification',
    components: {
        Btn,
    },
    data: () => ({
        isHidden: false,
    }),
    props: {
        // Change color
        // Default (primary) | Warning (red) | Success (green)
        type: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapGetters({
            isLoading: 'loader/isLoading',
        }),
        className() {
            let classname = 'c-notification'

            if(this.type) {
                classname += ` -${this.type}`
            }

            if(this.isLoading) {
                classname += ' is-loading'
            }

            if(this.isHidden) {
                classname += ' is-hidden'
            }

            return classname
        }
    },
    methods: {
        close() {
            this.isHidden = true
            this.$emit("close")
        }
    },
};

</script>

<style lang="scss">

/*==============================================
=            Notification component            =
==============================================*/

.c-notification {
    z-index: 51;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: $color-primary-light;
    border-bottom: $border-width-thick solid $color-primary;
    transition: top .5s ease;

    &.-success {}
    &.-warning {}

    &.is-loading {
        top: $border-width-thick;
    }

    &.is-hidden {
        transform: translate(0, -100%);
        transition: transform .8s $in-out-circ;
    }
}

.c-notification__inner {
    display: grid;
    grid-gap: var(--grid-gutter);
    grid-template-columns: 1fr;

    @media #{md("sm")} {
        grid-template-columns: 2fr 1fr;
    }
}

.c-notification__col {

    &.-left {}

    &.-right {
        padding-right: calc(var(--grid-gutter-half) + #{$accessible-size}/2);
    }
}

.c-notification__close {
    position: absolute;
    top: 0;
    right: 0;
}


/*==================================
=            Cross icon            =
==================================*/

.o-cross {
    display: block;
    width: 100%;
    height: 100%;

    &:after,
    &:before {
        @include pseudo-el($width: .7em, $height: 1px, $bg: $color-dark);
        position: absolute;
        top: calc(50% - .7em/2);
        left: calc(50% - .7em/2);
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}



</style>
