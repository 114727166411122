<template>
    <div>
        <app-section
            :title="featured ? featured.title : null"
            :cover="featured ? featured.asset : null"
            :breadcrumb="true"
            titleClass="t-t2"
        >
            <template #before-content>
                <div class="u-dp-flex u-jc-center">
                    <btn
                        v-if="featured"
                        tag="router-link"
                        :href="`/${featured.uri}`"
                        :label="$t('btn.readMore')"
                        bg="primary-light"
                    />
                </div>
            </template>
            <blocks :blocks="page.blocks" />
        </app-section>
        <push :push="page.push" />
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Btn from 'components/Btn';
import Blocks from 'components/Blocks';
import Push from 'components/Push';

export default {
    name: 'Collections',
    components: {
        AppSection,
        Btn,
        Blocks,
        Push,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    data: () => ({
        featured: null,
        catalog: null,
    }),
    created() {

        // Fetch featured
        let featured = this.page.selectedCollection || null
        if( featured && featured.length > 0 ) {
            featured = featured[0]
            this.$store.dispatch('pages/loadPartial', { id: featured.id })
                .then(entryData => {
                    this.featured = entryData
                })
                .catch(e => {
                    console.error('Error: Collections/Featured', e)  // eslint-disable-line
                })
        }

        // Fetch catalog
        let catalog = this.page.selectedCatalog | null
        if( catalog && catalog.length > 0 ) {
            catalog = catalog[0]
            this.$store.dispatch('pages/loadPartial', { id: catalog.id })
                .then(entryData => {
                    this.catalog = entryData
                })
                .catch(e => {
                    console.error('Error: Collections/Catalog', e)  // eslint-disable-line
                })
        }
    },
};

</script>

<style lang="scss">

.p-collections {

    .c-blocks {
        margin-top: var(--section-margin-half);
    }

    .c-blocks__sup {
        display: none;
    }

    .c-blocks__title {
        margin-top: 0;
    }
}

</style>
