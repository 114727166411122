<template>
    <span v-if="price" class="o-price">
        <span class="o-price__amount">
            {{ price }}
        </span>
        <del
            v-if="regularPrice"
            class="o-price__compareAt"
        >
            {{ regularPrice }}
        </del>
    </span>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'Price',
    props: {
        amount: {
            type: Number | String,
            required: true
        },
        compareAt: {
            type: Number | String,
            required: false
        },
        showCurrency: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
    }),
    computed: {
        ...mapGetters({
            country: 'global/getCurrentCountry',
            currency: 'shop/getCurrencyCode',
        }),
        price() {

            if (!this.country || !this.currency)
                return

            return this.formatAmount(this.amount)
        },
        regularPrice() {

            if (!this.price && !this.compareAt)
                return false

            if (this.price === this.compareAt)
                return false

            return this.formatAmount(this.compareAt)
        }
    },
    methods: {
        formatAmount(amount) {

            if (!amount)
                return

            if (!this.currency || !this.country)
                return

            // Shorteners
            const currency = this.currency,
                  country = this.country

            // Force integer
            amount = parseFloat(amount)

            // Create the formatter with the current site|store settings
            const formatter = new Intl.NumberFormat(country, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0
            })

            // Format amount
            amount = formatter.format(amount)

            // Return with currency when needed
            return `${this.showCurrency && currency ? currency : '' } ${amount}`
        }
    }
};

</script>

<style lang="scss">

.o-price {
    display: inline-block;
}

</style>
