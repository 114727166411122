<template>
    <div
        :class="className"
        :style="styles"
    >
        <asset
            v-if="asset"
            v-bind="asset"
        />
        <breadcrumb
            v-if="products"
            :title="$t('misc.wantToBuy')"
            :list="products"
            class="b-product__list"
        />
    </div>
</template>

<script>

import Breadcrumb from 'layout/Breadcrumb'
import Asset from 'objects/Asset'

export default {
    name: 'BlockProduct',
    components: {
        Breadcrumb,
        Asset,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    computed: {
        className() {
            let classname = 'b-product'

            if(this.settings.boxed) {
                classname += ' -boxed'
            }

            return classname
        },
        asset() {
            const asset = this.data.asset
            if(asset.length === 0) {
                return false
            } else {
                return asset[0]
            }
        },
        products() {
            const products = this.data.selectedProducts
            if(products.length === 0) {
                return false
            } else {
                return products
            }
        },
        settings() {
            return this.data.blockSettings[0]
        },
        styles() {
            let styles = `
                --width: ${this.settings.width};
                --align-self: ${this.settings.alignY};
            `

            if(this.settings.alignX === 'left') {
                styles += '--margin-left: 0;'
            } else if(this.settings.alignX === 'right') {
                styles += '--margin-right: 0;'
            }

            return styles
        },
    },
};

</script>

<style lang="scss">

.b-product__list {
    margin-top: .5em;

    .l-breadcrumb__list {
        display: contents;
    }
}

</style>
