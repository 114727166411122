<template>
    <app-not-found
        v-if="error"
    />
    <div
        v-else
        class="p-catalog"
        :key="key"
    >
        <app-section
            :title="page.mainTitle"
            :breadcrumb="true"
            :headerCenter="false"
            class="p-catalog__head"
            ref="head"
        >
            <template #after-title>
                <anim-text
                    :cms="true"
                    :text="page.headline"
                    class="p-catalog__desc | t-t5"
                />

                <div
                    ref="filters"
                    class="o-filters"
                    :class="{ 'is-sticky' : filtersStickty }"
                >
                    <div class="o-filters__inner">
                        <div
                            v-if="categories.length > 1"
                            class="o-filters__item -categories"
                        >
                            <app-select
                                :options="categories"
                                :placeholder="$t('misc.categories')"
                                :clearable="true"
                                v-model="selectedCategory"
                            />
                        </div>
                        <div
                            v-if="sizes.length > 1"
                            class="o-filters__item -sizes"
                        >
                            <app-select
                                :options="sizes"
                                :placeholder="$t('misc.size')"
                                :clearable="true"
                                v-model="selectedSize"
                            />
                        </div>
                        <div
                            class="o-filters__item -order"
                        >
                            <app-select
                                :options="orders"
                                :placeholder="$t('misc.order')"
                                :clearable="true"
                                modifier="simple"
                                v-model="selectedOrder"
                            />
                        </div>
                        <div class="o-filters__item -display">
                            <span class='o-filters__label | t-small'>{{ $t('misc.display') }}</span>
                            <button
                                :aria-label="$t('aria.displayBigGrid')"
                                class="o-filters__btn | u-as"
                                :class="{ 'is-active' : displayBig }"
                                @click="displayBig = true"
                            >
                                <icon icon="display-column" />
                            </button>
                            <button
                                :aria-label="$t('aria.displaySmallGrid')"
                                class="o-filters__btn | u-as"
                                :class="{ 'is-active' : !displayBig }"
                                @click="displayBig = false"
                            >
                                <icon icon="display-grid" />
                            </button>
                        </div>
                    </div>
                </div>

            </template>

            <template #before-content>
                <product-card
                    v-if="featured"
                    :key="`${featured.id}-${featured.slug}`"
                    v-bind="featured.card"
                    class="p-catalog__featured"
                />
            </template>

            <div
                v-if="page.fillInCover && page.fillInCover.length"
                class="p-catalog__fill-in"
            >
                <asset
                    v-bind="page.fillInCover[0]"
                    :reveal="true"
                    :cover="true"
                />
            </div>

        </app-section>

        <app-section
            v-if="productsList.length"
            class="p-catalog__products"
        >
            <product-list
                :products="productsList"
                :slider="false"
                :big="displayBig"
                :videos="page.videoList"
            />
            <div class="p-catalog__load-more">
                <btn
                    v-if="hasMore"
                    :label="$t('btn.loadMore')"
                    bg="light"
                    iconAfter="plus"
                    @click.native="paginationCount++"
                />
            </div>
        </app-section>
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import AppNotFound from 'layout/AppNotFound';

import Asset from 'objects/Asset'
import Builder from 'builder/Builder';
import Icon from 'objects/Icon';
import AnimText from 'objects/AnimText';
import AppSelect from 'objects/AppSelect';
import Btn from 'components/Btn';
import ProductCard from 'components/ProductCard';
import ProductList from 'components/ProductList';

import flatten from 'lodash/flatten';

export default {
    name: 'Catalog',
    components: {
        AppSection,
        AppNotFound,
        Asset,
        Builder,
        Icon,
        AnimText,
        AppSelect,
        Btn,
        ProductCard,
        ProductList,
    },
    props: {
        page: Object,
        allProducts: Array,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    data: () => ({
        error: null,
        products: [],
        selectedSize: null,
        selectedCategory: null,
        selectedOrder: null,
        filtersStickty: false,
        displayBig: true,

        // Pagination
        paginationCount: 1,
        pagination: 12,
    }),
    created() {
        // Set default display value from page data
        this.displayBig = this.page.displayBig
    },
    mounted() {

        if ( this.allProducts) {
            this.products = this.allProducts
        } else {

            let query = ''
            let handles, titles, order

            const catalog = this.page.slug
            const type = this.page.catalogType

            if ( type == 'static' ) {

                const selectedProducts = this.page.selectedProducts || null

                if ( selectedProducts && selectedProducts.length ) {

                    handles = selectedProducts.map(product => product.storefrontId)
                    titles = selectedProducts.map(product => product.title)
                    query += `(title:"${titles.join('") OR (title:"')}")`
                    //query += `("${handles.join('" OR "')}")`
                } else {
                    this.error = true
                }
            } else {

                const featuredProducts = this.page.featuredProducts || null

                if ( featuredProducts && featuredProducts.length ) {
                    handles = featuredProducts.map(product => product.storefrontId)
                }

                if (this.productTypes.length > 0) {
                    const types = this.productTypes.map(type => `product_type:${type}`)
                    query += `(${types.join(' OR ')})`
                }

                if (this.tags.length > 0) {
                    query += query.length > 0 ? ' AND ' : ''
                    const tags = this.tags.map(tag => `tag:${tag}`)
                    query += `(${tags.join(' OR ')})`
                }

                // Custom order for smart Catalog
                order = 'postDate DESC'

            }

            // If we have a query, fetch the products
            if (query) {

                this.$store.dispatch('products/searchProducts', {query, catalog, keySort: handles, order})
                    .then(products => {
                        this.products = products
                    })
                    .catch(e => {
                        console.error('Error: Catalog', e)  // eslint-disable-line
                    })

            // If not, display the NotFound component
            } else {
                this.error = true
            }

        }

        // Window events
        window.addEventListener('scroll', this.scroll)

    },
    computed: {

        key() {
            return `key-${this.page.slug}`;
        },

        // Featured product
        featured() {
            return this.productsFiltered ? this.productsFiltered[0] : false
        },

        // Product list under feature
        productsList() {
            //return this.productsFiltered.filter((product, i) => i < this.pagination * this.paginationCount )
            const productsList = this.productsFiltered.filter((product, i) => i < this.pagination * this.paginationCount )
            return [...productsList].slice(1)
        },

        // Product order options (select)
        orders() {
            return [
                {
                    title: this.$t('misc.priceASC'),
                    value: -1,
                },
                {
                    title: this.$t('misc.priceDESC'),
                    value: 1,
                },
            ]
        },

        // Page product types
        productTypes() {
            const productTypes = this.page.relatedProductTypes || null

            return (productTypes !== null && productTypes.length > 0) ? productTypes : []
        },

        // Page tags
        tags() {
            const tags = this.page.relatedTags || null

            return (tags !== null && tags.length > 0) ? tags : []
        },

        // Product sizes from products
        sizes() {

            if ( !this.products && !this.products.length )
                return

            let sizes = []

            // Array of unique sizes from products
            sizes = [...new Set([...flatten( this.products.map(p => p.sizes) ), ...sizes])]

            // Default order
            const defaultOrder = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']

            // Sizes not in default order array
            const notInDefaultOrder = sizes.filter(size => !defaultOrder.includes(size)).sort()

            // Return product sizes in order (default + others)
            return [...defaultOrder.filter(size => new Set(sizes).has(size)), ...notInDefaultOrder]
        },

        // Product types from product
        categories() {

            if ( !this.products && !this.products.length )
                return

            return [...new Set(this.products.map(p => p.productType))].sort().filter(Boolean)
        },

        // Filters product by selected productType and size
        productsFiltered() {

            // Filter
            const productsFiltered = this.products.filter(product => {

                // Size
                if(    this.selectedSize !== null
                    && !product.sizes.includes(this.selectedSize)
                ) {
                    return false
                }

                // Product type
                if(    this.selectedCategory !== null
                    && product.productType !== this.selectedCategory
                ) {
                    return false
                }

                return true
            })

            if (this.selectedOrder) {
                // Order by price <
                if(this.selectedOrder.value < 0) {
                    productsFiltered.sort((a, b) => a.minPrice - b.minPrice)
                // Order by price >
                } else if(this.selectedOrder.value > 0) {
                    productsFiltered.sort((a, b) => a.minPrice - b.minPrice).reverse()

                }
            }

            return productsFiltered
        },

        hasMore() {
            return this.productsFiltered.length - this.pagination * this.paginationCount > 0;
        },
    },
    methods: {
        scroll() {
            if ( !this.$refs.head )
                return

            const rect = this.$refs.head.$el.getBoundingClientRect()
            const headerSize = 56
            this.filtersStickty = (rect.top + rect.height - headerSize) < 0
        }
    },
    beforeDestroy() {

        // Window events
        window.removeEventListener('scroll', this.scroll)
    }
};

</script>

<style lang="scss">

.p-catalog__head {
    margin-bottom: var(--grid-gutter-half);

    .l-section__content {
        min-height: 9em;
    }

    @media #{md("sm")} {

        .l-section__inner {
            @include clearfix;
        }

        .l-section__header,
        .l-section__content,
        .p-catalog__featured {
            width: calc(50% - var(--grid-gutter-half));
        }

        .l-section__header,
        .l-section__content {
            float: left;
        }

        .p-catalog__featured {
            float: right;
            margin-top: calc(-.5 * var(--section-margin-half));
        }
    }
    @media #{md("md")} {

        .l-section__inner {
            display: grid;
            grid-template-areas: "h i"
                                 "c i"
                                 "c i";

            grid-template-columns: repeat(2, 1fr);
            grid-gap: var(--grid-gutter);
        }

        .l-section__header,
        .l-section__content,
        .p-catalog__featured {
            width: 100%;
            float: none;
        }

        .l-section__header {
            grid-area: h;
            padding-top: calc(.5 * var(--section-margin-half));
        }

        .l-section__content {
            grid-area: c;
            overflow: hidden;
        }

        .p-catalog__featured {
            grid-area: i;
            margin-top: 0;
        }
    }
}

.p-catalog__desc {
    margin-top: 2em;
}

.p-catalog__products {
    margin-top: 0;
}

.p-catalog__load-more {
    margin-top: 4em;
    text-align: center;
}


.p-catalog__fill-in {
    display: none;

    @media #{md("md")} {
        display: block;
        height: 100%;

        .o-asset {
            position: absolute;
            top:0;left:0;
            bottom:0; right:0;

        }
    }
}

/*----------  Filter  ----------*/

.o-filters {
    margin-top: 2em;

    &.is-sticky {
        z-index: 10;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: $color-primary-light;

        .o-filters__inner {
            width: $grid-width;
            max-width: $grid-max-width;
            margin: 0 auto;
            padding: .5em var(--grid-gutter-half);
        }

        .o-filters__item {
            margin: 0;

            &:not(:first-child) {
                margin-left: 1em;
            }

            &.-order {
                margin-left: auto;
            }
        }

        .o-filters__label {
            display: none;
        }

        .vs__dropdown-menu {
            top: auto;
            bottom: 100%;
        }
    }

    @media #{md("sm", "max")} {
        font-size: .8em;
    }

    @media #{md("xs", "max")} {
        font-size: .65em;

        &.is-sticky {

            .o-filters__item {

                &.-display {
                    display: none;
                }
            }
        }
    }
}

.o-filters__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -.5em;
}

.o-filters__item {
    margin: .5em;

    &.-sizes {
        --min-width: 6em;
    }

    &.-order {
        --min-width: 8em;
    }

    &.-display {
        display: flex;
        align-items: center;
        margin-right: 0;
    }

    @media #{md("sm", "max")} {

        &.-order,
        &.-display {
            margin-left: auto;
        }

        &.-display {
        }
    }
}

.o-filters__label {

    @media #{md("sm", "max")} {
        display: none;
    }
}

.o-filters__btn {
    color: $color-grey;

    &.is-active {
        color: $color-dark;
    }
}

</style>
