<template>
    <div>
        <app-section
            :title="page.mainTitle"
            :breadcrumb="true"
        >
            <ul
                v-if="page.pressList.length > 0"
                class="p-press__list"
            >
                <li
                    v-for="(item, i) in page.pressList"
                    :key="`press-item-${i}`"
                    class="p-press__item"
                    v-reveal
                >
                    <a
                        v-if="item.asset.length > 0"
                        :href="item.website"
                        :title="item.label ? `${item.label} | ${$t('btn.newTab')}`: $t('btn.newTab')"
                        target="_blank"
                    >
                        <asset
                            v-bind="item.asset[0]"
                        />
                    </a>
                    <btn
                        v-if="item.website"
                        tag="a"
                        :href="item.website"
                        target="_blank"
                        :title="item.label ? `${item.label} | ${$t('btn.newTab')}`: $t('btn.newTab')"
                        :label="$t('btn.readMore')"
                        iconAfter="arrow-long-right"
                        bg="none"
                    />
                </li>
            </ul>
        </app-section>
        <push :push="page.push" />
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Asset from 'objects/Asset';
import Btn from 'components/Btn';
import Push from 'components/Push';

export default {
    name: 'Press',
    components: {
        AppSection,
        Asset,
        Btn,
        Push,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
};

</script>

<style lang="scss">

.p-press {}

.p-press__list {
    @include reset-list;
    display: grid;
    grid-gap: var(--grid-gutter-half);
    grid-template-columns: repeat(2, 1fr);
    margin-top: var(--section-margin-half);

    @media #{md("xs")} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(4, 1fr);
    }
}

.p-press__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
    text-align: center;
    background-color: $color-primary-light;
    @include anim-reveal-default;

    .o-asset {
        width: 100%;
    }

    .c-btn {
        margin-top: .5em;
        padding-right: 0;
        padding-left: 0;
    }

    @media #{md("sm")} {
        padding: 1.5em;
    }
}

</style>
