/*
 * Craft asset transform w/ srcset
 *
 */

const transforms = [
    {
        name: 'homeDesktop',
        width: 2000,
        mode: "crop",
        srcset: `["377w", "480w", "660w", "744w", "755w", "1000w", "1252w", "960w", "1320w", "1488w", "2000w"]`
    },
    {
        name: 'homeMobile',
        width: 800,
        mode: "crop",
        srcset: `["300w", "340w", "355w", "394w", "600w", "680w", "710w", "788w"]`
    },
    {
        name: 'product',
        width: 1080,
        mode: "crop",
        srcset: `["277w", "283w", "341w", "416w", "455w", "540w", "554w", "566w", "682w", "832w", "910w", "1080w"]`
    },
    {
        name: 'grid',
        width: 1400,
        mode: "crop",
        srcset: `["260w", "394w", "500w", "520w", "626w", "788w", "1000w", "1252w"]`
    },
    {
        name: 'nav',
        width: 526,
        height: 789,
        mode: "crop",
        srcset: `["165", "263w", "330", "526w"]`
    }
]
export const getAsset = (format = false, field = "asset") => {

    let transform

    const data = `
        id
        url
        title
        width
        height
    `

    if ( format && typeof format == 'string' )
        transform = transforms.find(t => t.name == format)

    if ( transform ) {

        return `
            ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${transform.height||0}, immediately: false) {
                ${data}
                mimeType
                srcset (sizes: ${transform.srcset})
            }
        `
    } else {

        return `
            ${field} {
                ${data}
            }
        `
    }
}

export default {
    getAsset
}
