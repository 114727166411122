<template>
    <i
        class="o-icon"
        :class="className"
    >
        <svg :class="`svg-${icon}`">
            <title v-if="title">{{ title }}</title>
            <use v-bind:xlink:href="path"></use>
        </svg>
    </i>
</template>

<script>

export default {
    name: 'Icon',
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: '',
        },
    },
    computed: {
        path() {
            return `/dist/svg/sprite.svg#svg-${this.icon}`;
        },
        className() {
            let name = `o-icon--${this.icon}`
            name = this.size ? `${name} o-icon--${this.size}`: name
            return name;
        }
    },
};

</script>

<style lang="scss">

.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        fill: currentColor;
    }
}


/*----------  SVG Sizes  ----------*/

// Logo
.svg-logo {
    width: 12.5em;
    height: calc(12.5em * 43/497);
}

.svg-chat,
.svg-clock,
.svg-search,
.svg-arrow-up-right {
    width: .875em;
    height: .875em;
}

.svg-cart {
    width: 1em;
    height: calc(1em * 17/20);
}

.svg-mail {
    width: .8em;
    height: calc(.8em * 7/10);
}

.svg-phone {
    width: .875em;
    height: calc(.875em * 13/14);
}

.svg-map-picker {
    width: .7em;
    height: calc(.7em * 18/13);
}

// Arrows
.svg-arrow-long-left,
.svg-arrow-long-right {
    width: 1.25em;
    height: calc(1.25em * 11/25);
}

.svg-arrow-left,
.svg-arrow-right {
    width: .5em;
    height: calc(.5em * 17/10);
}

.svg-arrow-up,
.svg-arrow-down {
    width: calc(.5em * 17/10);
    height: .5em;
}

.svg-plus,
.svg-cross, {
    width: .75em;
    height: .75em;
}

.svg-lock {
    width: .82em;
    height: calc(.82em * 19/13);
}

// Player
.svg-player-play {
    width: .8em;
    height: calc(.8em * 15/14);
}

.svg-player-pause {
    width: .75em;
    height: calc(.75em * 17/13);
}

.svg-player-mute {
    width: 1.375em;
    height: calc(1.375em * 15/22);
}

.svg-player-unmute {
    width: .7em;
    height: calc(1.375em * 10/15);
}

// Display
.svg-display-grid,
.svg-display-column {
    width: 1.4em;
    height: calc(1.4em * 15/22);
}

// Payment logos
.svg-logo-mastercard {
    width: calc(.85em * 23/12);
    height: .85em ;
}

.svg-logo-amex {
    width: calc(.5em * 42/8);
    height: .5em;
}

.svg-logo-visa {
    width: calc(.7em * 37/11);
    height: .7em;
}

.svg-logo-discover {
    width: calc(.7em * 60/10);
    height: .7em;
}

</style>
