/**********************************
 *
 *
 *  SHOPIFY SNIPPETS
 *
 *
 **********************************/

// Variables
const _basic = `
    title
    id
`

const _money = `
    amount
    currencyCode
`

const _variants = (first) => {
    return `
        variants(first:${first || 50}) {
            edges {
                node {
                    ${_basic}
                    availableForSale
                    currentlyNotInStock
                    quantityAvailable
                    priceV2 {
                        ${_money}
                    }
                    compareAtPriceV2 {
                        ${_money}
                    }
                    selectedOptions {
                        name
                        value
                    }
                    sku
                }
            }
        }
    `
}


/**********************************/

// _money
export const money = `
    ${_money}
`

// Product
export const product = `
    ${_basic}
    handle
    descriptionHtml
    options {
        name
        values
    }
    priceRange {
        minVariantPrice {
            ${_money}
        }
        maxVariantPrice {
            ${_money}
        }
    }
    productType
    tags
    totalInventory
    ${_variants()}
`

// Variant
export const variant = `
    variant {
        ${_basic}
        sku
        presentmentPrices(first:10) {
            edges {
                node {
                    compareAtPrice {
                        ${_money}
                    }
                    price {
                        ${_money}
                    }
                }
            }
        }
    }
`

// Checkout
export const checkout = `
    checkout {
        id
        webUrl
        currencyCode
        createdAt
        completedAt
        lineItemsSubtotalPrice {
            ${money}
        }
        totalPriceV2 {
            ${money}
        }
        totalTaxV2 {
            ${money}
        }
        lineItems(first: 250) {
            edges {
                node {
                    id
                    title
                    quantity
                    ${variant}
                }
            }
        }
    }
`


export default {
    money,
    product,
    variant,
    checkout
}
