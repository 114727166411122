<template>
    <component
        :is="tag"
        class="l-section l-section--not-found"
    >
        <div class="l-section__inner | grid">

            <header
                class="l-section__header"
                :class="headerCenter ? '-center' : 'left'"
            >

                <anim-text
                    :tag="titleTag"
                    :text="title"
                    class="l-section__title"
                    :class="titleClass"
                />

            </header>

            <div class="l-section__content">
                <slot />
            </div>

            <div class="l-section__footer">
                <btn
                    tag="router-link"
                    :href="'home'|resolve"
                    :label="$t('btn.backToHome')"
                    bg="light"
                />
                <btn
                    tag="router-link"
                    :href="'collections'|resolve"
                    :label="$t('btn.shopCollections')"
                    bg="light"
                />
            </div>
        </div>
    </component>
</template>

<script>

import AnimText from 'objects/AnimText';
import Btn from 'components/Btn';

export default {
    name: 'AppNotFound',
    components: {
        AnimText,
        Btn
    },
    props: {
        title: {
            type: String,
            default: 'This page doesn’t exist',
        },
        tag: {
            type: String,
            default: 'section',
        },
        titleTag: {
            type: String,
            default: 'h1',
        },
        titleClass: {
            type: String,
            default: 't-t2',
        },
        cover: {
            type: Object|Array,
            default: null,
        },
        headerCenter: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
    },
};

</script>

<style lang="scss">
.l-section--not-found {
    padding-top: var(--section-margin-half);

    .l-section__footer {
        margin: 4rem auto;
        text-align: center;

        .c-btn {
            margin: 0.5em;
        }
    }

}
</style>
