import Vue from 'vue'
import Vuex from 'vuex'

import global from './modules/global'
import loader from './modules/loader'

// Craft
import navigations from './modules/craft/navigations'
import globalsets from './modules/craft/globalsets'
import categories from './modules/craft/categories'
import pages from './modules/craft/pages'
import search from './modules/craft/search'

// Shopify
import shop from './modules/shopify/shop'
import products from './modules/shopify/products'
import checkout from './modules/shopify/checkout'
import cart from './modules/shopify/cart'

// Vuex
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        global,
        loader,
        navigations,
        globalsets,
        categories,
        search,
        pages,
        shop,
        products,
        checkout,
        cart,
    },
    strict: process.env.NODE_ENV !== 'production'
})

export default store
