<template>
    <footer class="l-footer">
        <div class="l-footer__inner | grid">
            <div class="l-footer__content">
                <div class="l-footer__info">
                    <router-link
                        to="/"
                        :title="$t('btn.backToHome')"
                        class="l-footer__logo"
                    >
                        <icon icon="logo" />
                    </router-link>

                    <app-address :address="address" />
                </div>
                <payment-methods class="l-footer__pm" />
                <ul class="l-footer__list">
                    <li
                        v-for="(node, i) in list"
                        :key="`footer-list-node-${i}`"
                    >
                        <router-link
                            v-if="node.entry"
                            :to="`/${node.entry.uri}`"
                            :title="node.entry.title"
                            class="t-link"
                        >
                            <icon
                                v-if="node.icon"
                                :icon="node.icon"
                            />
                            {{ node.label ? node.label : node.entry.title }}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="l-footer__bottom">
                <ul class="l-footer__socials">
                    <li
                        v-for="social in socials"
                        :key="`social-${social.name}`"
                    >
                        <a
                            :href="social.link"
                            :title="social.name"
                            target="_blank"
                            rel="noopener"
                            class="t-link"
                        >
                            {{ social.name }}
                        </a>
                    </li>
                </ul>
                <span class="l-footer__copy">&copy;{{ year }} SILVIA TCHERASSI</span>
                <a
                    :title="$t('credits')"
                    class="l-footer__credits"
                    href="https://mambomambo.ca/"
                    target="_blank"
                    rel="noopener"
                >
                    {{ $t('btn.credits') }}
                </a>
            </div>
        </div>
    </footer>
</template>

<script>

import Icon from 'objects/Icon';
import AppAddress from 'objects/AppAddress';
import PaymentMethods from 'objects/PaymentMethods';

import { mapGetters } from 'vuex'

export default {
    name: 'AppFooter',
    components: {
        Icon,
        AppAddress,
        PaymentMethods,
    },
    computed: {
        ...mapGetters({
            list: 'navigations/getFooter',
            socials: 'global/getSocials',
            address: 'global/getAddress',
        }),
        year: () => new Date().getFullYear(),
    },
};

</script>

<style lang="scss">

.l-footer {
    z-index: 20;
    background-color: $color-light;

    @media #{md("sm")} {
        //margin-bottom: 10vh;
    }
}

.l-footer__inner {}

.l-footer__content {
    padding-top: 10vh;
    padding-bottom: 10vh;

    @media #{md("sm")} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
    }

    @media #{md("md")} {
        grid-template-columns: 1fr 2fr;
    }

    @media #{md("lg")} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

.l-footer__info {}

.l-footer__logo {
    display: block;
    margin-bottom: 1em;
}

.l-footer__address {
}

.l-footer__pm {
    margin-top: 2em;

    @media #{md("sm")} {
        margin-top: 2em;
    }

    @media #{md("md")} {
        grid-column: 1/3;
        grid-row-start: 2;
    }
}

.l-footer__list {
    @include reset-list;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: var(--grid-gutter-half);

    // columns: 2;
    // column-gap: var(--grid-gutter-half);
    // page-break-inside: avoid;
    // break-inside: avoid;

    li {
        padding-top: .5em;
        padding-bottom: .5em;

        &:nth-child(2) {
            grid-row: 2/4;
        }

        // &:nth-child(2):after {
        //     content: "";
        //     display: block;
        //     height: 1.5em;
        // }
    }

    .o-icon {
        vertical-align: baseline;
    }


    @media #{md("md", "max")} {
        margin-top: 2em;
    }

    @media #{md("sm", "max")} {

        li {

            &:nth-child(5) {
                padding-bottom: .6em;
            }
        }
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column: 1/3;
    }

    @media #{md("md")} {
        grid-row-start: 1;
        grid-column: 2/3;
    }
}

.l-footer__bottom {
    font-size: .75em;
    padding-bottom: 1em;

    @media #{md("sm", "max")} {
        display: flex;
        flex-wrap: wrap;
    }

    @media #{md("sm")} {
        display: grid;
        grid-template-columns: 3fr 2fr 1fr;
        margin-top: 2em;
        padding-top: 1em;

        &:before {
            @include pseudo-el($width: 100vw);
            position: absolute;
            top: 0;
            left: calc(50% - 50vw);
            pointer-events: none;
            border-top: $border-secondary-light;
        }
    }
}

.l-footer__socials {
    @include reset-list;
    display: flex;
    text-transform: uppercase;

    @media #{md("sm", "max")} {
        justify-content: space-between;
        width: 100%;
        margin-top: 1em;
        margin-bottom: 1em;
        padding-top: 1em;
        padding-bottom: 1em;

        &:before {
            @include pseudo-el($width: 100vw);
            position: absolute;
            top: 0;
            left: calc(50% - 50vw);
            border-top: $border-secondary-light;
            border-bottom: $border-secondary-light;
            pointer-events: none;
        }
    }

    @media #{md("sm")} {

        li {

            &:not(:first-child) {
                margin-left: 1.5em;
            }
        }
    }
}

.l-footer__copy {
    text-transform: uppercase;
    color: $color-secondary;
}

.l-footer__credits {
    display: inline-block;
    margin-left: auto;
    color: $color-secondary;
}

</style>
