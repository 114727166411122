<template>
    <div>
        <app-section
            :title="page.mainTitle"
            titleClass="t-t2"
            :cover="cover"
            :breadcrumb="true"
        />
        <builder :builder="page.builder" />
        <entries-list :exclude="page.id" />
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Builder from 'builder/Builder';
import EntriesList from 'components/EntriesList';

export default {
    name: 'Post',
    components: {
        AppSection,
        Builder,
        EntriesList,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: this.decodedTitle
        }
    },
    computed: {
        decodedTitle() {
            return ( this.page.seo ) ? this.page.seo.title.replace('&amp;', '&') : null
        },
        cover() {
            if(this.page.asset.length === 0) {
                return null
            } else {
                return this.page.asset[0]
            }
        }
    }
};

</script>

<style lang="scss">

.p-post {

    .l-section__cover {

        &.-landscape {
            --width: 100%;
        }
    }
}


</style>
