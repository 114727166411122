<template>
    <div>
        <app-section
            :title="page.mainTitle"
            :breadcrumb="true"
        >
            <box class="p-stores__box">
                <h3 class="t-small">{{ stores.length }} {{ $t('misc.storesIn') }} {{ regions.length }} {{ $t('misc.regions') }}</h3>
                <btn
                    v-for="region in regions"
                    :key="`region-${region.slug}`"
                    :label='region.title'
                    @click.native="scrollTo(region.slug)"
                />
            </box>

            <div
                v-for="region in regions"
                :key="`item-${region.slug}`"
                :id="region.slug"
                class="p-stores__region"
            >
                <anim-text
                    tag="h3"
                    :text="region.title"
                    class="p-stores__title | t-t2"
                />
                <ul class="o-stores-list">
                    <li
                        v-for="store in stores.filter(store => store.region.id === region.id)"
                        :key="`store-${store.slug}`"
                        class="o-stores-list__item"
                        v-reveal
                    >
                        <icon
                            class="o-stores-list__icon"
                            icon="map-picker"
                        />
                        <h4
                            v-html="store.title"
                            class="o-stores-list__title | t-t4"
                        ></h4>
                        <h5
                            v-if="store.country"
                            class="o-stores-list__country | t-small"
                        >
                            {{ store.country.title }}
                        </h5>
                        <app-address
                            :address="store.address"
                            :directions="true"
                            class="o-stores-list__address"
                        />
                        <btn
                            tag="router-link"
                            :href="store.uri"
                            :label="$t('btn.learnMore')"
                            bg="light"
                            :reveal="false"
                            class="o-stores-list__btn"
                        />
                    </li>
                </ul>
            </div>
        </app-section>
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Box from 'objects/Box';
import Icon from 'objects/Icon';
import AppAddress from 'objects/AppAddress';
import AnimText from 'objects/AnimText';
import Btn from 'components/Btn';

import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

import { orderObjectByKey } from 'src/utils';

export default {
    name: 'Stores',
    components: {
        AppSection,
        Box,
        Icon,
        AppAddress,
        AnimText,
        Btn,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    data: () => ({
        stores: [],
        regions: [],
    }),
    created() {

        // Load stores
        this.$store.dispatch('pages/loadList', 'stores')
            .then(stores => {
                let region
                stores.forEach(store => {
                    region = store.selectedRegion.length > 0 ? store.selectedRegion[0] : false
                    store.region = region
                    store.country = store.selectedRegion.length > 1 ? store.selectedRegion[1] : false
                })

                this.stores = stores
                this.regions = this.getRegions(stores)

            })
            .catch(e => {
                console.error('Error: Stores/loadList', e)  // eslint-disable-line
            })

    },
    methods: {
        scrollTo(anchor) {

            if (!anchor) {
                return
            }

            gsap.to(window, {
                scrollTo: {
                    y: `#${anchor}`,
                    offsetY: 80 // Header size
                },
                duration: .8,
                ease: 'power2.inOut',
            })
        },
        getRegions(stores) {
            let regions = [], region

            stores.forEach(store => {
                region = store.region

                if(region && regions.findIndex(r => r.id === region.id) < 0) {
                    regions.push(region)
                }
            })

            regions = orderObjectByKey(regions, ['Europe', 'North America', 'South America'], 'title')

            return regions
        }
    }
};

</script>

<style lang="scss">

.p-stores {
}

.p-stores__box {

    .c-btn {
        margin: 1em;
    }
}

.p-stores__region {
    padding-top: var(--section-margin-half);
    padding-bottom: var(--section-margin-half);
    text-align: center;

    &:not(:last-child) {
        border-bottom: $border-secondary-light;
    }

    @media #{md("lg")} {
        width: calc(5/6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }
}

.p-stores__title {
    margin-bottom: 1em;
}



/*===================================
=            Stores list            =
===================================*/


.o-stores-list {
    @include reset-list;
    display: flex;
    flex-wrap: wrap;
    // display: grid;
    // grid-column-gap: var(--grid-gutter);
    // justify-items: center;

    // @media #{md("xs")} {
    //     grid-template-columns: repeat(2, 1fr);
    // }

    // @media #{md("sm")} {
    //     grid-template-columns: repeat(3, 1fr);
    // }
}

.o-stores-list__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 100%;
    padding: 5vh var(--grid-gutter);

    @include anim-reveal-default;

    @media #{md("xs")} {
        flex-basis: calc(1/2 * 100%);
    }

    @media #{md("sm")} {
        flex-basis: calc(1/3 * 100%);
    }
}

.o-stores-list__icon {
    margin-bottom: .5em;
}

.o-stores-list__country {
    margin-top: .5em;

}

.o-stores-list__btn,
.o-stores-list__address {
    margin-top: 1em;
}

</style>
