import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from 'src/store/index'

import reveal from './directives/reveal'  // eslint-disable-line
import { i18n } from 'src/i18n'
import { resizeEnd } from 'src/utils';
import 'src/filters'
import 'whatwg-fetch'

// Plugins
import VueMeta from 'vue-meta'
import VueGtm from 'vue-gtm';

// Sentry
//import * as Sentry from "@sentry/browser";
//import { Vue as VueIntegration } from "@sentry/integrations";
//import { Integrations } from "@sentry/tracing";

// Add window resize End event
resizeEnd()

// Var
Vue.config.productionTip = false

// Meta descriptions
Vue.use(VueMeta, {})

// Production
const isProd = process.env.NODE_ENV === 'production';

// GTM
if ( isProd && process.env.VUE_APP_GTM ) {
    Vue.use(VueGtm, {
        id: process.env.VUE_APP_GTM,
        queryParams: {
            gtm_auth: process.env.VUE_APP_GTM_AUTH,
            gtm_cookies_win:'x'
        },
        defer: true,
        enabled: true,
        debug: false,
        vueRouter: router,
        loadScript: true,
        // ignoredViews: [''],
        // trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    });
}

// Sentry
// if ( isProd && process.env.VUE_APP_SENTRY ) {
//     Sentry.init({
//         dsn: process.env.VUE_APP_SENTRY,
//         integrations: [
//             new VueIntegration({
//                 Vue,
//                 tracing: isProd,
//             }),
//             new Integrations.BrowserTracing(),
//         ],
//
//         // We recommend adjusting this value in production, or using tracesSampler
//         // for finer control
//         tracesSampleRate: 1.0,
//     });
// }

// Initial load
store.dispatch('global/init')

// Vue instance
new Vue({
    el: '#vue',
    router,
    i18n,
    store,
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
