<template>
    <div>
        <app-section
            :title="featured.title"
            :cover="featured.asset"
            :breadcrumb="true"
            titleClass="t-t2"
        >
            <div class="u-dp-flex u-jc-center">
                <btn
                    v-if="featured"
                    tag="router-link"
                    :href="featured.uri"
                    :label="$t('btn.readMore')"
                    bg="primary-light"
                />
            </div>
            <blocks :blocks="blocks" />
        </app-section>
        <entries-list
            :exclude="excludes"
            type="events"
        />
        <push :push="page.push" />
    </div>
</template>

<script>

import AppSection from 'layout/AppSection';
import Btn from 'components/Btn';
import Blocks from 'components/Blocks';
import Push from 'components/Push';
import EntriesList from 'components/EntriesList';

export default {
    name: 'Events',
    components: {
        AppSection,
        Btn,
        Blocks,
        Push,
        EntriesList,
    },
    props: {
        page: Object,
    },
    metaInfo() {
        return {
            title: ( this.page.seo ) ? this.page.seo.title : null
        }
    },
    computed: {
        featured() {
            let selectedEvent = this.page.selectedEvent
            if(selectedEvent.length > 0) {
                selectedEvent = selectedEvent[0].id
                return this.page.events.find(e => e.id === selectedEvent)
            } else {
                return this.page.events[0]
            }
        },
        blocks() {
            if(this.page.blocks.length > 0) {
                return this.page.blocks
            } else {
                // Format for block component
                return [{ entry: [this.eventsNotFeatured[0]]}, { entry: [this.eventsNotFeatured[1]] }]
            }
        },
        eventsNotFeatured() {
            return this.page.events.filter(e => e.id !== this.featured.id)
        },
        excludes() {
            // Retrieve blocks ids
            const blocksIDS = this.blocks.filter(block => block.entry && block.entry.length > 0).map(block => block.entry[0].id)

            // Exclude featured and block events from bottom list
            return [this.featured.id, ...blocksIDS]
        }
    }
};

</script>

<style lang="scss">

.p-events {

    // .c-blocks {
    //     margin-top: var(--section-margin);
    // }
}

</style>
