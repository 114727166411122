/*
** GraphQL Configuration and variables
*/
import { checkStatus } from 'src/utils';

export const api = '/api'

export const fetchApi = (query, variables) => {

    return fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query, variables })
        })
        .then(checkStatus)
        .then(r => {
            return r.json()
        })
        .then(r => {
            return r.data || r.errors || null;
        })
        .catch(error => {
            console.error('Error: /api', error) // eslint-disable-line
            return undefined
        })
}
