<template>
    <v-select
        v-if="options.length > 0"
        :options="options"
        :clearable="clearable"
        :searchable="searchable"
        :label="label"
        :placeholder="placeholder"
        v-model="selected"
        :class="className"
    >
        <template #selected-option="option">
            {{ option[label] }}
        </template>
        <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
                <icon icon="arrow-down" />
            </span>
        </template>
        <template #option="option">
            <span class="vs__dropdown-label">
                {{ option[label] }}
            </span>
        </template>
    </v-select>
</template>

<script>

import Icon from 'objects/Icon';

import vSelect from 'vue-select'

export default {
    name: 'AppSelect',
    components: {
        vSelect,
        Icon,
    },
    props: {
        modifier: {
            type: String,
            default: null,
        },
        defaultValue: false,

        // Plugin props
        options: {
            type: Array,
            default: () => [],
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'title',
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        selected: null,
    }),
    mounted() {
        if(this.defaultValue) {
            this.selected = this.defaultValue
        }
    },
    computed: {
        className() {
            let classname = 'o-select'

            if(this.modifier) {
                classname += ` -${this.modifier}`
            }

            return classname
        }
    },
    watch: {
        selected(value) {
            this.$emit('input', value)
        },
    },
};

</script>

<style lang="scss">

@import "vue-select/src/scss/vue-select.scss";

.o-select {
    --border-size: 2px;
    display: inline-block;
    min-width: var(--min-width, 10em);

    &.-simple {
        --border-size: 0;

        .vs__dropdown-toggle {
            padding-right: 0;
        }
    }

    &.vs--open {
        z-index: 2;

        .vs__selected {
            opacity: 1;
        }

        .vs__dropdown-toggle {
            background-color: $color-primary-light;
        }

        &.vs--single {

            .vs__selected {
                position: relative;
            }
        }
    }

    .vs__selected {
        margin: 0;
        padding: 0;
        border: 0;
        // padding: 0 0 0 .5em;
    }

    .vs__search {
        padding: 0;
        color: $color-dark;
        border: 0;
        pointer-events: none;

        &::-webkit-input-placeholder {
            opacity: 1;
        }

        &,
        &:focus {
            margin: 0;
        }
    }

    .vs__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 100%;
        padding: 0 0 0 .5em;
    }

    .vs__open-indicator {
        display: flex;
    }

    .vs__dropdown-toggle {
        height: 2.75em;
        align-items: center;
        padding-right: .75em;
        padding-bottom: 0;
        border: 0;
        border-right: var(--border-size) solid $color-dark;
        border-bottom: var(--border-size) solid $color-dark;
        border-radius: 0;
    }

    .vs__dropdown-menu {
        z-index: 1;
        top: 100%;
        min-width: 100%;
        background-color: $color-primary-light;
        border: 0;
        border-right: var(--border-size) solid $color-dark;
        border-bottom: var(--border-size) solid $color-dark;
        border-radius: 0;
        box-shadow: none;
    }

    .vs__dropdown-option {
        padding: .5em .5em;

        .vs__dropdown-label {
            display: inline-block;

            &:after {
                @include pseudo-el($height: var(--border-size), $bg: currentColor);
                position: absolute;
                bottom: 0;
                left: 0;
                transform: scale(0, 1);
            }
        }

        &--highlight {
            color: inherit;
            background: none;

            .vs__dropdown-label:after {
                transform: scale(1);
            }
        }
    }

    @media #{md("xs")} {

        .vs__dropdown-toggle {
            padding-left: .75em;
        }
    }
}

</style>
