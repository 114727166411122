<template>
    <span
        :class="className"
        ref="loader"
    >
        <icon
            v-if="firstload"
            icon="logo"
            class="o-loader__logo"
        />
    </span>
</template>


<script>

import Icon from 'objects/Icon';
import Variables from 'src/mixins/variables';

import { mapGetters } from 'vuex'

import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default {
    name: 'Loader',
    components: {
        Icon,
    },
    mixins: [ Variables ],
    data: () => ({
        firstload: true,
    }),
    created() {
        this.$store.dispatch('loader/startLoad')

        const loaderWatch = this.$store.watch(() => this.$store.getters['loader/loadCount'], count => {

            if(count === 1) {

                    setTimeout(() => {
                        this.$store.dispatch('loader/endLoad')
                        this.firstload = false

                        // Remove watch
                        loaderWatch()
                    }, 400);
            }
        })
    },
    computed: {
        ...mapGetters({
            isLoading: 'loader/isLoading',
        }),
        className() {
            let classname = 'o-loader'

            if(this.firstload) {
                classname += ' -firstload'
            }

            if(this.isLoading) {
                classname += ' is-loading'
            }

            return classname
        }
    },
    watch: {
        isLoading(loading) {
            if(loading) {
                setTimeout(() => {
                    window.scrollTo(0, 0)
                }, 400);
            }
        },
    },
}

</script>

<style lang="scss">

.o-loader {
    z-index: -10;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: $color-light;
    opacity: 0;
    transition: z-index 0s 1s, opacity 1s ease-out;

    &.is-loading {
        z-index: 10;
        pointer-events: initial;
        opacity: 1;
        transition: opacity .3s ease-in;
    }

    // First load animation
    &.-firstload {
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-primary-light;

        &:after {
            @include pseudo-el($width: 100%, $height: $border-width-thick, $bg: $color-primary);
            position: absolute;
            top: 0;
            left: 0;
            animation: anim-loading-line 2s ease-in-out infinite;
        }
    }
}

.o-loader__logo {
    display: block;

    @media #{md("xs")} {
        font-size: 1.5rem;
    }
}

</style>
