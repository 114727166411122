<template>
    <div :class="className">
        <div class="c-navbar__inner">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Navbar',
    props: {
        center: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        className() {
            let classname = 'c-navbar'

            if(this.center) {
                classname += ' -center'
            }

            return classname
        },
    }
};

</script>

<style lang="scss">

.c-navbar {
    z-index: 10;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-primary-light;

    &.-center {
        text-align: center;
    }
}

.c-navbar__inner {
    width: $grid-width;
    max-width: $grid-max-width;
    margin: 0 auto;
    padding: .5em var(--grid-gutter-half);
}

</style>
