//import Vue from 'vue'

import { storeFrontApi } from 'src/graphql/shopify/config';
//import {default as storefrontQueries} from 'src/graphql/shopify/queries';
import {default as storefrontMutations} from 'src/graphql/shopify/mutations';

import mainStore from 'src/store/index'

/**********************************
 *
 *
 *  SHOPIFY/CART
 *
 *
 **********************************/

// State
const state = {
    checkout: {}
}

// Getters
const getters = {
    getCheckoutId: state => state.checkout.id ? state.checkout.id : false,
    getCheckoutLines: state => state.checkout.lineItems ? state.checkout.lineItems.edges : false,
}

// Actions
const actions = {

    loadFromLocalStorage(store) {

        return new Promise((resolve, reject) => {

            const currentSite = mainStore.getters['global/getCurrentSite']

            if ( localStorage.getItem(currentSite.handle + '_checkout') !== null ) {
                try {

                    const checkout = JSON.parse(localStorage.getItem(currentSite.handle + '_checkout'));

                    let   now = new Date().toISOString(),
                          today = new Date(now).getTime(),
                          createdAt = new Date(checkout.createdAt),
                          expiredOn = createdAt.setDate( createdAt.getDate() + 14 ),
                          completedAt = new Date(checkout.completedAt).getTime()

                    if ( completedAt !== 0 && completedAt < today ) {

                        throw {
                            removeCart: true,
                            message: 'Checkout is completed.'
                        }
                    }

                    if ( expiredOn < today ) {

                        throw {
                            removeCart: false,
                            message: 'Checkout is expired.'
                        }
                    }

                    store.commit('setCheckout', checkout)
                    resolve(checkout)

                } catch(e) {
                    localStorage.removeItem(currentSite.handle + '_checkout');
                    reject(e)
                }
            }

            resolve()

        })
    },
    create(store) {

        return new Promise((resolve, reject) => {

            // Check if a state.checkout already exist, if so, update the checkout instead of creating it
            if ( store.state.checkout.webUrl ) {
                resolve(store.state.checkout)

            } else {

                // Filter lineItems to keep only `variantId` and `quantity` for Shopify
                const input = {
                    lineItems: mainStore.getters['cart/getLines'].map(item => ({ variantId: item.variantId, quantity: item.quantity }))
                }

                //
                storeFrontApi(storefrontMutations.checkoutCreate, {input})
                   .then(response => {

                        if ( !response ) {
                            reject(response)

                        } else {
                            const checkout = response.checkoutCreate.checkout
                            store.commit('setCheckout', checkout)
                            resolve(checkout)
                        }
                   })
            }

        })
    },
    addLineItem(store, {variantId, quantity}) {

        return new Promise((resolve, reject) => {

            const checkoutId = store.getters.getCheckoutId

            const lineItems = [{
                quantity,
                variantId
            }]

            // Update Checkout with Shopify API
            storeFrontApi(storefrontMutations.checkoutLineItemsAdd, {checkoutId, lineItems})
               .then(response => {

                    // Exit on error
                    if (!response || !response.checkoutLineItemsAdd) {
                        reject({
                            message: 'Something went wrong while update the Shopify checkout.',
                            type: 'checkoutLineItemsAdd',
                            response: response || null
                        })

                    } else {
                        const checkout = response.checkoutLineItemsAdd.checkout
                        store.commit('setCheckout', checkout)
                        resolve(checkout)
                    }
               })

        })
    },
    updateLineItem(store) {

        return new Promise((resolve, reject) => {

            const checkoutId = store.getters.getCheckoutId

            // Clean Checkout LineItems (remove .node and keep only what we need)
            const checkoutLines = store.getters.getCheckoutLines.map(item => ({
                id: item.node.id,
                quantity: item.node.quantity,
                variantId: item.node.variant.id
            }))

            // Clean Cart Lines
            const cartLines = mainStore.getters['cart/getLines'].map(item => ({
                variantId: item.variantId,
                quantity: item.quantity
            }))

            // Update quantity from cart to checkout
            checkoutLines.forEach(line => {
                line.quantity = cartLines.find(x => x.variantId === line.variantId).quantity
            })

            // Update Checkout with Shopify API
            storeFrontApi(storefrontMutations.checkoutLineItemsUpdate, {checkoutId, lineItems:checkoutLines})
               .then(response => {

                    // Exit on error
                    if (!response || !response.checkoutLineItemsUpdate) {
                        reject({
                            message: 'Something went wrong while update the Shopify checkout.',
                            type: 'checkoutLineItemsUpdate',
                            response: response || null
                        })

                    } else {
                        const checkout = response.checkoutLineItemsUpdate.checkout
                        store.commit('setCheckout', checkout)
                        resolve(checkout)
                    }
               })

        })
    },
    removeLineItems(store, ids) {

        return new Promise((resolve, reject) => {

            const checkoutId = store.getters.getCheckoutId

            // Clean Checkout LineItems (remove .node and keep only what we need)
            const checkoutLines = store.getters.getCheckoutLines.map(item => ({
                id: item.node.id,
                variantId: item.node.variant.id
            }))

            let lineItemIds = []

            ids.forEach(id => {

                const index = checkoutLines.findIndex(l => l.variantId === id),
                      line = checkoutLines[index]

                if (index !== -1)
                    lineItemIds.push(line.id)

            })

            // Remove the Checkout LineItem with Shopify API
            storeFrontApi(storefrontMutations.checkoutLineItemsRemove, {checkoutId, lineItemIds})
               .then(response => {

                    // Exit on error
                    if (!response || !response.checkoutLineItemsRemove) {
                        reject({
                            message: 'Something went wrong while update the Shopify checkout.',
                            type: 'checkoutLineItemsRemove',
                            response: response || null
                        })

                    } else {
                        const checkout = response.checkoutLineItemsRemove.checkout
                        store.commit('setCheckout', checkout)
                        resolve(checkout)
                    }

               })
        })
    }
}

// Mutations
const mutations = {
    setCheckout(state, checkout) {

        // Update the store
        state.checkout = checkout

        // Update the localStorage
        const currentSite = mainStore.getters['global/getCurrentSite']
        let parsed = JSON.stringify(checkout)
        localStorage.setItem(currentSite.handle + '_checkout', parsed)

    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
