/*
** Craft GraphQL Queries
*/

import snippets from './snippets'
import {getAsset} from './assets'

const siteID = window.__initialData__.currentSite.id || 1

/**********************************
 *
 *
 *  GLOBALS
 *
 *
 **********************************/

// Navigations
export const nav = `
{
    globalSet(handle: "navs", siteId: "${siteID}") {
        ...on navs_GlobalSet {
            mainNav {
                ...on mainNav_BlockType {
                    entry {
                        ${snippets.defaultData}
                        uri
                        sectionHandle
                    }
                    label
                    subnav
                }
            }
            subNavs {
                ...on subNavs_BlockType {
                    navHandle
                    columns {
                        ...on columns_collection_BlockType {
                            typeHandle
                            nodes {
                                ${snippets.defaultData}
                                uri
                                ...on collection_collection_Entry {
                                    ${getAsset('nav')}
                                }
                            }
                        }
                        ...on columns_catalog_BlockType {
                            typeHandle
                            nodes {
                                ${snippets.defaultData}
                                uri
                                ...on catalog_catalog_Entry {
                                    ${getAsset('nav')}
                                }
                            }
                        }
                        ...on columns_listCatalogs_BlockType {
                            sub
                            typeHandle
                            nodes {
                                ${snippets.defaultData}
                                uri
                            }
                        }
                        ...on columns_listCollections_BlockType {
                            sub
                            typeHandle
                            nodes {
                                ${snippets.defaultData}
                                uri
                            }
                        }
                    }
                }
            }
            footerNav {
                ...on footerNav_BlockType {
                    entry {
                        ${snippets.defaultData}
                        uri
                    }
                    label
                    icon
                }
            }
        }
    }
}
`

// Product
export const productSet = `
{
    globalSet(handle: "product", siteId: "${siteID}") {
        ...on product_GlobalSet {
            sizingGuide
            shippingInstructions
        }
    }
}
`

// General (social/address)
export const general = `
{
    globalSet(handle: "general", siteId: "${siteID}") {
        ...on general_GlobalSet {
            socialList {
                ...on socialList_TableRow {
                    name
                    link
                }
            }
            colorMain: color
            colorDarker: colorAlt
            ${snippets.address}
        }
    }
}
`

// Stores general info
export const storeSet = `
{
    globalSet(handle: "store", siteId: "${siteID}") {
        ...on store_GlobalSet {
            headline
            isActive
            table {
                ...on table_MatrixField {
                    ...on table_column_BlockType {
                        label
                        list {
                            ...on list_TableRow {
                                label
                            }
                        }
                    }
                }
            }
        }
    }
}
`

// Modal
export const modalSet = `
{
    globalSet(handle: "modal", siteId: "${siteID}") {
        ...on modal_GlobalSet {
            selectedModal {
                id
            }
        }
    }
}
`




/**********************************
 *
 *
 *  CATEGORIES
 *
 *
 **********************************/

// Category group
export const categories = (group) => `
{
    categories(group: "${group}", siteId: "${siteID}") {
        ${snippets.defaultData}
    }
}
`




/**********************************
 *
 *
 *  Partials
 *
 *
 **********************************/


// Post Partial
export const defaultPartial = (id) => `
{
    entry(id: "${id}", siteId: "${siteID}") {
        ${snippets.defaultData}
        uri

        ...on home_home_Entry {
            mainTitle
            ${getAsset('homeDesktop')}
        }

        ...on shop_shop_Entry {
            mainTitle
            ${getAsset()}
        }

        ...on collections_collections_Entry {
            mainTitle
            ${getAsset()}
        }

        ...on collection_collection_Entry {
            mainTitle
            ${getAsset('grid')}
        }

        ...on catalog_catalog_Entry {
            mainTitle
            ${getAsset()}
        }

        ...on product_product_Entry {
            mainTitle
            ${getAsset()}
        }

        ...on about_about_Entry {
            mainTitle
            ${getAsset()}
        }

        ...on atelier_atelier_Entry {
            mainTitle
            ${getAsset()}
        }

        ...on blog_blog_Entry {
            mainTitle
            ${getAsset()}
        }

        ...on post_post_Entry {
            mainTitle
            postDate
            ${getAsset()}
        }

        ...on events_events_Entry {
            mainTitle
        }

        ...on event_event_Entry {
            mainTitle
            date
            ${getAsset()}
        }

        ...on stores_stores_Entry {
            mainTitle
        }

        ...on contact_contact_Entry {
            mainTitle
        }
    }
}
`




/**********************************
 *
 *
 *  Lists
 *
 *
 **********************************/

// Posts
export const postsList = `
{
    entries(section: "post", siteId: "${siteID}") {
        ${snippets.defaultData}
        uri

        ...on post_post_Entry {
            mainTitle
            postDate
            ${getAsset()}
        }
    }
}
`

// Events
export const eventsList = `
{
    entries(section: "event", orderBy: "date", siteId: "${siteID}") {
        ${snippets.defaultData}
        uri

        ...on event_event_Entry {
            mainTitle
            date
            ${getAsset()}
        }
    }
}
`

// Stores
export const storesList = `
{
    entries(section: "store", siteId: "${siteID}") {
        ${snippets.defaultData}
        uri

        ...on store_store_Entry {
            mainTitle
            ${snippets.address}
            selectedRegion {
                ${snippets.defaultData}
                children {
                    ${snippets.defaultData}
                }
            }
        }
    }
}
`




/**********************************
 *
 *
 *  PAGES
 *
 *
 **********************************/

// Home
export const home = `
{
    entry(section: "home", siteId: "${siteID}") {
        ${snippets.entry}

        ...on home_home_Entry {
            ${snippets.defaultEntry}
            ${getAsset('homeDesktop')}
            ${snippets.mainCta}
            ${snippets.blocks}
            ${getAsset('homeMobile', 'coverMobile')}
            desktopVideo: video
            mobileVideo: videoAlt
            featuredEntry {
                id
            }
            featuredProducts {
                ...on product_product_Entry {
                    storefrontId
                    title
                }
            }
        }
    }

    blog: entry(section: "blog", siteId: "${siteID}") {

        ...on blog_blog_Entry {
            mainTitle
        }
    }
}
`

// about
export const about = `
{
    entry(section: "about", siteId: "${siteID}") {
        ${snippets.entry}

        ...on about_about_Entry {
            ${snippets.defaultEntry}
            ${getAsset()}
            ${snippets.builder}
            ${snippets.push}
        }
    }
}
`

// press
export const press = `
{
    entry(section: "press", siteId: "${siteID}") {
        ${snippets.entry}

        ...on press_press_Entry {
            ${snippets.defaultEntry}
            pressList {
                ... on pressList_BlockType {
                    ${getAsset()}
                    label
                    website
                }
            }
            ${snippets.push}
        }
    }
}
`

// atelier
export const atelier = `
{
    entry(section: "atelier", siteId: "${siteID}") {
        ${snippets.entry}

        ...on atelier_atelier_Entry {
            ${snippets.defaultEntry}
            ${getAsset()}
            body
            headline
            email
            phone
            ${snippets.address}
            ${snippets.builder}
            ${snippets.push}
        }
    }
}
`

// Shop
export const shop = `
{
    entry(section: "shop", siteId: "${siteID}") {
        ${snippets.entry}

        ...on shop_shop_Entry {
            ${snippets.defaultEntry}
            displayBig
            videoList {
                ...on videoList_TableRow {
                    video
                }
            }
        }
    }
}
`

// Products
export const products = `query Products($handles: [QueryArgument], $order: String ) {
    entries(section: "product", storefrontId: $handles, siteId: "${siteID}", orderBy: $order, limit: -1) {
        ${snippets.entry}
        ${snippets.product}
    }
}
`

// Product
export const product = (handle) => `
{
    entry(section: "product", slug: "${handle}", siteId: "${siteID}") {
        ${snippets.entry}
        ${snippets.product}
    }
}
`

// Collections
export const collections = `
{
    entry(section: "collections", siteId: "${siteID}") {
        ${snippets.entry}

        ...on collections_collections_Entry {
            ${snippets.defaultEntry}
            ${snippets.blocks}
            selectedCollection {
                id
            }
            ${snippets.push}
        }
    }
}
`

// Collection
export const collection = slug => `
{
    entry(section: "collection", slug: "${slug}", siteId: "${siteID}") {
        ${snippets.entry}
        typeHandle

        ...on collection_collection_Entry {
            ${snippets.defaultEntry}
            ${getAsset('grid')}
            ${getAsset('grid', 'landscapeImage')}
            video
            showControls: true_false_1
            muteByDefault: true_false_2
            autoPlayByDefault: true_false_3
            selectedCatalog {
                title
                uri
            }
            label
            colorMain: color
            colorDarker: colorAlt
            ${snippets.builder}
            ${snippets.push}
        }
    }
}
`

// Catalog
export const catalog = slug => `
{
    entry(section: "catalog", slug: "${slug}", siteId: "${siteID}") {
        ${snippets.entry}
        typeHandle

        ...on catalog_catalog_Entry {
            ${snippets.defaultEntry}
            ${getAsset()}
            fillInCover: ${getAsset('default', 'landscapeImage')}
            headline
            catalogType
            colorMain: color
            colorDarker: colorAlt
            featuredProducts {
                ...on product_product_Entry {
                    storefrontId
                    title
                }
            }
            displayBig
            videoList {
                ...on videoList_TableRow {
                    video
                }
            }
            relatedTags
            relatedProductTypes
            selectedProducts {
                title
                id
                slug
                ...on product_product_Entry {
                    storefrontId
                }
            }
            ${snippets.push}
        }
    }
}
`

// Stores
export const stores = `
{
    entry(section: "stores", siteId: "${siteID}") {
        ${snippets.entry}

        ...on stores_stores_Entry {
            ${snippets.defaultEntry}
        }
    }
}
`


// Store
export const store = slug => `
{
    entry(section: "store", slug: "${slug}", siteId: "${siteID}") {
        ${snippets.entry}

        ...on store_store_Entry {
            ${snippets.defaultEntry}
            ${snippets.address}
            phone
            email
            selectedRegion {
                ${snippets.defaultData}
            }
            openingTimes {
                ...on openingTimes_TableRow {
                    days
                    time
                }
            }
            ${snippets.builder}
        }
    }
}
`

// Blog Page
export const blog = `
{
    entry(section: "blog", siteId: "${siteID}") {
        ${snippets.entry}

        ...on blog_blog_Entry {
            ${snippets.defaultEntry}
            ${snippets.blocks}
            selectedPost {
                id
            }
            ${getAsset(null, 'highlightedAssets')}
        }
    }

    posts: entries(section: "post", siteId: "${siteID}") {
        ${snippets.defaultData}
        uri

        ...on event_event_Entry {
            mainTitle
            ${getAsset()}
        }
    }
}
`

// Post
export const post = slug => `
{
    entry(section: "post", slug: "${slug}", siteId: "${siteID}") {
        ${snippets.entry}

        ...on post_post_Entry {
            ${snippets.defaultEntry}
            ${getAsset()}
            colorMain: color
            colorDarker: colorAlt
            ${snippets.builder}
        }
    }
}
`

// Events Page
export const events = `
{
    entry(section: "events", siteId: "${siteID}") {
        ${snippets.entry}

        ...on events_events_Entry {
            ${snippets.defaultEntry}
            ${snippets.blocks}
            selectedEvent {
                id
            }
        }
    }

    events: entries(section: "event", orderBy: "date", siteId: "${siteID}") {
        ${snippets.defaultData}
        uri

        ...on event_event_Entry {
            mainTitle
            date
            ${getAsset()}
        }
    }
}
`

// Event
export const event = slug => `
{
    entry(section: "event", slug: "${slug}", siteId: "${siteID}") {
        ${snippets.entry}

        ...on event_event_Entry {
            ${snippets.defaultEntry}
            date
            ${getAsset()}
            ${snippets.builder}
        }
    }
}
`

// Contact Page
export const contact = `
{
    entry(section: "contact", siteId: "${siteID}") {
        ${snippets.entry}

        ...on contact_contact_Entry {
            ${snippets.defaultEntry}
            headline
            contactList {
                ...on contactList_BlockType {
                    label
                    email
                    phone
                }
            }
            officesList {
                ...on officesList_BlockType {
                    label
                    address {
                        ...on address_address_BlockType {
                            label
                            googleMap
                        }
                    }
                }
            }
            customerService {
                ...on customerService_BlockType {
                    headline
                    chatTitle
                    chatDesc
                    emailTitle
                    emailDesc
                    emailAddress
                    phoneTitle
                    phoneDesc
                    phoneNumber
                }
            }
        }
    }
}
`

// Terms Page
export const terms = `
{
    entry(section: "terms", siteId: "${siteID}") {
        ${snippets.entry}

        ...on terms_terms_Entry {
            ${snippets.defaultEntry}
            headline
            body
            ${snippets.list}
        }
    }
}
`

// Search Page
export const search = `
{
    entry(section: "search", siteId: "${siteID}") {
        ${snippets.entry}

        ...on search_search_Entry {
            ${snippets.seo}
        }
    }
}
`

// Modal
export const modal = id => `
{
    entry(section: "modal", id: "${id}", siteId: "${siteID}") {

        ...on modal_entry_Entry {
            typeHandle
            mainTitle
            headline
            ${getAsset()}
            selectedEntry {
                ${snippets.entry}
            }
            label
        }

        ...on modal_newsletter_Entry {
            typeHandle
            mainTitle
            description
        }
    }
}
`

// Page Not Found
export const error404 = `
{
    entry(section: "error404", siteId: "${siteID}") {
        ${snippets.entry}

        ...on error404_error404_Entry {
            ${snippets.defaultEntry}
        }
    }
}
`


/**********************************
 *
 *
 *  SEARCH
 *
 *
 **********************************/


export const searchProducts = (query) => `
{
    entries(section: "product", search: "${query}", siteId: "${siteID}") {
        ${snippets.defaultData}
        uri

        ...on product_product_Entry {
            ${getAsset()}
        }
    }
}
`


export const pageURL = (pageID, site) => `
{
    entry(id: ${pageID}, siteId: "${site}") {
        url
    }
}
`



export default {
    // Globals
    nav,
    productSet,
    general,
    storeSet,
    modalSet,

    // Categories
    categories,

    // Partials
    defaultPartial,

    // Lists
    postsList,
    eventsList,
    storesList,

    // Entries
    home,
    about,
    press,
    atelier,
    shop,
    collections,
    collection,
    catalog,
    products,
    product,
    blog,
    post,
    events,
    event,
    stores,
    store,
    contact,
    terms,
    search,
    modal,
    error404,

    // Search
    searchProducts,
    pageURL,
}
