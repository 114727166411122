<template>
    <div class="o-mailchimp">
        <form
            class="o-mailchimp__form"
            @submit.prevent="subscribe"
        >

            <label class="o-mailchimp__label | -screen-reader-text" for="mcEmailInput">{{ $t('form.yourEmail') }}</label>
            <input
                id="mcEmailInput"
                type="email"
                name="email"
                :placeholder="$t('form.yourEmail')"
                class="o-mailchimp__input"
                @input="setEmail($event.target.value)"
            />

            <btn
                type="submit"
                :label="$t('btn.subscribe')"
                iconAfter="arrow-long-right"
                :uppercase="true"
                :reveal="false"
            />
        </form>

        <div
            v-if="error || success || loading"
            class="o-mailchimp__messages | t-cms"
        >
            <div v-if="error" v-html="error" class="-error"></div>
            <div v-if="success">{{ $t('form.success') }}</div>
            <div v-if="loading">{{ $t('form.loading') }}</div>
        </div>
    </div>
</template>

<script>

import Btn from 'components/Btn';

import jsonp from 'jsonp'
import queryString from 'query-string'
import { mapGetters } from 'vuex'

export default {

    components: {
        Btn,
    },

    data: () => ({
        email: null,
        success: false,
        error: null,
        loading: false,
        customFields: {},

        userId: '46cedc026b6507656770e4e2f',
        listId: 'ff125bb55a',
        accountHandle: 'silviatcherassi.us19'
    }),

    computed: {
        ...mapGetters({
            site: 'global/getCurrentSite'
        }),

        baseUrl() {
            return `https://${this.accountHandle}.list-manage.com/subscribe`
        },
        data() {
            const formData = {
                u: this.userId,
                id: this.listId,
                EMAIL: this.email,
                LOCALE: this.site.lang
            }

            const merge = {...formData, ...this.customFields};

            //console.log(merge)

            return queryString.stringify(merge)
        },
    },

    methods: {
        setEmail(value = '') {
            this.email = value.trim()
        },

        setField(field, value = '') {
            this.customFields[field] = value.trim()
        },

        subscribe() {

            if (this.email === null || this.loading) {
                return
            }

            this.success = false
            this.error = null
            this.loading = true

            const url = `${this.baseUrl}/post-json?${this.data}`

            jsonp(url, { param: 'c' }, this.onResponse)
        },

        onResponse(error, data) {
            this.loading = false

            if (error) {
                this.error = error
            }

            if (data && data.result === 'error') {

                if(this.email) {
                    window.location.href = `${this.baseUrl}?${this.data}`
                } else {
                    this.error = this.formatErrorMessage(data.msg)
                }
            }

            if (!this.error) {
                this.success = true
                this.email = null
            }
        },

        formatErrorMessage(message) {
            return message.slice(4)
        },
    },

    // render() {
    //     return this.$scopedSlots.default({
    //         subscribe: this.subscribe,
    //         setEmail: this.setEmail,
    //         setField: this.setField,
    //         error: this.error,
    //         success: this.success,
    //         loading: this.loading,
    //     })
    // },
}

</script>

<style lang="scss">

.o-mailchimp {}

.o-mailchimp__form {
    display: flex;
    justify-content: center;

    .c-btn {
        padding-left: 0;

        &:hover {

            .c-btn__border:after {
                transform: scale(.9, 1);
            }
        }
    }

    .c-btn__border {

        &:before {
            top: 0;
        }

        &:after {
            top: calc(100% - #{$border-width});
            width: calc(100% + #{$border-width});
            transform-origin: 0 50%;
        }
    }
}

.o-mailchimp__input {
    width: 100%;
    max-width: 12em;
    // Same padding as btn
    padding-top: .5em;
    padding-bottom: .5em;

    padding-left: .5em;
    border-bottom: $border-width solid $color-dark;
    color: $color-dark;

}

.o-mailchimp__messages {
    width: 100%;

    & > * {
        padding-top: 1em;

        &.-error {
            color: red;
        }
    }
}

</style>
