<template>
    <div
        v-if="hasVideo"
        :class="className"
        :style="styles"
        @mouseenter="mouseevents && !controls ? pause() : false"
        @mouseleave="mouseevents && !controls ? play() : false"
    >
        <div
            class="o-vimeo__player"
            ref="video"
        ></div>
        <div
            v-if="controls"
            class="o-vimeo__controls"
        >
            <span
                class="o-vimeo__play"
                :style="`--progress-clip-path: ${progressClipPath};`"
            >
                <button @click="togglePlay()">
                    <icon
                        v-show="!isPlaying"
                        icon="player-play"
                    />
                    <icon
                        v-show="isPlaying"
                        icon="player-pause"
                    />
                </button>
            </span>
            <button
                class="o-vimeo__mute"
                @click="toggleMute()"
            >
                <icon
                    v-show="!isMuted"
                    icon="player-mute"
                />
                <icon
                    v-show="isMuted"
                    icon="player-unmute"
                />
            </button>
        </div>
    </div>
</template>

<script>

import Icon from 'objects/Icon'

import Variables from 'src/mixins/variables';
import { round } from 'src/utils';

import { gsap } from 'gsap/all'
import Player from '@vimeo/player';

export default {
    name: 'Vimeo',
    mixins: [ Variables ],
    components: {
        Icon,
    },
    props: {
        id: {
            type: String,
            default: null,
        },
        mouseevents: {
            type: Boolean,
            default: false,
        },
        cover: {
            type: Boolean,
            default: false,
        },

        // Player plugin options
        autoplay: {
            type: Boolean,
            default: true,
        },
        controls: {
            type: Boolean,
            default: false,
        },
        muted: {
            type: Boolean,
            default: true,
        },
        loop: {
            type: Boolean,
            default: true,
        }
    },
    data: () => ({
        isPlaying: false,
        inViewport: false,
        videoWidth: 0,
        videoHeight: 0,
        iframeWidth: 0,
        iframeHeight: 0,
        promise: null,
        videoData: {
            progress: 0,
        },
        isMuted: false,
        wasPlaying: false,
    }),
    created() {
        this.isPlaying = this.autoplay
        this.isMuted = this.autoplay ? true : this.muted
    },
    mounted() {

        if(!this.hasVideo) {
            return
        }

        // Create player instance
        this.player = new Player(this.$refs.video, {
            id: this.id,
            autoplay: this.autoplay,
            loop: this.loop,
            controls: false,
            muted: this.isMuted,
            autopause: false,
            responsive: !this.cover,
        })

        // Get video with and height
        this.player.getVideoWidth().then(w => this.videoWidth = w)
            .catch(() => {
                this.isPlaying = false
            })

        this.player.getVideoHeight().then(h => this.videoHeight = h)
            .catch(() => {
                this.isPlaying = false
            })

        // Load event
        this.player.on('loaded', this.onLoaded = () => {
            this.$emit('load')
        })

        if(this.controls) {
            this.player.on('timeupdate', this.onProgress = ({percent}) => {
                gsap.to(this.videoData, {
                    progress: percent,
                    duration: .25,
                    ease: 'none'
                })
            })
        }

        // Window events
        window.addEventListener('scroll', this.isInViewport);
        window.addEventListener('resizeEnd', this.setPosition);
    },
    computed: {
        className() {
            let classname = 'o-vimeo'

            if(this.cover) {
                classname += ' -cover'
            }

            if(this.isPlaying) {
                classname += ' is-playing'
            }

            return classname
        },
        styles() {
            return `--w: ${this.iframeWidth}px; --h: ${this.iframeHeight}px;`
        },
        videoRatio() {
            if(this.videoWidth === 0 || this.videoHeight === 0) {
                return 16/9
            }

            return round(this.videoWidth/this.videoHeight)
        },
        hasVideo() {
            return this.id !== null
        },
        progressClipPath() {

            const perc = this.videoData.progress * 100

            // Define default polygon
            let polygon = [
                // Center
                [50, 50],
                // Top right corner
                [100, 0],
                // Bottom right corner
                [100, Math.min(round(4 * perc), 100)],
            ]

            let x, y

            // Bottom left corner
            if (perc >= 25) {
                x = Math.max(round(-4 * perc + 200), 0)
                y = 100
                polygon.push([x, y])
            }

            // Top left corner
            if (perc >= 50) {
                x = 0
                y = Math.max(round(-4 * perc + 300), 0)
                polygon.push([x, y])
            }

            // Top right corner
            if (perc >= 75) {
                x = Math.min(round(4 * perc - 300), 100)
                y = 0
                polygon.push([x, y])
            }

            // Array to string with css polygon format values
            polygon = polygon.map(p => p.join('% ').concat('%')).join(', ');

            return polygon;
        }
    },
    methods: {
        play() {
            // Don't play if element is not visible or is playing already
            if(!this.inViewport || this.isPlaying) {
                return
            }

            this.player.play().then(() => {
                this.isPlaying = true
            })
        },
        pause() {
            // Don't pause if element is paused already
            if(!this.isPlaying) {
                return
            }

            this.player.pause().then(() => {
                this.isPlaying = false
            })
        },
        setPosition() {

            if(!this.cover) {
                return
            }

            const containerSizes = {
                w: this.$el.offsetWidth,
                h: this.$el.offsetHeight,
            }

            const containerRatio = containerSizes.w / containerSizes.h

            if (containerRatio > this.videoRatio) {
                this.iframeWidth = containerSizes.w
                this.iframeHeight = Math.ceil(containerSizes.w / this.videoRatio)
            } else {
                this.iframeWidth = Math.ceil(containerSizes.h * this.videoRatio)
                this.iframeHeight = containerSizes.h
            }
        },
        isInViewport() {
            const rect = this.$el.getBoundingClientRect();

            this.inViewport = (
                rect.top <= this.W.h &&
                rect.bottom >= 0
            );
        },
        toggleMute() {
            this.isMuted = !this.isMuted
            this.player.setMuted(this.isMuted)
        },
        togglePlay() {
            if(this.isPlaying) {
                this.pause()
            } else {
                this.play()
            }
        },
    },
    watch: {
        videoRatio() {
            this.setPosition()
        },
        inViewport(visible) {

            if(visible && this.wasPlaying) {
                this.play()
            } else if(!visible) {
                this.wasPlaying = this.isPlaying
                this.pause()
            }
        }
    },
    beforeDestroy() {

        if(!this.hasVideo) {
            return
        }

        // Remove load event
        this.player.off('loaded', this.onLoaded)

        if(this.controls) {
            this.player.off('timeupdate', this.onProgress)
        }

        // Remove scroll event
        window.removeEventListener('scroll', this.isInViewport);

        // Remove window resize event
        window.removeEventListener('resizeEnd', this.setPosition);
    }
};

</script>

<style lang="scss">


.o-vimeo {
    width: 100%;

    &.-cover {

        &,
        .o-vimeo__player {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            overflow: hidden;
            pointer-events: none;
        }

        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            width: var(--w, 100%);
            height: var(--h, 100%);
            transform: translate(-50%, -50%);
        }
    }

    &.is-playing {

        .o-vimeo__mute,
        .o-vimeo__play {
            opacity: 0;
            transform: translate(0, 1em) scale(.8);
            transition: all .4s $in-quart;
        }
    }

    &:hover {

        .o-vimeo__mute,
        .o-vimeo__play {
            opacity: 1;
            transform: translate(0) scale(1);
            transition: all .4s $out-quart;
        }
    }
}

.o-vimeo__player {
    width: 100%;

    iframe {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.o-vimeo__controls {
    z-index: 1;
    position: absolute;
    bottom: 2em;
    display: flex;
    align-items: center;

    @media #{md("sm", "max")} {
        right: 1em;
        bottom: .5em;
        left: 1em;
        width: auto;
        align-items: center;
        justify-content: space-between;
    }

    @media #{md("sm")} {
        left: 1.5em;
        flex-direction: column-reverse;
    }
}

.o-vimeo__mute {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $accessible-size;
    height: $accessible-size;
    color: $color-primary;
    cursor: pointer;
    transform-origin: 50% 100%;
}

.o-vimeo__play {
    --progress-clip-path: 50% 50%;

    display: block;
    padding: .75em;
    border-radius: 50%;
    transform-origin: 50% 100%;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.25em;
        height: 3.25em;
        background-color: $color-primary;
        border-radius: inherit;
        cursor: pointer;
    }

    &:after,
    &:before {
        @include pseudo-el;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid $color-primary;
        border-radius: inherit;
        z-index: -1;
    }

    &:before {
        opacity: .4;
    }

    &:after {
        clip-path: polygon(var(--progress-clip-path));
        transform: rotate(-45deg);
    }

    @media #{md("sm")} {
        padding: 1em;

        button {
            width: 6.75em;
            height: 6.75em;
        }
    }
}

</style>
