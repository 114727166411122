<template>
    <span
        v-if="tag"
        class="o-tag"
        :class="`-${color}`"
    >
        {{ tag }}
    </span>
    <ul
        v-else-if="totalTags > 1"
        class="o-tags"
    >
        <li
            v-for="(tag, i) in tags"
            :key="`tag-${i}`"
            class="o-tags__item | o-tag"
            :class="`-${color}`"
        >
            {{ tag }}
        </li>
    </ul>
</template>

<script>

export default {
    name: 'Tags',
    props: {
        tags: {
            type: Array,
            required: true,
        },
        color: {
            type: String,
            default: 'black'
        }
    },
    computed: {
        totalTags() {
            return this.tags.length
        },
        tag() {
            if(this.totalTags === 0 || this.totalTags > 1) {
                return false
            } else {
                return this.tags[0]
            }
        },
    },
};

</script>

<style lang="scss">


/*----------  Tags list  ----------*/

.o-tags {
    margin-right: -.3em;
    margin-left: -.3em;
}

.o-tags__item {
    margin: .3em;
}


/*----------  Tag  ----------*/

.o-tag {
    display: inline-block;
    padding: .1em .4em;
    font-size: .75em;
    color: $color-light;
    text-transform: uppercase;
    background-color: $color-dark;

    &.-red {
        background-color: $color-red;
    }
}


</style>
