import {storeFrontApi} from 'src/graphql/shopify/config';
import {default as storefrontQueries} from 'src/graphql/shopify/queries';

import {fetchApi} from 'src/graphql/craft/config';
import {default as craftQueries} from 'src/graphql/craft/queries';

import { orderObjectByKey } from 'src/utils';

/**********************************
 *
 *
 *  SHOPIFY/PRODUCTS
 *
 *
 **********************************/

// State
const state = {
    count: window.__initialData__.count.products,
    all: [],
    catalogs: []
}

// Getters
const getters = {
    getProductByID: state => id => state.all.find(p => p.id === id) || false,
    getProductsByIDs: state => ids => state.all.filter(p => ids.includes(p.id)) || false,
    getProductBySlug: state => slug => state.all.find(p => p.slug === slug) || false,
    getCatalog : state => catalog => state.catalogs[catalog] || false,
}

// Formating functions
const formatProduct = (shopify, craft={}) => {

    // Keep Shopify ID stored
    shopify.shopifyId = shopify.id

    let product = {...shopify, ...craft}

    // Simplify variant object
    product.variants = product.variants.edges.map(v => v.node)

    // Get our available sizes based on variants
    const sizes = product.options.find(option => option.name === 'Size')
    product.sizes = sizes && sizes.values.length > 0 ? sizes.values.map(size => size.toUpperCase()) : []

    //
    product.variantSizes = []
    if ( sizes && sizes.values.length > 0 )
        product.variants.forEach(v => {

            const _sizeAttribute = v.selectedOptions.findIndex(o => o.name === 'Size')

            if ( _sizeAttribute !== -1) {
                const _sizeValue = v.selectedOptions[_sizeAttribute].value
                product.variantSizes.push({
                    id: _sizeValue,
                    title: _sizeValue.toUpperCase(),
                    disabled: !(v.availableForSale && v.quantityAvailable > 0 && !v.currentlyNotInStock)
                })
            }
        })

    // Min price value in root
    product.minPrice = parseFloat(product.priceRange.minVariantPrice.amount)

    // Shortcut for inSale
    product.inSale = product.variants.filter(v => v.compareAtPriceV2 != null && v.compareAtPriceV2.amount).length > 0 ? true : false

    // TMP sale price show
    product.regularPrice = product.variants[0].compareAtPriceV2 ? product.variants[0].compareAtPriceV2.amount : product.variants[0].priceV2.amount

    product.regularPrice = parseFloat(product.regularPrice)

    // Find if product is available in any variant
    product.available = product.variants.findIndex(v => v.availableForSale && v.quantityAvailable > 0 && !v.currentlyNotInStock) > -1

    // Card props
    product.card = {
        title: product.title,
        uri: product.uri,
        thumb: product.asset[0],
        assets: product.productAssets,
        minPrice: product.minPrice,
        regularPrice: product.regularPrice,
        sizes: product.sizes,
        variantSizes: product.variantSizes,
        inSale: product.inSale,
        unavailable: !product.available,
    }

    return product
}


// Actions
const actions = {
    /*
     * loadAll()
     *
     * Triggered on the Shop view or on a textual search, this action will load everything using the 3rd parameter
     * of the storeFrontApi() method (recursive) and then search handles' result in Craft.
     */
    loadAll(store) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Get products
            const products = store.state.all

            // If exists, resolve right away.
            if (products.length === store.state.count) {

                // Resolve
                resolve(products)
                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // Else, fetch.
            } else {

                const variables = {
                    first: 100,
                    after: null,
                    sortKey: 'CREATED_AT',
                    reverse: true
                }

                storeFrontApi(storefrontQueries.products, variables, true, 'products')
                .then(r => {

                    // Exit on error
                    if (!r) {
                        reject({
                            code: 200,
                            message: 'Shopify query failed.'
                        })

                    } else {

                        // Clean 'node' prop to get clean product compatible with 'single' loader
                        const shopifyProducts = r.map(product => product.node)

                        // Array of Shopify product handles
                        const shopify_handles = shopifyProducts.map(product => product.handle)

                        // Array of product handles from the store
                        //const store_handles = store.state.all.map(product => product.handle)

                        // Fetch only the difference
                        //const handles = shopify_handles.filter(x => !store_handles.includes(x))

                        const handles = shopify_handles

                        // Fetch the Craft entries matching the `handles`
                        fetchApi(craftQueries.products, {handles, order: 'postDate DESC'})
                        .then (r => {

                            // Exit on error
                            if (!r || !r.entries) {
                                reject({
                                    code: 200,
                                    message: 'No products found'
                                })
                                store.dispatch('loader/endLoad', null, {root: true});

                            } else {

                                let count = 0;
                                const craftProducts = r.entries

                                let products = []
                                let shopifyProduct, product

                                // Loop through Craft results
                                craftProducts.forEach(craftProduct => {

                                    // Search for the matching product
                                    shopifyProduct = shopifyProducts.find(p => p.handle == craftProduct.storefrontId)

                                    if ( typeof shopifyProduct !== 'undefined' && shopifyProduct) {

                                        product = formatProduct(shopifyProduct, craftProduct)
                                        products.push(product)

                                        count++

                                        // Add product to all products if doesn't exist
                                        if(!store.getters.getProductByID(product.id)) {
                                            store.commit('addProduct', product)
                                        }
                                    }
                                })

                                store.commit('updateCount', count)

                                // Resolve
                                resolve(products)
                                store.dispatch('loader/endLoad', null, {root: true});
                            }
                        })
                    }
                })
            }
        })
    },
    /*
     * searchProducts()
     *
     * Search Shopify using their textual search argument, retrieve all the handles return from Shopify
     * and then query Craft with the result to build a search or a catalog query. It can also order the
     * result in a specific order using the `keySort` var (an array of handles).
     */
    searchProducts(store, {query, catalog, keySort, order = ''}) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // If the catalog prop exist, search for the matching catalog
            const hasCatalog = catalog && catalog !== ''

            let catalogProducts
            const catalogProductsIds = hasCatalog ? store.getters.getCatalog(catalog) : false

            // If we have product in the store, resolve them right away
            if (catalogProductsIds) {

                // Get products from stored IDs
                catalogProducts = store.getters.getProductsByIDs(catalogProductsIds)

                // Preserve the specific order
                catalogProducts = orderObjectByKey(catalogProducts, catalogProductsIds, 'id')

                // Resolve
                resolve(catalogProducts)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If not, search in Shopify and Craft
            } else {

                // TODO : make the 2nd params an object (instead of `query` >> {query, options} ) to make overridable defaults, ie. let the sortKey be editable
                // const defaults = {
                //     first: 250,
                //     after: null,
                //     sortKey: 'CREATED_AT',
                //     reverse: true
                // }

                const variables = {
                    first: 250,
                    after: null,
                    sortKey: 'CREATED_AT',
                    reverse: true,
                    query
                }

                // Search Shopify for product matching the `query`
                storeFrontApi(storefrontQueries.products, variables)
                .then(r => {

                    // Exit on error
                    if (!r || !r.products) {
                        reject({
                            code: 200,
                            message: 'Shopify query failed.'
                        })

                    } else {

                        // Clean response
                        // Array of products
                        const shopifyProducts = r.products.edges.map(product => product.node)

                        // Array of Shopify product handles
                        const shopify_handles = shopifyProducts.map(product => product.handle)

                        // Array of product handles from the store
                        //const store_handles = store.state.all.map(product => product.handle)

                        // Fetch on the difference
                        //const handles = shopify_handles.filter(x => !store_handles.includes(x))

                        const handles = shopify_handles

                        // Fetch the Craft entries matching the `handles`
                        fetchApi(craftQueries.products, {handles, order})
                        .then (r => {

                            // Exit on error
                            if (!r || !r.entries) {
                                reject({
                                    code: 200,
                                    message: 'No products found'
                                })

                            } else {

                                const craftProducts = r.entries

                                if ( !craftProducts ) {
                                    reject({
                                        code: 200,
                                        message: 'No products found'
                                    })
                                    store.dispatch('loader/endLoad', null, {root: true});

                                } else {

                                    let products = []
                                    let shopifyProduct, product

                                    // Loop through Craft results
                                    craftProducts.forEach(craftProduct => {

                                        // Search for the matching product
                                        shopifyProduct = shopifyProducts.find(p => p.handle == craftProduct.storefrontId)

                                        if ( typeof shopifyProduct !== 'undefined' && shopifyProduct) {

                                            product = formatProduct(shopifyProduct, craftProduct)
                                            products.push(product)

                                            // Add product to all products if doesn't exist
                                            if(!store.getters.getProductByID(product.id)) {
                                                store.commit('addProduct', product)
                                            }
                                        }
                                    })

                                    // Commit catalog
                                    if (hasCatalog) {

                                        if (keySort) {

                                            // Add a property to be able to recognize those products
                                            products.forEach(product => {
                                                product.isFeatured = keySort.includes(product.storefrontId);
                                            })

                                            // Order by backend order
                                            products = orderObjectByKey(products, keySort, 'storefrontId')
                                        }
                                        const ids = products.map(product => product.id)

                                        store.commit('addCatalog', {catalog, ids})

                                    } else if (keySort) {

                                        // Add a property to be able to recognize those products
                                        products.forEach(product => {
                                            product.isFeatured = keySort.includes(product.storefrontId);
                                        })

                                        // Order by backend order
                                        products = orderObjectByKey(products, keySort, 'storefrontId')
                                    }

                                    // Resolve
                                    resolve(products)

                                    store.dispatch('loader/endLoad', null, {root: true});
                                }
                            }
                        })
                    }
                })

            }
        })
    },
    /*
     * loadProductBySlug()
     *
     * Load a product first from Craft with the queried `slug`, retrieve the `storefrontId` value
     * and then query Shopify to fetch a product with this value, which is the product handle.
     */
    loadProductBySlug(store, slug) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Get products
            const product = store.getters.getProductBySlug(slug)

            // If exists, resolve right away.
            if (product) {

                // Resolve
                resolve(product)
                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // Else, fetch.
            } else {

                // Fetch the Craft entry
                fetchApi(craftQueries.product(slug))
                .then (r => {

                    // Exit on error
                    if (!r || !r.entry) {
                        reject({
                            code: 404,
                            message: 'No product found with this handle.'
                        })
                        store.dispatch('loader/endLoad', null, {root: true});

                    // If it exists, try to fetch from Shopify
                    } else {

                        const craftProduct = r.entry

                        // Fetch the Shopify element
                        storeFrontApi(storefrontQueries.productByHandle, { handle: craftProduct.storefrontId })
                        .then(r => {

                            // Reject on error
                            if (!r || !r.productByHandle) {
                                reject({
                                    code: 404,
                                    message: 'No Shopify product found with this handle.'
                                })

                            // Else, resolve
                            } else {

                                const shopifyId = r.productByHandle.id
                                const product = formatProduct(r.productByHandle, craftProduct)

                                // Commit and resolve
                                store.commit('addProduct', product)
                                resolve(product)
                                store.dispatch('loader/endLoad', null, {root: true});
                            }
                        })

                    }
                })

            }
        })
    },

    /*
     * loadProductRecommendations()
     *
     *
     *
     */
    loadProductRecommendations(store, shopifyId) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Query variables
            const variables = {
                id: shopifyId
            }

            // Fetch recommendations
            storeFrontApi(storefrontQueries.productRecommendations, variables)
                .then(r => {

                    // Exit on error
                    if (!r || !r.productRecommendations) {
                        reject({
                            code: 200,
                            message: 'Product recommendations query failed.'
                        })
                        store.dispatch('loader/endLoad', null, {root: true});

                    } else {
                        resolve(r.productRecommendations)
                        store.dispatch('loader/endLoad', null, {root: true});
                    }
                })
        })
    }
}

// Mutations
const mutations = {
    addCatalog(state, {catalog, ids}) {
        state.catalogs[catalog] = ids
    },
    addProduct(state, product) {
        state.all.push(product)
    },
    updateCount(state, count) {
        state.count = count
    }
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
