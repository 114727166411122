<template v-if="label">
    <a
        v-if="googleMap"
        :href="googleMap"
        :title="label"
        target="_blank"
        rel="noopener"
        class="o-address"
    >
        <address v-html="labelHTML"></address>
        <span
            v-if="directions"
            class="o-address__directions | t-caps"
        >
            {{ $t('btn.directions') }}
            <icon icon="arrow-up-right" />
        </span>
    </a>
    <address
        v-else
        v-html="labelHTML"
        class="o-address"
    ></address>
</template>

<script>

import Icon from 'objects/Icon';

export default {
    name: 'AppAddress',
    components: {
        Icon,
    },
    props: {
        address: {
            type: Object|Array,
            required: true,
        },
        directions: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        data() {
            // If object, return object
            if (!Array.isArray(this.address)) {
                return this.address
            }

            // Return first instance if array
            return this.address.length > 0 ? this.address[0] : false
        },
        label() {
            return this.data && this.data.label !== '' ? this.data.label : false
        },
        labelHTML() {
            return this.label ? this.label.replace('\n', '<br>') : false
        },
        googleMap() {
            return this.data && this.data.googleMap !== '' ? this.data.googleMap : false
        },
    },
};

</script>

<style lang="scss">

.o-address {
    display: inline-block;
}

.o-address__directions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;

    .o-icon {
        margin-left: 1em;
        transition: transform .2s ease-out;
    }

    &:hover {

        .o-icon {
            transform: translate(.25em, -.25em);
        }
    }
}

</style>
